import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContactFlows } from '../../../../../api/contact-flows';
import useEngagement from '../../../../../api/people/use-engagement';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { usePublicContactPhoneNumber } from '../../../../../api/public/public-contact-phone-number';
import { useSession } from '../../../../../api/session';
import DoubleHousesWithClouds from '../../../../../assets/double-houses-with-clouds.svg';
import HomePlusAuto from '../../../../../assets/home-plus-auto.svg?react';
import PlantAndPhone from '../../../../../assets/plant-and-phone.svg';
import SeparatedHousesWithClouds from '../../../../../assets/separate-houses-with-clouds.svg';
import { SEGMENT } from '../../../../../constants/analytics';
import {
  CALENDLY_ID,
  CALENDLY_PREVIEW_DETAILS_ID,
} from '../../../../../hooks/use-init-calendly/use-init-calendly.util';
import { useSegment } from '../../../../../hooks/use-segment';
import noop from '../../../../../shared/utils/noop';
import { scrollToNode } from '../../../../../utils/scroll.util';
import CTACard from '../CTACard/CTACard';
import CalendlyAutoScheduledMessage from '../CalendlyAutoScheduledMessage/CalendlyAutoScheduledMessage';
import MaticHelpBlock from '../MaticHelpBlock/MaticHelpBlock';
import NoQuotesLoader from '../NoQuotesLoader/NoQuotesLoader';
import QuotePageScheduleCall from '../QuotePageScheduleCall/QuotePageScheduleCall';
import SoftStopPage from '../SoftStopPage/SoftStopPage';
import {
  cardCSS,
  noQuoteImageCSS,
  headerContentCSS,
  wrapperCSS,
  pageWrapperCSS,
  elipseCSS,
  separateHouseIconCSS,
  doubleHouseIconCSS,
  headerCSS,
  subheaderCSS,
} from './DigitalProfileNoQuotesContent.style';
import type { DigitalProfileNoQuotesContentProps } from './DigitalProfileNoQuotesContent.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const DigitalProfileNoQuotesContent: FC<DigitalProfileNoQuotesContentProps> = ({
  bundleAnswer,
  noQuotesReason,
  onPhoneClicked = noop,
  onScheduleCallClick = noop,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: contactFlowData } = useContactFlows(gid);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { data: phoneData } = usePublicContactPhoneNumber(gid);
  const { data: sessionData } = useSession(gid);

  const { data: engagementData, isLoading: isEngagementDataLoading } = useEngagement(
    gid,
    sessionData?.session.engagement_gid,
    true
  );

  const lastContactFlow = contactFlowData?.contact_flows[0];

  const { track } = useSegment();

  useEffect(() => {
    track(SEGMENT.events.noQuoteMessageViewed, {
      reason: noQuotesReason,
    });
  }, [noQuotesReason, track]);

  const onScheduleCall = (): void => {
    scrollToNode(lastContactFlow ? CALENDLY_PREVIEW_DETAILS_ID : CALENDLY_ID);
    onScheduleCallClick();
  };

  const noQuoteCopy = (
    <>
      We are searching for the carrier and coverage that’s the best fit for you.{' '}
      <b>A Matic agent will be reaching out soon</b> to discuss your options. Have a specific time you’d like to chat?
      Schedule below.
    </>
  );
  if (isEngagementDataLoading) {
    return <NoQuotesLoader />;
  }

  return (
    <>
      {engagementData?.engagement?.soft_stop_page_enabled ? (
        <SoftStopPage userName={primaryInsuredData?.person.first_name} />
      ) : (
        <div css={pageWrapperCSS}>
          <div css={wrapperCSS}>
            <img css={separateHouseIconCSS} src={SeparatedHousesWithClouds} alt="" />
            <div css={headerContentCSS}>
              <img src={PlantAndPhone} css={noQuoteImageCSS} alt="" />
              <div css={headerCSS}>We’re going to need to chat.</div>
              <div css={subheaderCSS}>{noQuoteCopy}</div>
              <div css={elipseCSS} />
            </div>
            <img css={doubleHouseIconCSS} src={DoubleHousesWithClouds} alt="" />
          </div>

          <QuotePageScheduleCall
            hideHeading
            callScheduledInfoBlock={bundleAnswer ? <CalendlyAutoScheduledMessage /> : null}
          />
          <MaticHelpBlock
            customCSS={cardCSS}
            phoneNumber={phoneData?.contact_phone_number?.number}
            onPhoneClick={onPhoneClicked}
          />
          {!bundleAnswer && (
            <CTACard
              customCSS={cardCSS}
              icon={HomePlusAuto}
              onButtonClick={onScheduleCall}
              heading="Want to Save More?"
              buttonText="Talk to an Advisor"
              subHeading={
                'Speak with an advisor to discuss bundling options based on your home, auto and driver details.'
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default DigitalProfileNoQuotesContent;
