import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { useContactFlows } from '../../../../../api/contact-flows';
import { usePage } from '../../../../../api/page';
import { useCreateCustomerChoice } from '../../../../../api/people';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { usePublicContactPhoneNumber } from '../../../../../api/public/public-contact-phone-number';
import { useSession } from '../../../../../api/session';
import HomePlusAuto from '../../../../../assets/home-plus-auto.svg?react';
import { SEGMENT } from '../../../../../constants/analytics';
import {
  CALENDLY_ID,
  CALENDLY_PREVIEW_DETAILS_ID,
} from '../../../../../hooks/use-init-calendly/use-init-calendly.util';
import { useSegment } from '../../../../../hooks/use-segment';
import Button from '../../../../../shared/components/Button/Button';
import { Card } from '../../../../../shared/components/Card/Card';
import { getQuoteSavings } from '../../../../../utils/quote.util';
import { scrollToNode } from '../../../../../utils/scroll.util';
import CTACard from '../CTACard/CTACard';
import CalendlyAutoScheduledMessage from '../CalendlyAutoScheduledMessage/CalendlyAutoScheduledMessage';
import DigitalProfileQuoteDetails from '../DigitalProfileQuoteDetails/DigitalProfileQuoteDetails';
import DigitalProfileQuotePageHeading from '../DigitalProfileQuotePageHeading/DigitalProfileQuotePageHeading';
import MaticHelpBlock from '../MaticHelpBlock/MaticHelpBlock';
import QuotePageScheduleCall from '../QuotePageScheduleCall/QuotePageScheduleCall';
import { cardCSS, showQuoteCardCSS } from './DigitalProfileQuoteContent.style';
import { getCalendlyHeading } from './DigitalProfileQuoteContent.util';
import type { DigitalProfileQuoteContentProps } from './DigitalProfileQuoteContent.type';
import type { Address } from '../../../../../types/address.type';
import type { Carrier } from '../../../../../types/carrier.type';
import type { DigitalProfileQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC, ReactElement } from 'react';

const DigitalProfileQuoteContent: FC<DigitalProfileQuoteContentProps> = ({
  quotes,
  bundleAnswer,
  onPhoneClicked,
  onScheduleCallClick,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage(gid, currentPageKey);
  const { data: carrierData } = useSelectedCarrier<Carrier | null>(gid);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { data: effectivePolicy } = useEffectivePolicy(gid);
  const { data: phoneData } = usePublicContactPhoneNumber(gid);

  const { track } = useSegment();

  const { mutateAsync: createCustomerChoice } = useCreateCustomerChoice();
  const [lastCustomerChoice, setLastCustomerChoice] = useState<string | null>(null);

  const { data: contactFlowData } = useContactFlows(gid);

  const lastContactFlow = contactFlowData?.contact_flows[0];

  const [showAllQuotes, setShowAllQuotes] = useState(false);
  const finalQuoteViewedRef = useRef(false);

  // TODO: We can remove NoQuoteScenario here, because we handle it on higher level
  const isNoQuoteScenario = quotes.length === 0;
  const isFirstOriginalQuote =
    !isNoQuoteScenario && !!carrierData?.carrier?.key && quotes[0]?.carrier_key === carrierData.carrier.key;
  const cheapestQuote: DigitalProfileQuote | undefined = quotes[0];

  const cheapestQuoteSavings = getQuoteSavings(
    cheapestQuote,
    !!cheapestQuote?.multi_policy_discount_premium,
    effectivePolicy?.effective_policy
  );
  const originalQuote = quotes.find((q) => q.carrier_key === carrierData?.carrier?.key);

  const addBundleMessageToHeading = isFirstOriginalQuote
    ? !!bundleAnswer && !!originalQuote?.multi_policy_discount_premium
    : !!bundleAnswer && quotes.some((q) => q.multi_policy_discount_premium);

  const customerChoice = isNoQuoteScenario ? null : (lastCustomerChoice ?? cheapestQuote.gid);

  useEffect(() => {
    if (!finalQuoteViewedRef.current && cheapestQuote) {
      finalQuoteViewedRef.current = true;
      track(SEGMENT.events.finalQuoteViewed, {
        quotes: [cheapestQuote.gid],
      });

      track(SEGMENT.events.quoteSavingsViewed, {
        value: Number(!!cheapestQuoteSavings),
      });
    }
  }, [track, cheapestQuote, cheapestQuoteSavings]);

  const onScheduleCall = (quoteGid: string | null): void => {
    onScheduleCallClick();
    scrollToNode(lastContactFlow ? CALENDLY_PREVIEW_DETAILS_ID : CALENDLY_ID);
    onCustomerChoiceCreate(quoteGid);
  };

  const onCustomerChoiceCreate = (quoteGid: string | null): void => {
    if (!quoteGid || !sessionData?.session.engagement_gid) return;

    setLastCustomerChoice(quoteGid);

    const customerChoiceParams = {
      quoteGid,
      engagementGid: sessionData?.session.engagement_gid,
    };

    createCustomerChoice(customerChoiceParams).catch((err) => Sentry.captureException(err));
  };

  const onSeeOriginalQuote = (): void => setShowAllQuotes(true);

  const showButton = (quotes.length >= 2 && !isFirstOriginalQuote && originalQuote && !showAllQuotes) ?? false;
  const address = (pageData?.answers?.property_address as Address) || primaryInsuredData?.person.mailing_address;

  const renderQuotes = (): ReactElement => {
    const quotesToRender = [quotes[0]];

    if (showAllQuotes && originalQuote) {
      quotesToRender.push(originalQuote);
    }

    return (
      <Fragment>
        {quotesToRender.map((q, index) => (
          <DigitalProfileQuoteDetails
            key={q.carrier_key}
            coverages={q.coverages}
            premium={q.premium}
            savings={getQuoteSavings(q, !!q.multi_policy_discount_premium, effectivePolicy?.effective_policy)}
            deductible={q?.deductible}
            multiPolicyDiscountPremium={q.multi_policy_discount_premium}
            bundleAnswer={bundleAnswer}
            carrierName={q.carrier_key}
            carrierLogo={q.carrier_logo_url}
            actionButtonLabel={lastContactFlow ? 'Appointment details' : 'Schedule a call'}
            onScheduleCall={() => onScheduleCall(q.gid)}
            quoteIndex={index}
          />
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <DigitalProfileQuotePageHeading
        isFirstOriginalQuote={isFirstOriginalQuote}
        originalQuote={originalQuote}
        isSelectedCarrierUnknown={!carrierData?.carrier?.key}
        cheapestQuote={cheapestQuote}
        personAddress={address}
        bundleMessage={addBundleMessageToHeading}
      />
      {!isNoQuoteScenario && renderQuotes()}
      {showButton && (
        <Card customCSS={showQuoteCardCSS}>
          <Button variant="secondary" onClick={onSeeOriginalQuote}>
            View My Original Quote
          </Button>
        </Card>
      )}
      <QuotePageScheduleCall
        hideHeading={isNoQuoteScenario}
        heading={getCalendlyHeading(!!cheapestQuoteSavings, !!bundleAnswer)}
        callScheduledInfoBlock={bundleAnswer ? <CalendlyAutoScheduledMessage /> : null}
        onCallScheduled={() => onCustomerChoiceCreate(customerChoice)}
      />
      <MaticHelpBlock
        customCSS={cardCSS}
        phoneNumber={phoneData?.contact_phone_number?.number}
        onPhoneClick={onPhoneClicked}
      />

      {!bundleAnswer && (
        <CTACard
          customCSS={cardCSS}
          icon={HomePlusAuto}
          onButtonClick={() => onScheduleCall(customerChoice)}
          heading="Want to Save More?"
          buttonText="Talk to an Advisor"
          subHeading="Speak with an advisor to discuss bundling options based on your home, auto and driver details."
        />
      )}
    </Fragment>
  );
};

export default DigitalProfileQuoteContent;
