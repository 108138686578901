import PhoneIcon from '../../../../../assets/phone.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import Anchor from '../../../../../shared/components/Anchor/Anchor';
import format from '../../../../../utils/format.util';
import { callRibbonCSS, linkCSS, iconCSS } from './CallAgentRibbon.style';
import type { CallAgentRibbonProps } from './CallAgentRibbon.type';
import type { FC } from 'react';

const CallAgentRibbon: FC<CallAgentRibbonProps> = ({ customCSS, phoneNumber, onPhoneClick }) => {
  const { isMobile } = useResponsive();

  const content = isMobile ? (
    <>
      <PhoneIcon css={iconCSS} />
      Call Now:
      <Anchor customCSS={linkCSS} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
        {format.phone(phoneNumber)}
      </Anchor>
    </>
  ) : (
    <>
      Speak to a Licensed Matic Advisor Now
      <Anchor customCSS={linkCSS} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
        <PhoneIcon css={iconCSS} />
        {format.phone(phoneNumber)}
      </Anchor>
    </>
  );

  if (!phoneNumber) {
    return null;
  }

  return <div css={[callRibbonCSS, customCSS]}>{content}</div>;
};

export default CallAgentRibbon;
