import { useParams } from 'react-router-dom';
import { useCreateLoan, useMutateLoan, useRecentLoan } from '../../../../../api/loan';
import { useMutatePrimaryInsured, usePrimaryInsured } from '../../../../../api/primary-insured';
import { useServicers } from '../../../../../api/servicer';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import { maskSensitiveInfo } from '../../../../../utils/string-helpers';
import { MORTGAGE_STEP_HINT, PAYMENT_METHOD_ESCROW_OPTION } from '../CheckoutHomeOpportunityPage.util';
import CheckoutMortgageForm from '../CheckoutMortgageForm/CheckoutMortgageForm';
import CheckoutMortgagePreview from '../CheckoutMortgagePreview/CheckoutMortgagePreview';
import { formCSS, infoBlockCSS, previewCSS } from './CheckoutBundleMortgageStep.style';
import { getServicersOptions } from './CheckoutBundleMortgageStep.util';
import type {
  CheckoutBundleMortgageStepProps,
  CheckoutMortgageContactFormState,
} from './CheckoutBundleMortgageStep.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutBundleMortgageStep: FC<CheckoutBundleMortgageStepProps> = ({
  customCSS,
  isCurrent,
  isEdit,
  actionLabel = 'Move to Next Step',
  isSubmitting = false,
  onStepSubmit,
  onBeforeStepSubmit,
}) => {
  const { gid } = useParams() as DefaultLocation;

  const servicersQuery = useServicers();
  const servicers = servicersQuery.data?.servicers;
  const loanQuery = useRecentLoan(gid);
  const loanData = loanQuery.data;
  const primaryInsuredQuery = usePrimaryInsured(gid);
  const primaryInsuredData = primaryInsuredQuery.data;

  const { mutateAsync: createLoan } = useCreateLoan(gid);
  const { mutateAsync: mutateLoan } = useMutateLoan(gid);
  const { mutateAsync: mutatePrimaryInsured } = useMutatePrimaryInsured(gid);

  const onFormSubmit = async (data: CheckoutMortgageContactFormState): Promise<void> => {
    onBeforeStepSubmit();
    const loanPayload = {
      loan_number: data.loan_number,
      servicer_gid: data.servicer_gid,
    };
    const primaryInsuredPayload = {
      email: data.email,
      phone: data.phone,
      mailing_address: data.address,
    };

    await Promise.all([
      loanData?.loan ? mutateLoan({ ...loanPayload, gid: loanData.loan.gid }) : createLoan(loanPayload),
      mutatePrimaryInsured(primaryInsuredPayload),
    ]);

    await onStepSubmit();
  };

  return (
    <div css={customCSS}>
      {isEdit && (
        <>
          <InfoFormBlock customCSS={infoBlockCSS} content={MORTGAGE_STEP_HINT} />
          <CheckoutMortgageForm
            customCSS={formCSS}
            loan={loanData?.loan}
            primaryInsured={primaryInsuredData?.person}
            servicersOptions={getServicersOptions(servicers)}
            actionLabel={actionLabel}
            isSubmitting={isSubmitting}
            onValidSubmit={onFormSubmit}
            isBindDisabled={false}
          />
        </>
      )}

      {!isEdit && loanData?.loan?.servicer && loanData.loan.loan_number && primaryInsuredData?.person && (
        <CheckoutMortgagePreview
          customCSS={previewCSS}
          landerName={loanData.loan.servicer.name}
          loanNumber={maskSensitiveInfo(loanData.loan.loan_number)}
          personEmail={primaryInsuredData.person.email}
          personNumber={primaryInsuredData.person.phone}
          personAddress={primaryInsuredData.person.mailing_address}
          paymentMethod={PAYMENT_METHOD_ESCROW_OPTION.label}
          actionLabel={actionLabel}
          showButton={isCurrent}
          onButtonClick={onStepSubmit}
        />
      )}
    </div>
  );
};

export default CheckoutBundleMortgageStep;
