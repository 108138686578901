import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { textCSS } from '../../../styles/typography.style';
import type { GridWidth } from '../../../types/form-element.type';
import type { SerializedStyles } from '@emotion/react';

const disabledCSS = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const gridWidthCSS = (gridWidth: GridWidth): SerializedStyles => css`
  flex-basis: ${(gridWidth / 12) * 100}%;
  padding-right: ${theme.sizes.inputHorizontalSpacing}px;
`;

export const containerCSS = (
  isDisabled?: boolean,
  gridWidth?: GridWidth | null,
  isExtendedHeight?: boolean
): SerializedStyles => css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  ${isDisabled && disabledCSS};

  ${mq[0]} {
    margin-bottom: 12px;
    ${gridWidth && gridWidthCSS(gridWidth)};

    ${isExtendedHeight &&
    css`
      height: 120px;
    `}
  }
`;

export const labelCSS = (isExtendedHeight?: boolean, bold?: boolean): SerializedStyles => css`
  margin-bottom: 4px;
  ${bold && 'font-weight: 700;'};

  ${mq[0]} {
    ${isExtendedHeight &&
    css`
      height: 40px;
    `}
  }
`;

export const errorCSS = css`
  margin-top: 4px;
`;

export const innerWrapperCSS = css`
  border: 1px solid ${theme.colors.black};
  border-radius: ${theme.sizes.borderRadius}px;
  padding: 16px 20px;
`;

export const labelWithIconCSS = css`
  font-weight: 700;
  margin-left: 12px;
`;

export const descriptionWithIconCSS = css`
  margin-bottom: 4px;
  ${textCSS};
  line-height: 20px;
`;

export const iconLabelWrapperCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
