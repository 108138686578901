import * as Sentry from '@sentry/react';
import { isAxiosError } from 'axios';
import ROUTES from '../constants/routes';
import type { AxiosError } from 'axios';

export interface ApiErrorResponse {
  data?: {
    errors?: string[];
    failure_reason?: ApiErrorFailureReasons;
  };
}

export enum ApiErrorFailureReasons {
  INTERNAL_TECHNICAL_ERROR = 'internal_technical_error',
  AUTHORIZATION_NOT_PASSED = 'authorization_not_passed',
  TIME_OUT = 'time_out',
  BIND_NOT_SUCCESSFUL = 'bind_not_successful',
  RC2_NOT_SUCCESSFUL = 'rc2_not_successful',
  PRIMARY_INSURED_DISQUALIFIED = 'primary_insured_disqualified',
  CARRIER_INTEGRATION_ERROR = 'carrier_integration_error',
  POLICY_ALREADY_SOLD = 'policy_already_sold',
  UNLOCK_NOT_SUCCESSFUL = 'unlock_not_successful',
}

const LOCK_SESSION_STATUS = 423;
const UNPROCESSABLE_ENTITY_STATUS = 422;
const ERROR_STATUS_SKIP = [UNPROCESSABLE_ENTITY_STATUS, LOCK_SESSION_STATUS];

export const isErrorToSkip = (error: unknown): boolean =>
  Boolean(isAxiosError(error) && ERROR_STATUS_SKIP.includes(error.response?.status ?? 0));

export const isLockSessionError = (error: unknown): boolean => {
  if (!isAxiosError<ApiErrorResponse>(error)) return false;

  const errorData = getApiErrorData(error);
  return (
    error.response?.status === LOCK_SESSION_STATUS ||
    errorData?.data?.failure_reason === ApiErrorFailureReasons.UNLOCK_NOT_SUCCESSFUL
  );
};

export const isPrimaryInsuredDisqualifiedError = (error: unknown): boolean => {
  if (!isAxiosError<ApiErrorResponse>(error)) return false;
  const errorData = getApiErrorData(error);

  return errorData?.data?.failure_reason === ApiErrorFailureReasons.PRIMARY_INSURED_DISQUALIFIED;
};

export const isPolicySoldError = (error: unknown): boolean => {
  if (!isAxiosError<ApiErrorResponse>(error)) return false;

  const errorData = getApiErrorData(error);
  return errorData?.data?.failure_reason === ApiErrorFailureReasons.POLICY_ALREADY_SOLD;
};

export const isInternalTechnicalError = (error: unknown): boolean => {
  if (!isAxiosError<ApiErrorResponse>(error)) return false;

  const errorData = getApiErrorData(error);
  const responseStatus = error.response?.status ?? 0;
  return errorData?.data?.failure_reason === ApiErrorFailureReasons.INTERNAL_TECHNICAL_ERROR || responseStatus >= 500;
};

export const isNetworkError = (error: unknown): boolean => {
  return isAxiosError<ApiErrorResponse>(error) && !error.response;
};

export const isNotFoundError = (error: unknown): boolean => {
  if (!isAxiosError<ApiErrorResponse>(error)) return false;

  const responseStatus = error.response?.status ?? 0;
  return responseStatus === 404;
};

export const getApiErrorData = (error: AxiosError<ApiErrorResponse>): ApiErrorResponse | undefined => {
  return error.response?.data;
};

export const captureException = (error: unknown, location?: string): void => {
  Sentry.captureException(error, { tags: { location } });
};

export const getNavigationPathByAPIError = (error: AxiosError<ApiErrorResponse>): string => {
  switch (true) {
    case isLockSessionError(error):
      return ROUTES.sessionLocked;
    case isPolicySoldError(error):
      return ROUTES.policySold;
    case isNotFoundError(error):
      return ROUTES.problem;
    case isPrimaryInsuredDisqualifiedError(error):
      return ROUTES.errorDisqualified;
    default:
      return ROUTES.agent;
  }
};
