import { Fragment, type FC } from 'react';
import CashIcon from '../../../../../../assets/cash.svg?react';
import CheckIcon from '../../../../../../assets/check-circle.svg?react';
import HomeIcon from '../../../../../../assets/home-simple.svg?react';
import AutoIcon from '../../../../../../assets/vehicle.svg?react';
import DashedRow from '../../../../../../shared/components/DashedRow/DashedRow';
import { removeCents, getCents } from '../../../../../../shared/utils/money.util';
import { PolicyType } from '../../../../../../types/policy.type';
import formatUtil from '../../../../../../utils/format.util';

import {
  groupWrapperCSS,
  iconCSS,
  titleCSS,
  checkIconCSS,
  rowCSS,
  titleWrapperCSS,
  dateCSS,
  escrowMessageCSS,
} from '../CheckoutBundleSummary.style';

import { getSummaryPropertiesToRender, feesTooltipContent } from '../CheckoutBundleSummary.util';
import type { CheckoutPolicySummaryProps } from './CheckoutPolicySummary.type';

const CheckoutPolicySummary: FC<CheckoutPolicySummaryProps> = ({
  isStepCompleted,
  isStepActive,
  policySummary,
  paymentMethod,
  policyType,
  effectiveDate,
  paymentType,
  isFinalStep = false,
}) => {
  const propertiesToRender = getSummaryPropertiesToRender(
    policyType,
    paymentMethod,
    paymentType,
    isStepCompleted,
    policySummary
  );

  return (
    <div css={groupWrapperCSS(isStepCompleted)}>
      <div css={titleWrapperCSS}>
        <div css={titleCSS(isStepActive || isStepCompleted)}>
          {policyType === PolicyType.Home ? (
            <Fragment>
              <HomeIcon css={iconCSS(isStepActive || isStepCompleted)} /> <div>Home policy</div>
            </Fragment>
          ) : policyType === PolicyType.Auto ? (
            <Fragment>
              <AutoIcon css={iconCSS(isStepActive || isStepCompleted)} /> <div>Auto policy</div>
            </Fragment>
          ) : (
            <Fragment>
              <CashIcon css={iconCSS(isStepActive || isStepCompleted)} /> <div>Checkout</div>
            </Fragment>
          )}

          {isStepCompleted && <CheckIcon css={checkIconCSS} />}
        </div>
        {(isStepActive || isStepCompleted) && effectiveDate && <div css={dateCSS}>Start date: {effectiveDate}</div>}
      </div>
      {(isStepActive || isStepCompleted) &&
        propertiesToRender.map((property, index) =>
          property?.isEscrow ? (
            <div key={index} css={escrowMessageCSS}>
              Matic will partner work with your lender to pay your new insurance premium from your escrow account as
              part of your mortgage payments.
            </div>
          ) : (
            !!property?.value && (
              <DashedRow
                key={index}
                tooltipContent={property?.isTooltip ? feesTooltipContent : ''}
                customCSS={rowCSS(isStepCompleted && index === propertiesToRender.length - 1, isFinalStep)}
                label={property.label}
                content={
                  property.isMoney
                    ? `${formatUtil.money(removeCents(property?.value), 0, '$')}` +
                      `.${getCents(property?.value)}${property.isMonthly ? '/m.' : ''}`
                    : property.value
                }
              />
            )
          )
        )}
    </div>
  );
};

export default CheckoutPolicySummary;
