import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

export enum StepTitleButtonType {
  Edit = 'edit',
  AddCoInsured = 'add-co-insured',
  RemoveCoInsured = 'remove-co-insured',
}

export interface CheckoutStepTitleProps {
  customCSS?: SerializedStyles;
  stepNumber: number;
  title: ReactNode;
  titleButton?: ReactNode;
  hideStepNumber?: boolean;
  isFinished?: boolean;
  isDisabled?: boolean;
  isSkipped?: boolean;
  buttonType?: StepTitleButtonType | null;
  onButtonClick?: () => void;
}
