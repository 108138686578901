import { css } from '@emotion/react';
import { arrowCSS as accordionArrowCSS } from '../../../../../shared/components/Accordion/Accordion.style';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text32CSS, textCSS, text20CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const summaryHeroCSS = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${mq[1]} {
    align-items: center;
  }
`;

export const logoCSS = css`
  margin-top: 8px;
  ${mq[1]} {
    margin-top: 0;
  }
`;

export const dividerCSS = css`
  display: none;
  ${mq[1]} {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const annualPremiumBoxCSS = css`
  text-align: right;
  ${mq[1]} {
    text-align: left;
  }
`;

export const annualPremiumValueCSS = css`
  color: ${theme.colors.positiveGreen27};
  ${text32CSS};
  line-height: 40px;
  font-weight: 900;

  span {
    ${textCSS};
  }
`;

export const annualPremiumLabelCSS = css`
  margin-top: 2px;
  font-weight: 500;
`;

export const expandButtonCSS = css`
  ${activeElementResetCSS};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 16px 12px;
  border-top: 1px solid ${theme.colors.gray10};
`;

export const expandLabelCSS = css`
  font-weight: 700;
  color: ${theme.colors.azure50};
`;

export const arrowCSS = css`
  ${accordionArrowCSS};
  margin-left: 8px;
  &:before,
  &:after {
    background-color: ${theme.colors.azure50};
  }
`;

export const rowsContainerCSS = css`
  margin-top: 20px;
`;

export const loadingCSS = css`
  margin-bottom: 12px;
`;

export const rowCSS = (isBold?: boolean, isFinalStep?: boolean): SerializedStyles => css`
  margin-bottom: 12px;
  font-weight: 500;
  border-color: ${theme.colors.gray30};

  & span:last-of-type {
    font-weight: 700;
  }

  &>span: first-of-type {
    color: ${theme.colors.black};
  }

  ${mq[1]} {
    & > span:before {
      background-color: ${theme.colors.gray8};
    }
  }

  ${isBold &&
  css`
    color: ${theme.colors.black};
    font-weight: 700;
  `}

  ${isFinalStep &&
  css`
    & > span {
      font-weight: 700;
      ${text20CSS};
      color: ${theme.colors.black};
    }

    & span:first-of-type {
      font-weight: 700;
      ${text20CSS};
      color: ${theme.colors.black};
    }
  `}
`;

export const effectiveDateCSS = css`
  ${rowCSS()};
  margin-top: 25px;
  margin-bottom: 0;
  & span:first-of-type {
    font-weight: 600;
    color: ${theme.colors.black};
  }
`;

export const additionalDiscountCSS = css`
  padding: 16px;
  margin-top: 24px;
  background-color: ${theme.colors.positiveGreen94};
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const groupWrapperCSS = (isCompleted: boolean): SerializedStyles => css`
  margin-bottom: 42px;

  ${isCompleted &&
  css`
    & > div:last-of-type {
      & > span:first-of-type {
        font-weight: bold;
      }
    }
  `}
`;

export const iconCSS = (isActive: boolean): SerializedStyles => css`
  color: ${isActive ? theme.colors.gray60 : theme.colors.gray30};
  height: 20px;
  margin-right: 8px;
`;

export const titleWrapperCSS = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const dateCSS = css`
  font-style: italic;
  color: ${theme.colors.gray60};
`;

export const titleCSS = (isActive: boolean): SerializedStyles => css`
  display: flex;
  color: ${isActive ? theme.colors.black : theme.colors.gray30};
  font-size: 20px;
  font-weight: 700;
  align-items: center;

  ${isActive &&
  css`
    padding-bottom: 12px;
  `};
`;

export const policyDetailsButtonCSS = css`
  background: transparent;
  border: none;
  color: ${theme.colors.azure50};
  cursor: pointer;
  font-weight: 700;
`;

export const checkIconCSS = css`
  margin-left: 8px;
  height: 16px;
`;

export const escrowMessageCSS = css`
  padding: 8px;
  background: ${theme.colors.azure95};
  margin: 12px 0;
  font-size: 14px;
  font-weight: 500;
`;
