import { tz } from '@date-fns/tz';
import { add } from 'date-fns/add';
import { format } from 'date-fns/format';
import { enUS } from 'date-fns/locale/en-US';
import { toZonedTime, format as formatTz } from 'date-fns-tz';

const DEFAULT_TIME_ZONE = 'US/Eastern';

export const getScheduledCallTime = (scheduledTime: string, timeZoneName?: string | null): string => {
  const timeZone = timeZoneName || DEFAULT_TIME_ZONE;
  const startHour = format(scheduledTime, 'HH:mm', { in: tz(timeZone) });
  const endHour = format(add(scheduledTime, { minutes: 10 }), 'HH:mm', { in: tz(timeZone) });
  const time = format(scheduledTime, 'EEEE, MMMM d, yyyy', { in: tz(timeZone) });

  return `${startHour} - ${endHour}, ${time}`;
};

// TODO: Remove date-fns-tz package when date-fns/format will support correct formatting of timezones
export const getScheduledCallTimezone = (scheduledTime: string, timeZoneName?: string | null): string => {
  const timeZone = timeZoneName || DEFAULT_TIME_ZONE;
  const date = toZonedTime(scheduledTime, timeZone);
  return formatTz(date, 'zzzz (XXX)', { timeZone, locale: enUS });
};
