import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { usePublicContactPhoneNumber } from '../../../../../api/public/public-contact-phone-number';
import LiveHelpButton from '../../../../../components/LiveHelpButton/LiveHelpButton';
import PageLoader from '../../../../../components/PageLoader/PageLoader';
import { SEGMENT } from '../../../../../constants/analytics';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import { useSegment } from '../../../../../hooks/use-segment';
import Header from '../../../../../shared/components/Header/Header';
import { containerCSS, imageCSS, loaderCSS } from './CheckoutHomePageLoader.style';
import { getSubheader, HEADERS } from './CheckoutHomePageLoader.util';
import type { Carrier } from '../../../../../types/carrier.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutHomePageLoader: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: carrierData } = useSelectedCarrier<Carrier>(gid);
  const { track } = useSegment();
  const [index, setIndex] = useState(0);
  const { data: phoneData } = usePublicContactPhoneNumber(gid);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex === HEADERS.length - 1) {
          clearInterval(timer);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onPhoneClicked = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: SEGMENT.pages.error.name,
      location,
    });
  };

  return (
    <div css={containerCSS}>
      <Header
        carrierLogo={carrierData?.carrier.logo_url}
        carrierName={carrierData?.carrier.name}
        phoneNumber={phoneData?.contact_phone_number?.number}
        showContacts
        denseContainer
        onPhoneClick={() => onPhoneClicked(SEGMENT.locations.header)}
      >
        <LiveHelpButton />
      </Header>
      <PageLoader
        customCSS={loaderCSS}
        customImageCSS={imageCSS}
        imgUrl={SIRV_IMAGES.checkoutLoader}
        header={HEADERS[index]}
        subheader={getSubheader(index, carrierData?.carrier.name)}
      />
    </div>
  );
};

export default CheckoutHomePageLoader;
