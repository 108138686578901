import type { AssetHashGroup } from './AutoQuoteCoverages.type';
import type { AutoQuoteCoverage, QuoteAsset } from '../../../../../types/quote.type';
import type { QuoteCoveragesGroup } from '../../QuoteCoverageGroup/QuoteCoverageGroup.type';

const POLICY_AUTO_COVERAGES_GROUP: AssetHashGroup = {
  index: 0,
  gid: 'without_asset',
  name: 'Policy Coverages',
};

const getAssetsHash = (assets: QuoteAsset[] = []): Record<string, AssetHashGroup> => {
  return assets.reduce((acc, asset, index) => {
    return { ...acc, [asset.gid]: { gid: asset.gid, name: asset.name, iconUrl: asset.icon_url, index: index + 1 } };
  }, {});
};
export const getCoveragesGroups = (
  coverages: AutoQuoteCoverage[],
  assets: QuoteAsset[] = []
): QuoteCoveragesGroup[] => {
  const assetsHash = getAssetsHash(assets);
  return coverages
    .reduce<QuoteCoveragesGroup[]>((acc, coverage) => {
      const group =
        coverage.asset_gid && assetsHash[coverage.asset_gid]
          ? assetsHash[coverage.asset_gid]
          : POLICY_AUTO_COVERAGES_GROUP;
      const res = [...acc];

      if (!res[group.index]) {
        res[group.index] = {
          assetGid: group.gid,
          title: group.name,
          iconUrl: group.iconUrl,
          coverages: [],
        };
      }

      res[group.index].coverages.push({
        title: coverage.title,
        value: coverage.value,
        description: coverage.description,
        key: coverage.key,
      });
      return res;
    }, [])
    .filter((g) => !!g);
};
