import * as Sentry from '@sentry/browser';
import { useEffect, useRef } from 'react';
import analytics from '../utils/analytics.util';
import { isErrorToSkip } from '../utils/error.util';
import type { Integrations } from '../types/public-config.type';

const initAllIntegrations = (integrations: Integrations): void => {
  integrations.segment?.enabled && integrations.segment.key && analytics.init(integrations.segment.key);

  if (integrations.sentry?.dsn && integrations.sentry.environment) {
    Sentry.init({
      dsn: integrations.sentry.dsn,
      environment: integrations.sentry.environment,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      denyUrls: [
        'http://localhost:5173',
        'http://localhost:3000',
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /^moz-extension:\/\//i,
      ],
      // TODO: Add FullStory integration https://www.npmjs.com/package/@sentry/fullstory
      integrations: [Sentry.httpClientIntegration(), Sentry.extraErrorDataIntegration()],
      initialScope: {
        tags: { sender: 'browser' },
      },
      beforeSend: (event, hint) => {
        if (isErrorToSkip(hint.originalException)) {
          return null;
        }
        return event;
      },
    });
  }
};

const useAnalytics = (integrations?: Integrations): void => {
  const readyRef = useRef<boolean>();

  useEffect(() => {
    if (import.meta.env.PROD && integrations && !readyRef.current) {
      try {
        readyRef.current = true;
        initAllIntegrations(integrations);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load analytics', error);
      }
    }
  }, [integrations]);
};

export default useAnalytics;
