import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { QuoteRequestNoQuotesResponse } from '../../types/quote.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useLastQuoteRequestNoQuotes = (gid?: string, enabled = true): QueryObserverResult<QuoteRequestNoQuotesResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.quoteRequestNoQuotes, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<QuoteRequestNoQuotesResponse>>(getApiUrl(API_PATH.quoteLastNoQuotes, gid as string))
        .then((res) => res.data),
    refetchInterval: (query) => (query.state.data?.quotes_request?.finished_at ? false : 10000),
    enabled: !!gid && enabled,
  });

export default useLastQuoteRequestNoQuotes;
