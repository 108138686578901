import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { isInternalTechnicalError, isNetworkError } from '../../../../utils/error.util';
import { getRouteParamsFromLocationPath } from '../../../../utils/route-path.util';
import ErrorFallbackContent from '../ErrorFallbackContent/ErrorFallbackContent';
import { getNavigateProps } from './ErrorFallback.util';
import type { ApiErrorResponse } from '../../../../utils/error.util';
import type { FC } from 'react';

const ErrorFallback: FC<{ error: unknown; resetError: () => void }> = ({ error, resetError }) => {
  const location = useLocation();
  const { gid, flow } = getRouteParamsFromLocationPath(location.pathname);

  const shouldNavigateToDifferentRoute =
    isAxiosError<ApiErrorResponse>(error) && !isInternalTechnicalError(error) && !isNetworkError(error) && flow && gid;

  useEffect(() => {
    if (shouldNavigateToDifferentRoute) {
      resetError();
    }
  }, [shouldNavigateToDifferentRoute, resetError]);

  if (!shouldNavigateToDifferentRoute) {
    return <ErrorFallbackContent resetError={resetError} />;
  }

  return <Navigate {...getNavigateProps(error, flow, gid)} />;
};

export default ErrorFallback;
