import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const dashedRowCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: ${theme.sizes.borderWidth / 4}px dashed ${theme.colors.gray60};
  align-items: flex-end;

  & > span {
    position: relative;
    z-index: 0;
  }

  & > span:first-of-type {
    font-weight: 400;
    color: ${theme.colors.gray60};
  }

  & > span:before {
    position: absolute;
    z-index: -1;
    top: 2px;
    width: calc(100% + 6px);
    height: 100%;
    content: '';
    background-color: ${theme.colors.white};
  }

  & > span:last-of-type:before {
    right: 0;
  }
`;

export const tooltipCSS = css`
  background: transparent;
`;
