import AnimatedDashRow from '../../../../../assets/preview-page/anim-dashed-row.svg?react';
import { animatedDashedLoaderCSS, containerCSS } from './FinalizeSteps.style';
import { INTRO_STEPS_CONTENT } from './FinalizeSteps.utils';
import StepItem from './StepItem/StepItem';
import type { FinalizeStepsProps } from './FinalizeSteps.type';
import type { FC } from 'react';

const FinalizeSteps: FC<FinalizeStepsProps> = ({ customCSS }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      {INTRO_STEPS_CONTENT.map((content, i) => (
        <StepItem {...content} count={i + 1} key={content.description} />
      ))}
      <AnimatedDashRow css={animatedDashedLoaderCSS} />
    </div>
  );
};

export default FinalizeSteps;
