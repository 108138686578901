import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import QuestionLabel from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import { QuestionLabelWrapper } from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel.type';
import { errorCSS } from '../../../../../questionsForm/components/QuestionWrapper/QuestionWrapper.style';
import ValidationError from '../../../../../shared/components/ValidationError/ValidationError';
import {
  activeOutlineCSS,
  containerCSS,
  inputWrapperCSS,
  labelCSS,
  stripeElementCSS,
} from './StripeInputElement.style';
import { StripeInputType } from './StripeInputElement.type';
import { getLabel } from './StripeInputElement.util';
import type { StripeInputElementProps } from './StripeInputElement.type';
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import type { FC, ReactNode } from 'react';

const StripeInputElement: FC<StripeInputElementProps> = ({ customCSS, type, disabled = false }) => {
  const [error, setError] = useState<string>();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const label = getLabel(type);

  const onReady = (): void => {
    setIsLoading(false);
  };

  const onBlur = (): void => {
    setIsFocused(false);
  };

  const onFocus = (): void => {
    setIsFocused(true);
  };

  const onChange = (
    event: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent
  ): void => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(undefined);
    }
  };

  const renderStripeElement = (): ReactNode => {
    const baseProps = {
      onReady,
      onBlur,
      onFocus,
      onChange,
    };

    switch (type) {
      case StripeInputType.CardNumber:
        return (
          <CardNumberElement
            {...baseProps}
            options={{ showIcon: true, style: stripeElementCSS, disabled }}
            className={FS_SENSITIVE_DATA_CLASS.mask}
            id="card-number"
          />
        );
      case StripeInputType.CardExpiry:
        return (
          <CardExpiryElement
            {...baseProps}
            options={{ style: stripeElementCSS, disabled }}
            className={FS_SENSITIVE_DATA_CLASS.mask}
            id="card-expiry"
          />
        );
      case StripeInputType.CardCvc:
        return (
          <CardCvcElement
            {...baseProps}
            options={{ placeholder: 'XXX', style: stripeElementCSS, disabled }}
            className={FS_SENSITIVE_DATA_CLASS.mask}
            id="card-cvc"
          />
        );
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <QuestionLabel customCSS={labelCSS} keyName={type} wrapperElement={QuestionLabelWrapper.Div}>
        {label}
      </QuestionLabel>
      <div css={inputWrapperCSS(isLoading, !!error, isFocused, disabled)}>
        {renderStripeElement()}
        {isFocused && <div css={activeOutlineCSS(isFocused, !!error)} />}
      </div>
      <ValidationError customCSS={errorCSS} heading={label} visible={!!error}>
        {error}
      </ValidationError>
    </div>
  );
};

export default StripeInputElement;
