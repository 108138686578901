import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { useMutateSession, useSession } from '../../../../../api/session';
import FlowNavigation from '../../../../../components/FlowNavigation/FlowNavigation';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import { useSegment } from '../../../../../hooks/use-segment';
import { innerLayoutContainerCSS, layoutNavigationCSS, pageHeadingCSS } from '../../../../../styles/layout.style';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import format from '../../../../../utils/format.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import NavigationCheckoutButton from '../../../../checkout/components/CheckoutAutoOpportunityPage/NavigationCheckoutButton/NavigationCheckoutButton';
import AutoPolicyDetails from '../AutoPolicyDetails/AutoPolicyDetails';
import { QUOTE_PAGE_DISCLAIMER } from '../AutoQuoteOpportunityPage.util';
import { disclaimerCSS } from './AutoQuotePageContent.style';
import type { AutoQuotePageContentProps } from './AutoQuotePageContent.type';
import type { Carrier } from '../../../../../types/carrier.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { SessionResponse } from '../../../../../types/session.type';
import type { FC } from 'react';

const AutoQuotePageContent: FC<AutoQuotePageContentProps> = ({ customCSS, quote }) => {
  const navigate = useNavigate();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: carrierData } = useSelectedCarrier<Carrier>(gid);
  const { data: sessionData } = useSession(gid);

  const currentPageKey = sessionData?.session.current_page_key;
  const { mutateAsync: mutateSession } = useMutateSession(gid);
  const queryClient = useQueryClient();

  const premium = format.money(String(quote.premium), 0, '$');
  const quoteViewedRef = useRef(false);

  const { track } = useSegment();

  useEffect(() => {
    if (quote.premium && !quoteViewedRef.current) {
      quoteViewedRef.current = true;
      track(SEGMENT.events.finalQuoteViewed, {
        quotes: [quote.gid],
      });
    }
  }, [track, quote]);

  const onSubmit = async (): Promise<void> => {
    if (carrierData) {
      track(SEGMENT.events.quoteSelected, {
        ordinal: 1,
        carrier_key: carrierData.carrier.key,
      });
    }

    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });

    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  return (
    <div css={customCSS}>
      <div css={layoutNavigationCSS}>
        <FlowNavigation />
        <NavigationCheckoutButton premium={premium} policyTermInMonths={quote.policy_term} onSubmit={onSubmit} />
      </div>
      <div css={innerLayoutContainerCSS(true)}>
        <div css={pageHeadingCSS}>Review your policy details.</div>
        <AutoPolicyDetails
          premium={premium}
          coverages={quote.coverages}
          policyTermInMonths={quote.policy_term}
          effectiveDate={quote.policy_effective_date}
          assets={quote.assets}
          onSubmit={onSubmit}
        />
        <div css={disclaimerCSS}>{QUOTE_PAGE_DISCLAIMER}</div>
      </div>
    </div>
  );
};

export default AutoQuotePageContent;
