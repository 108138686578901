import { useCallback, useEffect, useState } from 'react';
import { SubscriptionEventMessage } from '../../types/socket.type';
import useLastQuoteRequestNoQuotes from './use-last-quote-request-no-quotes';
import type { SubscriptionEventData } from '../../types/socket.type';

interface QuoteRequestsSubscription {
  isFinished?: boolean;
  startSubscription: () => void;
}

interface QuoteRequestsSubscriptionProps {
  gid: string;
  webSocketUrl?: string;
}

const getSubscribeMessage = (gid: string): string =>
  JSON.stringify({
    command: 'subscribe',
    identifier: JSON.stringify({
      channel: 'SessionChannel',
      session_gid: gid,
    }),
  });

export const waitForQuoteRequestCompleted = async (gid: string, webSocketUrl: string): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    const websocket = new WebSocket(webSocketUrl);
    websocket.onopen = () => {
      websocket.send(getSubscribeMessage(gid));
    };
    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data) as SubscriptionEventData;

      if (data.message?.message === SubscriptionEventMessage.QuotesRequestFinished) {
        resolve(true);
        websocket.close();
      }
    };
    websocket.onerror = (error) => {
      reject(error);
      websocket.close();
    };
  });
};

export const useQuoteRequestsSubscription = ({
  gid,
  webSocketUrl,
}: QuoteRequestsSubscriptionProps): QuoteRequestsSubscription => {
  const [isSocketOpen, setIsSocketOpen] = useState(false);
  const [isFinished, setIsFinished] = useState<boolean>();

  // Fallback if sockets are not supported or missed connection
  const { data: lastQuoteRequestData } = useLastQuoteRequestNoQuotes(gid);
  const isLastQuoteRequestFinished = lastQuoteRequestData?.quotes_request?.finished_at;

  useEffect(() => {
    if (isLastQuoteRequestFinished) {
      setIsFinished(true);
      setIsSocketOpen(false);
    }
  }, [isLastQuoteRequestFinished]);

  useEffect(() => {
    let websocket: WebSocket;

    if (isSocketOpen && webSocketUrl) {
      websocket = new WebSocket(webSocketUrl);
      websocket.onopen = () => {
        websocket.send(getSubscribeMessage(gid));
      };
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data) as SubscriptionEventData;

        if (data.message?.message === SubscriptionEventMessage.QuotesRequestFinished) {
          setIsFinished(true);
          setIsSocketOpen(false);
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.close();
        setIsFinished(false);
      }
    };
  }, [isSocketOpen, gid, webSocketUrl]);

  const startSubscription = useCallback(() => {
    setIsSocketOpen(true);
  }, []);

  return {
    isFinished,
    startSubscription,
  };
};

export default useQuoteRequestsSubscription;
