import type { ButtonProps } from '../Button/Button.type';
import type { SerializedStyles } from '@emotion/react';
import type { FC, ReactNode, SVGProps } from 'react';

export interface ModalActionButton extends ButtonProps {
  text: string;
  onClick: () => void;
}

export enum ButtonDirection {
  Row = 'row',
  Column = 'column',
}

export interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  title?: ReactNode;
  isFullScreen?: boolean;
  icon?: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  customCSS?: SerializedStyles;
  isLoading?: boolean;
  isNotActionable?: boolean;
  actionButtons?: ModalActionButton[];
  buttonDirection?: ButtonDirection;
  isExtendedWidth?: boolean;
  onClose?: () => void;
}
