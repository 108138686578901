import { FormElementTag } from '../../../../../types/form-element.type';
import { QuestionType } from '../../../../../types/question.type';
import { HOME_POLICY_TYPES } from '../../../../../types/quote.type';
import {
  getAutoPolicyEffectiveDateComponent,
  getHomePolicyEffectiveDateComponent,
} from '../../../../../utils/schema.util';
import stringTemplater from '../../../../../utils/string-templater.util';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { QuoteDetailsQuestion } from '../QuoteDetailsEditButton/QuoteDetailsEditButton.type';

export const getEffectiveDateQuestionToRender = (
  effectiveDate: string,
  policyType: string,
  sections?: CustomSectionSchema[],
  currentPolicyExpirationDate?: string | null
): QuoteDetailsQuestion | null => {
  const homeEffectiveDateComponent = getHomePolicyEffectiveDateComponent(sections);
  const autoEffectiveDateComponent = getAutoPolicyEffectiveDateComponent(sections);
  const isHomePolicy = HOME_POLICY_TYPES.includes(policyType);

  const component = isHomePolicy ? homeEffectiveDateComponent : autoEffectiveDateComponent;
  if (!component) {
    return null;
  }

  const getComponentDescription = (description: string | undefined): string => {
    if (description) {
      const policyTypeDescription = stringTemplater(description, { policy_type: isHomePolicy ? 'home' : 'auto' });
      return stringTemplater(policyTypeDescription, {
        effective_date: currentPolicyExpirationDate ? `on ${currentPolicyExpirationDate}` : null,
      });
    }
    return '';
  };

  return {
    questionKey: component?.key,
    type: QuestionType.Date,
    label: component?.label,
    validations: component?.validations,
    dense: true,
    tags: [FormElementTag.DatePicker, FormElementTag.RenderDropDownInBody],
    answer: effectiveDate,
    description: getComponentDescription(component.description),
  };
};
