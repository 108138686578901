import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePublicContactPhoneNumber from '../../api/public/public-contact-phone-number/use-public-contact-phone-number';
import PhoneIcon from '../../assets/phone.svg?react';
import ErrorPageMessage from '../../components/ErrorPageMessage/ErrorPageMessage';
import { SEGMENT } from '../../constants/analytics';
import ROUTES from '../../constants/routes';
import { useSegment } from '../../hooks/use-segment';
import Button from '../../shared/components/Button/Button';
import { buttonCSS } from '../../shared/components/Button/Button.style';
import { ButtonSize } from '../../shared/components/Button/Button.type';
import Container from '../../shared/components/Container/Container';
import Footer from '../../shared/components/Footer/Footer';
import Header from '../../shared/components/Header/Header';
import {
  errorPageActionButtonCSS,
  errorPageAnchorButtonCSS,
  errorPageButtonIconCSS,
  errorPageCenterContainerCSS,
  errorPageSectionCSS,
} from '../../styles/common.style';
import { fullHeightPageCSS } from '../../styles/layout.style';
import { captureException } from '../../utils/error.util';
import type { FC } from 'react';

const ErrorLayout: FC = () => {
  const { state } = useLocation();
  const { page } = useSegment();
  const { track } = useSegment();
  const navigate = useNavigate();
  const { data: phoneNumberData } = usePublicContactPhoneNumber(null);
  const errorToReport = state?.errorToReport;

  useEffect(() => {
    page(SEGMENT.pages.error.name);
  }, [page]);

  useEffect(() => {
    if (errorToReport) {
      captureException(errorToReport, 'error_layout');
    }
  }, [errorToReport]);

  const onPhoneClick = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, { page: SEGMENT.pages.error.name, location });
  };

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: SEGMENT.pages.error.name,
    });
    navigate(ROUTES.scheduleCall);
  };

  return (
    <div css={fullHeightPageCSS}>
      <Header
        phoneNumber={phoneNumberData?.contact_phone_number?.number}
        showContacts
        denseContainer
        onPhoneClick={() => onPhoneClick(SEGMENT.locations.header)}
      />
      <main>
        <section css={errorPageSectionCSS}>
          <Container dense>
            <div css={errorPageCenterContainerCSS}>
              <ErrorPageMessage
                title="An unexpected error occurred"
                description={
                  <>
                    <strong>You can still purchase your policy.</strong> Please contact a Matic agent to finalize the
                    policy.
                  </>
                }
              >
                <a
                  css={[buttonCSS(true), errorPageAnchorButtonCSS]}
                  href={`tel:${phoneNumberData?.contact_phone_number?.number}`}
                  onClick={() => onPhoneClick(SEGMENT.locations.page)}
                >
                  <PhoneIcon css={errorPageButtonIconCSS} />
                  Call an Agent Now
                </a>
                <Button
                  css={errorPageActionButtonCSS}
                  variant={'secondary'}
                  size={ButtonSize.Large}
                  onClick={onScheduleCall}
                >
                  Schedule Another Time
                </Button>
              </ErrorPageMessage>
            </div>
          </Container>
        </section>
      </main>
      <Footer
        phone={phoneNumberData?.contact_phone_number?.number}
        denseContainer
        onSocialLinkClick={() => onPhoneClick(SEGMENT.locations.footer)}
      />
    </div>
  );
};

export default ErrorLayout;
