import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../../constants/api';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import api from '../../api';
import type { HTTPResponse } from '../../../types/api.type';
import type { ContactPhoneNumberResponse } from '../../../types/contact-phone-number.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const getAPIPath = (gid?: string | null): string => {
  return gid ? `${API_PATH.public.contactPhoneNumber}?session_gid=${gid}` : API_PATH.public.contactPhoneNumber;
};

const usePublicContactPhoneNumber = (gid?: string | null): QueryObserverResult<ContactPhoneNumberResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.contactPhoneNumber, gid || 'default'],
    queryFn: async () =>
      await api.get<HTTPResponse<ContactPhoneNumberResponse>>(getAPIPath(gid)).then((res) => res.data),
    enabled: gid === null ? true : !!gid,
  });

export default usePublicContactPhoneNumber;
