import { Fragment, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../api/page';
import { useSession } from '../../../../api/session';
import AutoIcon from '../../../../assets/auto-congratulations.svg?react';
import BundleIcon from '../../../../assets/bundle-congratulations.svg?react';
import HomeIcon from '../../../../assets/home-congratulations.svg?react';
import useResponsive from '../../../../hooks/use-responsive';
import { useSegment } from '../../../../hooks/use-segment';
import SkeletonLoader from '../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { Flows } from '../../../../types/route-params.type';
import {
  isAutoPolicyDetailsComponent,
  isHomePolicyDetailsComponent,
  isPolicyDetailsSchema,
} from '../../../../utils/schema.util';
import isVisibleByAnswers from '../../../../utils/visibility-conditions.util';
import BlogPostCard from '../BlogPostCard/BlogPostCard';
import CongratulationsBundleOffer from '../CongratulationsBundleOffer/CongratulationsBundleOffer';
import PageSectionHeading from '../PageSectionHeading/PageSectionHeading';
import PoliciesSummaryDetails from '../PoliciesSummaryDetails/PoliciesSummaryDetails';
import {
  AUTO_POLICY_DATA_RENDER,
  BLOG_POSTS,
  BLOG_SECTION_SUBTITLE,
  BLOG_SECTION_TITLE,
  HOME_POLICY_DATA_RENDER,
  INSURANCE_SECTION_SUBTITLE,
  INSURANCE_SECTION_TITLE,
  INSURANCE_TYPES,
} from './CongratulationsPage.constants';
import {
  blogPostCSS,
  blogPostsCSS,
  containerCSS,
  headingContainerCSS,
  headingCSS,
  headingIconCSS,
  insuranceTypeItemCSS,
  insuranceTypeNameCSS,
  insuranceTypesContainerCSS,
  pageHeadingCSS,
} from './CongratulationsPage.style';
import { getComponentByType, getPolicies } from './CongratulationsPage.util';
import type { CongratulationsPageProps } from './CongratulationsPage.type';
import type { PolicySummaryDetailsSchema } from '../../../../types/form-component.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../types/section.type';
import type { FC, ReactElement } from 'react';

const CongratulationsPage: FC<CongratulationsPageProps> = ({ customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const currentPageKey = sessionQuery.data?.session.current_page_key;
  const pageQuery = usePage<CustomSectionSchema>(gid, currentPageKey);
  const { isMobile, isTablet } = useResponsive();
  const isPageLoading = !pageQuery.data;
  const sections = pageQuery.data?.page?.sections ?? [];

  const pageTrackedViewed = useRef(false);
  const { page } = useSegment();

  useEffect(() => {
    if (currentPageKey && !pageTrackedViewed.current) {
      page(currentPageKey);
      pageTrackedViewed.current = true;
    }
  }, [currentPageKey, page]);

  const [policyDetailsSection, restSections] = sections.reduce<[CustomSectionSchema[], CustomSectionSchema[]]>(
    (acc, curr) => {
      return curr.components.every((c) => isPolicyDetailsSchema(c))
        ? [[...acc[0], curr], acc[1]]
        : [acc[0], [...acc[1], curr]];
    },
    [[], []]
  );

  const isAuto = flow === Flows.Auto;
  const policyDataToRender = isAuto ? AUTO_POLICY_DATA_RENDER : HOME_POLICY_DATA_RENDER;
  const isBundlePolicy =
    policyDetailsSection.flatMap((s) => s.components.filter((c) => isHomePolicyDetailsComponent(c))).length > 0 &&
    policyDetailsSection.flatMap((s) => s.components.filter((c) => isAutoPolicyDetailsComponent(c))).length > 0;

  const renderBottomAutoSection = (): ReactElement => (
    <Fragment>
      <PageSectionHeading
        customCSS={pageHeadingCSS}
        title={INSURANCE_SECTION_TITLE}
        subtitle={INSURANCE_SECTION_SUBTITLE}
        showDivider
      />
      <div css={insuranceTypesContainerCSS}>
        {INSURANCE_TYPES.map(({ name, icon: Icon, url }) => (
          <a css={insuranceTypeItemCSS} href={url} target="_blank" key={name} rel="noreferrer">
            <Icon width={120} height={120} />
            <div css={insuranceTypeNameCSS}>{name}</div>
          </a>
        ))}
      </div>

      <PageSectionHeading
        customCSS={pageHeadingCSS}
        title={BLOG_SECTION_TITLE}
        subtitle={BLOG_SECTION_SUBTITLE}
        showDivider
      />
      <div css={blogPostsCSS}>
        {BLOG_POSTS.filter((_, index) => !(isTablet && index > 1)).map((post) => (
          <BlogPostCard
            customCSS={blogPostCSS}
            key={post.title}
            title={post.title}
            description={post.description}
            image={`${post.image}?w=400&h=200`}
            imageAlt={post.imageAlt}
            url={post.url}
          />
        ))}
      </div>
    </Fragment>
  );

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={headingContainerCSS}>
        {isBundlePolicy ? (
          <BundleIcon css={headingIconCSS} />
        ) : isAuto ? (
          <AutoIcon css={headingIconCSS} />
        ) : (
          <HomeIcon css={headingIconCSS} />
        )}
        {isPageLoading ? (
          <SkeletonLoader centered={!isMobile} absoluteHeight={isMobile ? 32 : 48} minWidth={60} maxWidth={80} />
        ) : (
          <div css={headingCSS}>{pageQuery.data?.page?.content_heading}</div>
        )}
      </div>

      <div>
        {isPageLoading ? (
          <SkeletonLoader centered absoluteHeight={162} width={isMobile ? 100 : 90} />
        ) : (
          <>
            {policyDetailsSection.map((s) => (
              <PoliciesSummaryDetails
                key={s.key}
                policies={getPolicies(s)}
                carrier={(s.components[0] as PolicySummaryDetailsSchema).carrier}
                renderSummaryList={policyDataToRender}
                isBundle={isBundlePolicy}
              />
            ))}

            {restSections.map((s) =>
              s.components
                ?.filter((c) => isVisibleByAnswers(pageQuery.data?.answers, c.visibility_conditions))
                .map((c, index, visibleComponents) => getComponentByType(c, index, visibleComponents))
            )}
          </>
        )}
      </div>
      {!isBundlePolicy ? isAuto ? renderBottomAutoSection() : <CongratulationsBundleOffer /> : null}
    </div>
  );
};

export default CongratulationsPage;
