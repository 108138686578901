import { questionHasAnswer } from '../../../../../questionsForm/utils/answers/answers.util';
import {
  getQuestionCollectionTypeComponents,
  isQuestionCollectionComponent,
} from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import type { Answers } from '../../../../../types/answer.type';
import type { FormComponentSchema, QuestionCollectionSchema } from '../../../../../types/form-component.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';

export const prepareQuestionCollectionsToSubmit = (
  answers: Answers,
  components: QuestionCollectionSchema[]
): Answers => {
  return components.reduce<Answers>((acc, curr) => {
    const visibleQuestions = curr.questions.filter((q) => isVisibleByAnswers(answers, q.visibility_conditions));
    const nonVisibleQuestions = curr.questions.filter((q) => !isVisibleByAnswers(answers, q.visibility_conditions));
    return {
      ...acc,
      ...nonVisibleQuestions.reduce<Answers>((acc, q) => ({ ...acc, [q.key]: null }), {}),
      ...visibleQuestions.reduce(
        (acc, q) => (questionHasAnswer(answers[q.key]) ? { ...acc, [q.key]: answers[q.key] } : acc),
        {}
      ),
    };
  }, {});
};

export const getVisibleQuestionsQuestionCollection = (
  sections: CustomSectionSchema[],
  answers: Answers
): QuestionCollectionSchema[] => {
  const questionCollections = getQuestionCollectionTypeComponents(sections);
  return questionCollections.reduce<QuestionCollectionSchema[]>(
    (acc: QuestionCollectionSchema[], curr: FormComponentSchema) => {
      if (isQuestionCollectionComponent(curr)) {
        const visibleQuestions = curr.questions.filter((question) => {
          return isVisibleByAnswers(answers, question.visibility_conditions);
        });

        return [...acc, { ...curr, questions: visibleQuestions }];
      }

      return acc;
    },
    []
  );
};
