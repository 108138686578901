import { containerCSS, countCSS, descriptionCSS, imgWrapperCSS } from './StepItem.style';
import type { StepItemProps } from './StepItem.type';
import type { FC } from 'react';

const StepItem: FC<StepItemProps> = ({ customCSS, img, count, description }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={imgWrapperCSS}>
        <img src={img.src} alt={img.alt} />
      </div>

      <div css={descriptionCSS}>
        <div css={countCSS(count === 1)}>{count}</div>
        {description}
      </div>
    </div>
  );
};

export default StepItem;
