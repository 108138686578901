import { css } from '@emotion/react';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  margin-bottom: 80px;
`;

export const dividerCSS = css`
  width: 100%;
  border-top: 1px solid ${theme.colors.gray10};
  margin: 32px 0;
`;

export const stepTitleCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const stepIconCSS = css`
  margin-right: 4px;
  width: 24px;
`;
