import SCHEMA_KEYS from '../../../../constants/schema-hardcoded-keys';
import { PageKeyHome } from '../../../../types/page.type';
import { PaymentMethod } from '../../../../types/public-flow-config.type';
import type { HomeQuote, QuoteRequest } from '../../../../types/quote.type';

export const MORTGAGE_STEP_HINT =
  'We will work with your lender ' +
  'to <b>include your new insurance premium into your escrow</b> as part of your mortgage payments.';
// @TODO refactor after Branch release to one question key
export const PAYMENT_METHOD_QUESTION_KEY = 'billing/payment_method';
export const CHECKOUT_SUMMARY_EXPANDER_ID = 'checkout-expander';
export const ESCROW_STEP_TITLE = 'Mortgage Info & Contact Info';
export const DIRECT_BILL_STEP_TITLE = 'Payment Information';
export const PAYMENT_METHOD_STEP_TITLE = 'Payment Method';

export const PAYMENT_METHOD_CREDIT_CARD_OPTION = {
  label: 'Credit Card',
  value: PaymentMethod.direct_bill,
};
export const PAYMENT_METHOD_ESCROW_OPTION = {
  label: 'Escrow Account',
  value: PaymentMethod.escrow,
};
export const PAYMENT_METHOD_QUESTION = {
  key: 'payment_method',
  placeholder: '- choose option -',
  options: [PAYMENT_METHOD_CREDIT_CARD_OPTION, PAYMENT_METHOD_ESCROW_OPTION],
};

export const HOME_PAYMENT_METHOD_QUESTION = {
  key: SCHEMA_KEYS.billingHomePaymentMethod,
  placeholder: '- choose option -',
  options: [PAYMENT_METHOD_CREDIT_CARD_OPTION, PAYMENT_METHOD_ESCROW_OPTION],
};

export const AUTO_PAYMENT_METHOD_QUESTION = {
  key: SCHEMA_KEYS.billingAutoPaymentMethod,
  placeholder: '- choose option -',
  options: [PAYMENT_METHOD_CREDIT_CARD_OPTION],
};

export const generateBillingDayVariants = (length: number): Array<{ label: string; value: number }> =>
  Array.from({ length }, (_, i) => {
    const value = i + 1;
    return { label: value.toString(), value };
  });

export const getButtonLabel = (isExpanded: boolean): string => {
  return isExpanded ? 'Hide Details' : 'Show Details';
};

export const getAdditionalDiscount = (quoteRequests?: Array<QuoteRequest<HomeQuote>>): null | number => {
  if (!quoteRequests) {
    return null;
  }

  if (quoteRequests?.length < 2) {
    return null;
  }

  const savings =
    Number(quoteRequests[1].groups[0].quotes[0].premium) - Number(quoteRequests[0].groups[0].quotes[0].premium);

  return savings > 0 ? Math.round(savings) : null;
};

export const getAdditionalDiscountForCheckout = (quoteRequests?: Array<QuoteRequest<HomeQuote>>): null | number => {
  if (!quoteRequests) {
    return null;
  }

  if (quoteRequests?.length < 2) {
    return null;
  }
  const last = quoteRequests[quoteRequests.length - 1];
  const finalQuoteQuotesRequests = quoteRequests.filter(
    (quoteRequest) => quoteRequest.page_key === PageKeyHome.FinalQuote
  );
  const lastBeforeDiscounts = finalQuoteQuotesRequests[finalQuoteQuotesRequests.length - 2];

  if (!last || !lastBeforeDiscounts) {
    return null;
  }

  const savings = Number(lastBeforeDiscounts.groups[0].quotes[0].premium) - Number(last.groups[0].quotes[0].premium);

  return savings > 0 ? Math.round(savings) : null;
};
