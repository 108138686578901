import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import {
  text12CSS,
  text14CSS,
  text20CSS,
  text24CSS,
  text32CSS,
  text40CSS,
  text48CSS,
} from '../../../../styles/typography.style';

export const customModalCSS = css`
  padding: 0 16px;
`;

export const headingCSS = css`
  ${text24CSS};
  margin: 0;
  text-align: center;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq[1]} {
    flex-direction: row;
    font-size: 56px;
    line-height: 60px;
    font-weight: 900;
    margin-bottom: 20px;
  }
`;

export const descriptionCSS = css`
  text-align: center;
  ${text14CSS};
  margin-bottom: 20px;

  ${mq[1]} {
    ${text20CSS};
    margin-bottom: 32px;

    span {
      font-weight: 700;
    }
  }
`;

export const customCardCSS = css`
  border-color: ${theme.colors.gray10};
  margin-bottom: 20px;

  ${mq[1]} {
    margin-bottom: 40px;
  }
`;

export const innerCardContentCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 33px;

  [data-testid='divider'] {
    margin: 0 16px;
  }

  ${mq[1]} {
    [data-testid='divider'] {
      margin: 0 27px 0 34px;
    }
    padding: 20px 33px;
  }
`;

export const cardHeadingCSS = css`
  background-color: ${theme.colors.azure90};
  padding: 8px;
  text-align: center;
  font-weight: 700;

  ${mq[1]} {
    ${text20CSS};
  }
`;

export const priceCSS = css`
  ${text40CSS};
  font-weight: 700;

  span {
    ${text24CSS};
  }

  ${mq[1]} {
    font-weight: 900;
    ${text48CSS};

    span {
      ${text32CSS};
      font-weight: 700;
    }
  }
`;

export const priceDescriptionCSS = css`
  ${text12CSS};

  ${mq[1]} {
    ${text14CSS};
  }
`;

export const buttonCustomCSS = css`
  max-width: 520px;
  margin: 0 auto;
`;
