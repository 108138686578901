const theme = {
  typography: {
    fontFamily: 'freight-sans-pro, sans-serif',
    fontSize: '16px',
    lineHeight: 1.25,
    fontSizeLarge: '20px',
    lineHeightLarge: 1.4,
  },
  colors: {
    azure50: '#156EEA',
    azure62: '#408DF7',
    azure68: '#66A4F7',
    azure90: '#d9e9ff',
    azure95: '#E5F1FF',
    black: '#000000',
    cherise61: '#EA4D72',
    errorRed: '#C80000',
    gray5: '#F2F2F2',
    gray8: '#F4F3F3',
    gray10: '#E6E6E6',
    gray30: '#B3B3B3',
    gray60: '#666666',
    gray80: '#333333',
    peachPink: '#ffa7a7',
    positiveGreen94: '#E8F8E8',
    positiveGreen27: '#2F8802',
    violet: '#5920CE',
    warningYellow94: '#FFF1E0',
    white: '#FFFFFF',
  },
  navigationWidth: 300,
  navigationWidthTablet: 220,
  pageMarginHorizontal: {
    mobile: 16,
    desktop: 32,
  },
  header: {
    headerHeightDesktop: 74,
    headerHeightMobile: 48,
    testModePadding: 34,
    navigationHeightTablet: 53,
    navigationHeightMobile: 49,
  },
  sizes: {
    containerMaxWidth: 1280,
    containerQuestionsMaxWidth: 780,
    paddingBodyHorizontalMobile: 24,
    paddingBodyHorizontalMobileDense: 16,
    paddingBodyHorizontalTablet: 32,
    inputPaddingHorizontal: 20,
    inputPaddingHorizontalSmall: 10,
    controlHeight: 60,
    controlHeightSmall: 44,
    confettiHeight: [66, 88, 98],
    borderWidth: 4,
    borderRadius: 4,
    formHolderBottomSpaceMobile: 34,
    formHolderBottomSpaceDesktop: 88,
    indicatorDesktop: 16,
    indicatorMobile: 8,
    inputHorizontalSpacing: 16,
    questionNavDotPadding: [18, 38],
  },
  boxShadow: {
    container: '2px 4px 0px 0px #0000001F',
    containerHovered: '4px 8px 0px 0px #0000001F',
  },
};

export default theme;
