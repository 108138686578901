import { css, type SerializedStyles } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text20CSS } from '../../../styles/typography.style';
import { ButtonDirection } from './Modal.type';

const fullscreenContainerCSS = css`
  left: 0;
  top: 0;
  transform: none;
  max-height: 100%;
  height: 100%;
  width: 100%;

  ${mq[0]} {
    max-width: 100%;
    width: 100%;
  }

  ${mq[1]} {
    max-width: 100%;
  }
`;

export const overlayCSS = {
  backgroundColor: 'rgba(51, 51, 51, 0.5)',
};

export const containerCSS = (isFullScreen = false, isExtendedWidth = false): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray30};
  box-shadow: 2px 4px 0 0 rgba(0, 0, 0, 0.12);
  padding: 24px;
  max-height: ${isExtendedWidth ? '100' : '85'}%;
  max-width: ${isFullScreen ? '100%' : '400px'};
  width: calc(100% - 36px);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;

  ${mq[0]} {
    max-width: ${isExtendedWidth ? '900px' : '700px'};
    width: calc(100% - 48px);
  }

  ${mq[1]} {
    max-width: 1000px;
  }

  ${isFullScreen && fullscreenContainerCSS};
`;

export const titleCSS = css`
  position: relative;
  display: flex;
  align-items: center;
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 24px;
  padding-right: 24px;
`;

export const contentNotActionableCSS = css`
  overflow-y: auto;
`;

export const contentCSS = (isFullScreen: boolean): SerializedStyles => css`
  padding: 0 4px;
  /* Extra space for input focus line in scrollable container */
  margin-left: -4px;
  margin-right: -4px;

  ${isFullScreen &&
  css`
    overflow-y: auto;
    height: 100%;
    margin-right: 0;
  `}
`;

const buttonsWrapperWithDividerCSS = css`
  position: relative;
  margin-top: 48px;

  &:before {
    position: absolute;
    left: -24px;
    right: -24px;
    top: -24px;
    content: '';
    display: block;
    height: 1px;
    background-color: ${theme.colors.gray30};
    margin-bottom: 24px;
  }
`;

export const buttonsWrapperCSS = (withDivider: boolean, buttonDirection: ButtonDirection): SerializedStyles => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
  ${withDivider && buttonsWrapperWithDividerCSS};
`;

export const closeIconCSS = css`
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const buttonCSS = (buttonDirection: ButtonDirection): SerializedStyles => css`
  padding: 12px 20px;
  width: ${buttonDirection === ButtonDirection.Row ? 'auto' : '100%'};
  min-width: 92px;
  ${mq[0]} {
    width: auto;
  }
`;
