import discountDiamond from '../../../../../assets/preview-page/diamond.svg';
import doc from '../../../../../assets/preview-page/doc.svg';
import myHome from '../../../../../assets/preview-page/home.svg';
import profile from '../../../../../assets/preview-page/profile.svg';

export const INTRO_STEPS_CONTENT = [
  {
    img: {
      src: discountDiamond,
      alt: 'Diamond',
    },
    description: 'Discounts',
  },
  {
    img: {
      src: myHome,
      alt: 'Home',
    },
    description: 'My Home',
  },
  {
    img: {
      src: profile,
      alt: 'Profile',
    },
    description: 'My Info',
  },
  {
    img: {
      src: doc,
      alt: 'Document',
    },
    description: 'Coverage Overview',
  },
];
