export interface CheckoutBundleFormProps {
  currentStep: CheckoutBundleStep;
  maxStep: number;
  homePaymentCreditCard: string;
  autoPaymentCreditCard: string;
  updateCurrentStep: (step: CheckoutBundleStep) => void;
  updateMaxStep: (number: number) => void;
  setAutoPaymentCreditCard: (cardNumber: string) => void;
  setHomePaymentCreditCard: (cardNumber: string) => void;
}

export enum CheckoutBundleStepsKeys {
  HomeBundle = 'homeBundle',
  AutoBundle = 'autoBundle',
  BindBundle = 'bindBundle',
}
export interface CheckoutBundleStep {
  key: CheckoutBundleStepsKeys;
  stepNumber: number;
}
