import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

interface CreateQuoteRequestOptions {
  pageKey?: string;
}

const useCreateQuotesRequest = (
  gid: string
): UseBaseMutationResult<Record<never, never>, HTTPResponse, CreateQuoteRequestOptions> => {
  return useMutation({
    mutationFn: async (options: CreateQuoteRequestOptions) =>
      await api
        .post<HTTPResponse<Record<never, never>>>(getApiUrl(API_PATH.quoteRequests, gid), {
          body: {
            data: { page_key: options.pageKey },
          },
        })
        .then((res) => res.data),
  });
};

export default useCreateQuotesRequest;
