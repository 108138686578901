import { useState } from 'react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import { noopAsync } from '../../../../../shared/utils/noop';
import format from '../../../../../utils/format.util';
import { buttonCSS, columnsWrapperCSS, itemCSS, previewCSS } from './CheckoutCreditCardPreview.style';
import type { CheckoutCreditCardPreviewProps } from './CheckoutCreditCardPreview.type';
import type { FC } from 'react';

const CheckoutCreditCardPreview: FC<CheckoutCreditCardPreviewProps> = ({
  cardNumber,
  personAddress,
  personEmail,
  personNumber,
  paymentMethod,
  paymentType,
  billingDayOfMonth,
  showButton,
  actionLabel,
  onButtonClick = noopAsync,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onActionClick = async (): Promise<void> => {
    setIsLoading(true);
    await onButtonClick();
    setIsLoading(false);
  };

  return (
    <div css={previewCSS}>
      <div css={columnsWrapperCSS}>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          {paymentMethod ? (
            <>
              <strong>Payment Method:</strong> {paymentMethod}, **** **** **** {cardNumber}
            </>
          ) : (
            <>
              <strong>Card Number:</strong> XXXX XXXX XXXX {cardNumber}
            </>
          )}
        </div>
        {paymentType && (
          <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <strong>Payment Plan:</strong> {paymentType}
          </div>
        )}
        {billingDayOfMonth && (
          <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <strong>Billing Day:</strong> {billingDayOfMonth}
          </div>
        )}
        {personEmail && (
          <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <strong>Email:</strong> {personEmail}
          </div>
        )}
        {personNumber && (
          <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <strong>Phone Number:</strong> {format.phone(personNumber)}
          </div>
        )}
        {personAddress && (
          <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <strong>Mailing address:</strong> {format.address(personAddress)}
          </div>
        )}
      </div>
      {showButton && (
        <Button customCSS={buttonCSS} fullWidth size={ButtonSize.Large} isLoading={isLoading} onClick={onActionClick}>
          {actionLabel}
        </Button>
      )}
    </div>
  );
};

export default CheckoutCreditCardPreview;
