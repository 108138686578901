import { create } from 'zustand';

interface ReminderState {
  isReminderPostponed: boolean;
  postponeReminder: () => void;
}

const useReminderStore = create<ReminderState>((set) => ({
  isReminderPostponed: false,
  postponeReminder: () => set((state) => ({ isReminderPostponed: !state.isReminderPostponed })),
}));

export default useReminderStore;
