export const QUOTE_CUSTOMIZATION_PREFIX = 'quote_customization';
const SCHEMA_KEYS = {
  billingPaymentType: 'billing/payment_type',
  billingPaymentPlan: 'billing/payment_plan',
  billingHomePaymentType: 'billing/home_payment_type',
  billingAutoPaymentType: 'billing/auto_payment_type',
  billingHomeDayOfMoth: 'billing/home_billing_day_of_month',
  billingAutoDayOfMoth: 'billing/auto_billing_day_of_month',
  billingHomePaymentMethod: 'billing/home_payment_method',
  billingAutoPaymentMethod: 'billing/auto_payment_method',
  personMaritalStatus: 'person_marital_status',
  personWantsToSkipAutoCollection: 'auto_form/person_wants_to_skip_auto_collection',
  driversSectionKey: 'drivers_information',
  vehiclesSectionKey: 'vehicles_information',
  bundleContainerKey: 'core_bundle',
  autoPolicyEffectiveDay: 'quote_customization/auto_policy_effective_date',
  homePolicyEffectiveDay: 'quote_customization/home_policy_effective_date',
  collision: 'collision',
  comprehensive: 'comprehensive',
  autoPolicyPrefix: 'quote_customization/auto_policy_',
  autoPolicyComprehensive: 'auto_policy_comprehensive',
  autoPolicyCollision: 'auto_policy_collision',
};

export const RELATIONSHIP_KIND_SPOUSE = 'spouse';
export const RELATIONSHIP_KIND_DOMESTIC_PARTNER = 'domestic_partner';
export const RELATIONSHIP_KIND_CHILD = 'child';
export const RELATIONSHIP_KIND_PARENT = 'parent';
export const RELATIONSHIP_KIND_RELATIVE = 'relative';
export const RELATIONSHIP_KIND_OTHER = 'other';
export const RELATIONSHIP_VARIANTS = [
  {
    label: 'Spouse',
    value: RELATIONSHIP_KIND_SPOUSE,
  },
  {
    label: 'Domestic Partner',
    value: RELATIONSHIP_KIND_DOMESTIC_PARTNER,
  },
  {
    label: 'Child',
    value: RELATIONSHIP_KIND_CHILD,
  },
  {
    label: 'Parent',
    value: RELATIONSHIP_KIND_PARENT,
  },
  {
    label: 'Relative',
    value: RELATIONSHIP_KIND_RELATIVE,
  },
  {
    label: 'Other',
    value: RELATIONSHIP_KIND_OTHER,
  },
];

export const GENDER_VARIANTS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-binary',
    value: 'nonbinary',
  },
];

export const MARITAL_STATUS_VARIANTS = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Divorced',
    value: 'divorced',
  },
];

export const PAGES = {
  driverDetails: 'drivers_details',
};

export const OTHER_OPTION_KEY = 'other';
export const OTHER_STRUCTURES_NONE_OPTION_KEY = 'none';

export const PAYMENT_TYPE_MONTHLY_OPTION_KEY = 'monthly';
export const PAYMENT_TYPE_ONE_TIME_OPTION_KEY = 'one_time';
export const PAYMENT_TYPE_ESCROW_OPTION = 'escrow';

// @TODO temporary solution for policy coverage for branch bundle
export const POLICY_BRANCH_AUTO_COVERAGES_KEYS: string[] = [
  'accidental_death',
  'extraordinary_medical',
  'combined_first_party_benefits',
  'funeral',
  'work_loss',
  'limited_property_damage_liability',
  'pip_medical_expense',
  'pip_work_loss_and_replacement_cost',
  'tort_option',
  'bodily_injury',
  'property_damage',
  'medical_payments',
  'uninsured_motorist_property_damage',
  'uninsured_motorist_bodily_injury',
  'underinsured_motorist_bodily_injury',
  'personal_injury_protection',
] as const;

export default SCHEMA_KEYS;
