import {
  PAYMENT_TYPE_MONTHLY_OPTION_KEY,
  PAYMENT_TYPE_ONE_TIME_OPTION_KEY,
} from '../../../../../constants/schema-hardcoded-keys';
import { PolicyType } from '../../../../../types/policy.type';
import { PaymentMethod } from '../../../../../types/public-flow-config.type';
import type { SummaryDataInfo, SummaryProperty } from './CheckoutBundleSummary.type';
import type { AnswerValue } from '../../../../../types/answer.type';

export const feesTooltipContent =
  'Each subscriber makes surplus contributions of up to 15% of total policy premiums. ' +
  'This amount goes toward the surplus needed to pay member claims, and is billed and ' +
  'collected alongside other premium.';

const getPaymentMethodLabel = (paymentMethod: PaymentMethod, paymentNumbers: AnswerValue): string => {
  const label = paymentMethod === PaymentMethod.escrow ? 'Escrow' : 'Credit card';
  const paymentSuffix = paymentNumbers ? (paymentNumbers as string).slice(-4) : '';

  return paymentNumbers ? `${label}, *${paymentSuffix}` : label;
};

const getDueTodayValue = (summaryInfo: SummaryDataInfo): number => {
  switch (true) {
    // Home Escrow + Auto One Time
    case summaryInfo.homePaymentMethod === PaymentMethod.escrow &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY:
      return summaryInfo.autoTotal;
    // Home Escrow + Auto Monthly
    case summaryInfo.homePaymentMethod === PaymentMethod.escrow &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY:
      return summaryInfo.autoDownPayment;
    //  Home One Time + Auto One Time
    case summaryInfo.homePaymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY:
      return Number(summaryInfo.homeTotal) + Number(summaryInfo.autoTotal);
    // Home Monthly + Auto Monthly
    case summaryInfo.homePaymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY:
      return Number(summaryInfo.homeDownPayment) + Number(summaryInfo.autoDownPayment);
    // Home Monthly + Auto One Time
    case summaryInfo.homePaymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY:
      return Number(summaryInfo.homeDownPayment) + Number(summaryInfo.autoTotal);
    // Home One Time + Auto Monthly
    case summaryInfo.homePaymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY &&
      summaryInfo.autoPaymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY:
      return Number(summaryInfo.homeTotal) + Number(summaryInfo.autoDownPayment);
    default:
      return 0;
  }
};

export const getSummaryPropertiesToRender = (
  policyType: PolicyType | undefined,
  paymentMethod: PaymentMethod | string | undefined,
  paymentType: AnswerValue | undefined,
  isCompleted: boolean,
  summaryInfo: SummaryDataInfo
): SummaryProperty[] => {
  const isHomePolicy = policyType === PolicyType.Home;

  const SummaryPropertiesSchema = {
    premium: {
      label: isHomePolicy ? 'Premium (12m)' : 'Premium (6m)',
      isMoney: true,
      value: isHomePolicy ? summaryInfo.homePremium : summaryInfo.autoPremium,
    },
    fees: {
      label: 'Fees & Surplus Contribution',
      isMoney: true,
      isTooltip: true,
      value: isHomePolicy ? summaryInfo.homeFees : summaryInfo.autoFees,
    },
    paymentMethod: {
      label: 'Payment method',
      value: getPaymentMethodLabel(
        isHomePolicy
          ? (summaryInfo.homePaymentMethod as PaymentMethod)
          : (summaryInfo.autoPaymentMethod as PaymentMethod),
        isHomePolicy
          ? summaryInfo.homePaymentMethod === PaymentMethod.direct_bill
            ? summaryInfo.homePaymentCreditCard
            : summaryInfo.loanNumber
          : summaryInfo.autoPaymentCreditCard
      ),
    },
    downPayment: {
      label: 'Due today',
      isMoney: true,
      value: isHomePolicy ? summaryInfo.homeDownPayment : summaryInfo.autoDownPayment,
    },
    monthlyPayment: {
      label: 'Monthly payment',
      isMoney: true,
      isMonthly: true,
      value: isHomePolicy ? summaryInfo.homeRemainingMonthly : summaryInfo.autoRemainingMonthly,
    },
    billingDay: {
      label: 'Billing day',
      value: isHomePolicy ? summaryInfo.homeBillingDay : summaryInfo.autoBillingDay,
    },
  };

  switch (true) {
    case !isCompleted:
      return [
        SummaryPropertiesSchema.premium,
        { label: 'Payment breakdown', value: 'Calculated at next step' },
        { label: 'Fees & Surplus Contribution', isTooltip: true, value: 'Calculated at next step' },
      ];
    case paymentMethod === PaymentMethod.escrow:
      return [
        SummaryPropertiesSchema.premium,
        SummaryPropertiesSchema.paymentMethod,
        { isEscrow: true },
        SummaryPropertiesSchema.fees,
        {
          label: 'Home total',
          isMoney: true,
          value: summaryInfo.homeTotal ? summaryInfo.homeTotal : summaryInfo.homeFees + summaryInfo.homePremium,
        },
      ];

    case paymentMethod === PaymentMethod.direct_bill && paymentType === PAYMENT_TYPE_ONE_TIME_OPTION_KEY:
      return [
        SummaryPropertiesSchema.premium,
        SummaryPropertiesSchema.paymentMethod,
        SummaryPropertiesSchema.fees,
        {
          label: isHomePolicy ? 'Home total' : 'Auto total',
          isMoney: true,
          value: isHomePolicy ? summaryInfo.homeTotal : summaryInfo.autoTotal,
        },
      ];

    case paymentMethod === PaymentMethod.direct_bill && paymentType === PAYMENT_TYPE_MONTHLY_OPTION_KEY:
      return [
        SummaryPropertiesSchema.premium,
        SummaryPropertiesSchema.paymentMethod,
        SummaryPropertiesSchema.billingDay,
        SummaryPropertiesSchema.monthlyPayment,
        SummaryPropertiesSchema.fees,
        SummaryPropertiesSchema.downPayment,
        {
          label: isHomePolicy ? 'Home total' : 'Auto total',
          isMoney: true,
          value: isHomePolicy ? summaryInfo.homeTotal : summaryInfo.autoTotal,
        },
      ];
    default:
      return [
        {
          label: 'Total for both policies',
          isMoney: true,
          value: Number(summaryInfo.homeTotal) + Number(summaryInfo.autoTotal),
        },

        {
          label: 'Due today',
          isMoney: true,
          value: getDueTodayValue(summaryInfo),
        },
      ];
  }
};
