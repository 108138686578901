import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useRecentLoan from '../../../../../api/loan/use-recent-loan';
import { usePage } from '../../../../../api/page';
import { useLastQuoteRequest, useQuotesRequestsList } from '../../../../../api/quote';
import useSession from '../../../../../api/session/use-session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import DollarIcon from '../../../../../assets/dollar.svg?react';
import { SEGMENT } from '../../../../../constants/analytics';
import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import { useSegment } from '../../../../../hooks/use-segment';
import SirvImage from '../../../../../shared/components/SirvImage/SirvImage';
import { PolicyType } from '../../../../../types/policy.type';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import { HOME_POLICY_TYPES } from '../../../../../types/quote.type';
import BundleQuoteCoveragesModal from '../BundleQuoteCoveragesModal/BundleQuoteCoveragesModal';
import { HOME_BUNDLE_STEP, AUTO_BUNDLE_STEP, BIND_BUNDLE_STEP } from '../CheckoutBundleForm/CheckoutBundleForm.util';
import { CHECKOUT_SUMMARY_EXPANDER_ID, getAdditionalDiscountForCheckout } from '../CheckoutHomeOpportunityPage.util';
import {
  policyDetailsButtonCSS,
  summaryHeroCSS,
  additionalDiscountCSS,
  logoCSS,
  rowsContainerCSS,
} from './CheckoutBundleSummary.style';

import CheckoutBundleSummaryComponent from './CheckoutPolicySummary/CheckoutPolicySummary';
import type { CheckoutBundleSummaryProps, SummaryDataInfo } from './CheckoutBundleSummary.type';
import type { Address } from '../../../../../types/address.type';
import type { HomeQuote, AutoQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutBundleSummary: FC<CheckoutBundleSummaryProps> = ({
  currentStep,
  homePaymentCreditCard,
  autoPaymentCreditCard,
}) => {
  const [isPolicyDetailsModalOpen, setIsPolicyDetailsModalOpen] = useState(false);

  const { track } = useSegment();
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const loanQuery = useRecentLoan(gid);
  const { data: quoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);
  const { data: pageData } = usePage(gid, currentPageKey);
  const address = pageData?.answers?.property_address as Address;
  const { data: quotesRequestsListData } = useQuotesRequestsList<HomeQuote>(
    gid,
    sessionFlowConfig?.flow_config?.config_type === ConfigType.Dynamic
  );
  const quotesGroup = quoteRequestData?.quotes_request?.groups[0].quotes;
  const additionalDiscount = getAdditionalDiscountForCheckout(quotesRequestsListData?.quotes_requests);

  const homeQuote = quotesGroup?.find((q) => HOME_POLICY_TYPES.includes(q.policy_type)) as HomeQuote;
  const autoQuote = quotesGroup?.find((q) => q.policy_type === PolicyType.Auto) as unknown as AutoQuote;
  const autoQuotePaymentOptions = autoQuote?.payment_options?.length > 0;
  const homeQuotePaymentOptions = homeQuote?.payment_options?.length > 0;
  const homeQuoteFees = homeQuotePaymentOptions
    ? homeQuote?.payment_options[0].full_term_premium - homeQuote?.payment_options[0].base_premium
    : 0;
  const autoQuoteFees = autoQuotePaymentOptions
    ? autoQuote?.payment_options[0].full_term_premium - autoQuote?.payment_options[0].base_premium
    : 0;

  const SummaryDataInfo: SummaryDataInfo = {
    homePremium:
      homeQuotePaymentOptions && homeQuote?.payment_options[0].base_premium
        ? homeQuote?.payment_options[0].base_premium
        : Number(homeQuote?.premium),
    autoPremium:
      autoQuotePaymentOptions && autoQuote?.payment_options[0].base_premium
        ? autoQuote?.payment_options[0].base_premium
        : Number(autoQuote?.premium) / 2,
    homeFees: homeQuoteFees,
    autoFees: autoQuoteFees,
    homeTotal: homeQuote?.payment_options[0].full_term_premium ?? 0,
    autoTotal: autoQuote?.payment_options[0].full_term_premium ?? 0,
    homePaymentCreditCard,
    autoPaymentCreditCard,
    homePaymentMethod: (pageData?.answers?.[SCHEMA_KEYS.billingHomePaymentMethod] as string) ?? '',
    autoPaymentMethod: (pageData?.answers?.[SCHEMA_KEYS.billingAutoPaymentMethod] as string) ?? '',
    homePaymentType: (pageData?.answers?.[SCHEMA_KEYS.billingHomePaymentType] as string) ?? '',
    autoPaymentType: (pageData?.answers?.[SCHEMA_KEYS.billingAutoPaymentType] as string) ?? '',
    homeDownPayment: homeQuotePaymentOptions ? homeQuote?.payment_options[0].downpayment : 0,
    autoDownPayment: autoQuotePaymentOptions ? autoQuote?.payment_options[0].downpayment : 0,
    homeBillingDay: (pageData?.answers?.[SCHEMA_KEYS.billingHomeDayOfMoth] as string) || '',
    autoBillingDay: (pageData?.answers?.[SCHEMA_KEYS.billingAutoDayOfMoth] as string) || '',
    homeRemainingMonthly: homeQuotePaymentOptions ? homeQuote?.payment_options[0].subsequent_payment : 0,
    autoRemainingMonthly: autoQuotePaymentOptions ? autoQuote?.payment_options[0].subsequent_payment : 0,
    loanNumber: loanQuery?.data?.loan?.loan_number ?? '',
  };

  useEffect(() => {
    if (additionalDiscount) {
      track(SEGMENT.events.finalPriceDiscountViewed, {
        discount: additionalDiscount,
      });
    }
  }, [additionalDiscount, track]);

  const onPolicyDetailsClick = (): void => {
    setIsPolicyDetailsModalOpen(true);
  };

  return (
    <div>
      <div>
        <div css={summaryHeroCSS}>
          <SirvImage
            customCSS={logoCSS}
            url={homeQuote?.carrier_logo_url}
            width={110}
            alt={`${homeQuote?.carrier_name} logo`}
          />

          <button css={policyDetailsButtonCSS} onClick={onPolicyDetailsClick}>
            Policy details
          </button>
        </div>

        {additionalDiscount && (
          <div css={additionalDiscountCSS}>
            <DollarIcon height={20} />
            <div>
              <div>Additional discount applied!</div>
              <strong>${additionalDiscount} more in savings</strong>
            </div>
          </div>
        )}

        <div id={CHECKOUT_SUMMARY_EXPANDER_ID} css={rowsContainerCSS}>
          <CheckoutBundleSummaryComponent
            paymentMethod={SummaryDataInfo.homePaymentMethod}
            policySummary={SummaryDataInfo}
            paymentType={SummaryDataInfo.homePaymentType}
            effectiveDate={homeQuote?.policy_effective_date}
            isStepActive={currentStep.stepNumber === HOME_BUNDLE_STEP.stepNumber}
            isStepCompleted={currentStep.stepNumber > HOME_BUNDLE_STEP.stepNumber}
            policyType={PolicyType.Home}
          />

          <CheckoutBundleSummaryComponent
            paymentMethod={SummaryDataInfo.autoPaymentMethod}
            policySummary={SummaryDataInfo}
            paymentType={SummaryDataInfo.autoPaymentType}
            effectiveDate={autoQuote?.policy_effective_date}
            isStepActive={currentStep.stepNumber === AUTO_BUNDLE_STEP.stepNumber}
            isStepCompleted={currentStep.stepNumber > AUTO_BUNDLE_STEP.stepNumber}
            policyType={PolicyType.Auto}
          />

          <CheckoutBundleSummaryComponent
            policySummary={SummaryDataInfo}
            isFinalStep={true}
            isStepActive={currentStep.stepNumber === BIND_BUNDLE_STEP.stepNumber}
            isStepCompleted={currentStep.stepNumber === BIND_BUNDLE_STEP.stepNumber}
          />
        </div>
      </div>

      {isPolicyDetailsModalOpen && (
        <BundleQuoteCoveragesModal
          autoQuote={autoQuote}
          homeQuote={homeQuote}
          onModalClose={() => setIsPolicyDetailsModalOpen(false)}
          isOpen={isPolicyDetailsModalOpen}
          address={address}
        />
      )}
    </div>
  );
};

export default CheckoutBundleSummary;
