import { useParams } from 'react-router-dom';
import { usePublicContactPhoneNumber } from '../../api/public/public-contact-phone-number';
import { useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';

import { useSegment } from '../../hooks/use-segment';
import Footer from '../../shared/components/Footer/Footer';
import type { OLBFooterProps } from './OLBFooter.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const OLBFooter: FC<OLBFooterProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const { data: phoneData } = usePublicContactPhoneNumber(gid);

  const { track } = useSegment();

  const onPhoneClicked = (): void =>
    track(SEGMENT.events.phoneConversationInitiated, {
      page: sessionQuery.data?.session.current_page_key,
      location: SEGMENT.locations.footer,
    });

  return (
    <Footer
      customCSS={customCSS}
      phone={phoneData?.contact_phone_number?.number}
      denseContainer
      onSocialLinkClick={onPhoneClicked}
    />
  );
};

export default OLBFooter;
