import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSession } from '../../api/session';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useSegment } from '../../hooks/use-segment';
import { Flows } from '../../types/route-params.type';
import { isCurrentKnockOutSection } from '../../utils/navigation.util';
import CheckoutAutoOpportunityPage from './components/CheckoutAutoOpportunityPage/CheckoutAutoOpportunityPage';
import CheckoutHomeOpportunityPage from './components/CheckoutHomeOpportunityPage/CheckoutHomeOpportunityPage';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const CheckoutLayout: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const isKnockOutSection = isCurrentKnockOutSection(sessionQuery.data?.session.current_section_key ?? '');
  const currentPageKey = sessionQuery.data?.session.current_page_key;

  const pageViewedTracked = useRef(false);
  const { page } = useSegment();

  const isAuto = flow === Flows.Auto;
  const showFooter = (!isAuto && !isKnockOutSection) || isAuto;

  useEffect(() => {
    if (currentPageKey && !pageViewedTracked.current) {
      page(currentPageKey);
      pageViewedTracked.current = true;
    }
  }, [currentPageKey, page]);

  return (
    <PageContainer
      showFooter={showFooter}
      allowFullStoryTracking
      allowTabTitleUpdate
      {...(isAuto && { layoutVariant: 'wide' })}
    >
      {isAuto ? <CheckoutAutoOpportunityPage /> : <CheckoutHomeOpportunityPage />}
    </PageContainer>
  );
};

export default CheckoutLayout;
