import type { Loan } from '../../../../../types/loan.type';
import type { Person } from '../../../../../types/person.type';
import type { CheckoutMortgageContactFormState } from '../CheckoutMortgageStep/CheckoutMortgageStep.type';
import type { SerializedStyles } from '@emotion/react';

export interface CheckoutMortgageFormProps {
  customCSS?: SerializedStyles;
  servicersOptions?: Array<{ value: string | number; label: string }>;
  loan?: Loan;
  primaryInsured?: Person;
  actionLabel?: string;
  isBindDisabled: boolean;
  isSubmitting?: boolean;
  onValidSubmit?: (data: CheckoutMortgageContactFormState) => Promise<void>;
}

export enum ServicerQuestionKeys {
  ServicerGid = 'servicer_gid',
  LoanNumber = 'loan_number',
}
