import { useQueryClient } from '@tanstack/react-query';
import { useState, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMutateAnswers, usePage } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import { PaymentMethod } from '../../../../../types/public-flow-config.type';
import CheckoutBundleCreditCardStep from '../CheckoutBundleCreditCardStep/CheckoutBundleCreditCardStep';
import { AUTO_PAYMENT_METHOD_QUESTION, PAYMENT_METHOD_CREDIT_CARD_OPTION } from '../CheckoutHomeOpportunityPage.util';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { StepTitleButtonType } from '../CheckoutStepTitle/CheckoutStepTitle.type';
import PaymentMethodSelect from '../PaymentMethodSelect/PaymentMethodSelect';
import { AUTO_BILLING_DAY_QUESTION_SCHEMA, AUTO_PAYMENT_TYPE_QUESTION_SCHEMA } from './CheckoutBundleAutoStep.util';
import type { CheckoutBundleAutoStepProps } from './CheckoutBundleAutoStep.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CheckoutSecondStepData } from '../CheckoutPaymentStep/CheckoutPaymentStep.type';

const CheckoutBundleAutoStep: FC<CheckoutBundleAutoStepProps> = ({
  stepNumber,
  currentStepNumber,
  stepTitle,
  maxStep,
  creditCardNumber,
  onSubmit,
  onEdit,
  setAutoPaymentCreditCard,
}) => {
  const queryClient = useQueryClient();
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: pageData } = usePage(gid, sessionData?.session.current_page_key);
  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);

  const [isLoading, setIsLoading] = useState(false);

  const isFinished = maxStep > stepNumber;
  const isCurrent = currentStepNumber === stepNumber;

  const onFormSubmit = async (data?: CheckoutSecondStepData, cardNumber?: string): Promise<void> => {
    cardNumber && setAutoPaymentCreditCard(cardNumber);
    const { answers } = await mutateAnswers({
      ...data,
      [SCHEMA_KEYS.billingAutoPaymentMethod]: PAYMENT_METHOD_CREDIT_CARD_OPTION.value,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.page, gid, sessionData?.session.current_page_key], {
      ...pageData,
      answers,
    });

    await onSubmit();

    if (cardNumber) {
      setAutoPaymentCreditCard(cardNumber);
    }

    setIsLoading(false);
  };

  const onBeforeStepSubmit = (): void => {
    setIsLoading(true);
  };

  return (
    <div>
      <CheckoutStepTitle
        stepNumber={stepNumber}
        title={stepTitle}
        isDisabled={!isCurrent && !isFinished}
        hideStepNumber={true}
        isFinished={isFinished && !isCurrent}
        onButtonClick={onEdit}
        buttonType={isFinished && !isCurrent ? StepTitleButtonType.Edit : null}
      />

      {isCurrent && (
        <PaymentMethodSelect
          title="How would you like to pay for your home insurance?"
          questionKey={AUTO_PAYMENT_METHOD_QUESTION.key}
          placeholder={AUTO_PAYMENT_METHOD_QUESTION.placeholder}
          options={[PAYMENT_METHOD_CREDIT_CARD_OPTION]}
          onChange={() => null}
          infoBlockText="Other payment options, such as bank transfer, are available through our agency."
          value={PaymentMethod.direct_bill}
          isDisabled={isLoading}
        />
      )}

      {(isCurrent || isFinished) && (
        <CheckoutBundleCreditCardStep
          creditCardNumber={creditCardNumber}
          questions={[AUTO_PAYMENT_TYPE_QUESTION_SCHEMA, AUTO_BILLING_DAY_QUESTION_SCHEMA]}
          isCurrent={isCurrent}
          isEdit={isCurrent}
          onStepSubmit={onFormSubmit}
          contactInformation={false}
          isSubmitting={isLoading}
          onBeforeStepSubmit={onBeforeStepSubmit}
        />
      )}
    </div>
  );
};

export default CheckoutBundleAutoStep;
