import { CheckoutBundleStepsKeys } from './CheckoutBundleForm.type';
import type { CheckoutBundleStep } from './CheckoutBundleForm.type';

export const HOME_BUNDLE_STEP: CheckoutBundleStep = {
  key: CheckoutBundleStepsKeys.HomeBundle,
  stepNumber: 1,
} as const;

export const AUTO_BUNDLE_STEP: CheckoutBundleStep = {
  key: CheckoutBundleStepsKeys.AutoBundle,
  stepNumber: 2,
} as const;

export const BIND_BUNDLE_STEP: CheckoutBundleStep = {
  key: CheckoutBundleStepsKeys.BindBundle,
  stepNumber: 3,
} as const;
