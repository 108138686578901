const BASE = '/api';
const PUBLIC_BASE = '/api/public';
const SESSION_BASE = `${BASE}/sessions`;
const PUBLIC_SESSION_BASE = `${BASE}/public/sessions`;

const API_PATH = {
  base: BASE,
  sessionBase: SESSION_BASE,
  sessionInit: `${SESSION_BASE}/init`,
  session: `${SESSION_BASE}/:gid`,
  page: `${SESSION_BASE}/:gid/pages/:key`,
  answers: `${SESSION_BASE}/:gid/answers`,
  featureFlags: `${BASE}/feature_flags`,
  policy: `${SESSION_BASE}/:gid/bind_policy`,
  carrier: `${SESSION_BASE}/:gid/carrier`,
  quote: `${SESSION_BASE}/:gid/quote`,
  initialQuote: `${SESSION_BASE}/:gid/initial_quote`,
  quoteRequests: `${SESSION_BASE}/:gid/quotes_requests`,
  quoteLastRequests: `${SESSION_BASE}/:gid/quotes_requests/last`,
  quoteLastNoQuotes: `${SESSION_BASE}/:gid/quotes_requests/last_without_quotes`,
  fullStories: `${SESSION_BASE}/:gid/full_story_sessions`,
  coInsureds: `${SESSION_BASE}/:gid/co_insureds`,
  coInsured: `${SESSION_BASE}/:gid/co_insureds/:coInsuredGid`,
  loans: `${SESSION_BASE}/:gid/loans/`,
  loan: `${SESSION_BASE}/:gid/loans/:loanGid`,
  loansRecent: `${SESSION_BASE}/:gid/loans/recent`,
  servicers: `${BASE}/servicers`,
  primaryInsured: `${SESSION_BASE}/:gid/primary_insured`,
  authorize: `${SESSION_BASE}/:gid/primary_insured/authorize`,
  unlockSession: `${SESSION_BASE}/:gid/unlock`,
  customerChoices: `${BASE}/engagements/:gid/customer_choices`,
  allFunnelFlow: `${SESSION_BASE}/:gid/all_funnel_flows`,
  sessionFlowConfig: `${SESSION_BASE}/:gid/flow_config`,
  vehicles: `${SESSION_BASE}/:gid/vehicles`,
  vehicle: `${SESSION_BASE}/:gid/vehicles/:vehicleGid`,
  drivers: `${SESSION_BASE}/:gid/drivers`,
  driver: `${SESSION_BASE}/:gid/drivers/:driverGid`,
  vehiclesAndDriversData: `${SESSION_BASE}/:gid/primary_insured/vehicles_and_drivers_data`,
  effectivePolicy: `${SESSION_BASE}/:gid/effective_policy`,
  contactFlows: `${SESSION_BASE}/:gid/contact_flows`,
  acceptedDisclosures: `${SESSION_BASE}/:gid/accepted_disclosures`,
  engagement: `${BASE}/engagements/:engagementGid`,
  recaptchaVerify: `${SESSION_BASE}/:gid/recaptcha/verify`,
  recaptchaSolvePuzzle: `${SESSION_BASE}/:gid/recaptcha/solve_puzzle`,
  // Public API
  public: {
    config: `${PUBLIC_BASE}/config`,
    session: `${PUBLIC_SESSION_BASE}/:gid`,
    primaryInsured: `${PUBLIC_SESSION_BASE}/:gid/primary_insured`,
    flowConfig: `${PUBLIC_SESSION_BASE}/:gid/flow_config`,
    errors: `${PUBLIC_SESSION_BASE}/:gid/errors`,
    contactPhoneNumber: `${PUBLIC_BASE}/contact_phone_number`,
  },
};

export default API_PATH;
