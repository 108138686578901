import { useQueryClient } from '@tanstack/react-query';
import { useEffect, type FC, type ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { useMutatePolicyBind } from '../../../../../api/policy';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useCreateQuotesRequest } from '../../../../../api/quote';
import { waitForQuoteRequestCompleted } from '../../../../../api/quote/use-quote-requests-subscription';
import { useMutateSession, useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import HouseIconSmall from '../../../../../assets/home-simple.svg?react';
import VehicleIconSmall from '../../../../../assets/vehicle.svg?react';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import useResponsive from '../../../../../hooks/use-responsive';
import { useSegment } from '../../../../../hooks/use-segment';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import { getDisclosuresSchema } from '../../../../../utils/schema.util';
import CheckoutBundleAutoStep from '../CheckoutBundleAutoStep/CheckoutBundleAutoStep';
import CheckoutBundleBindStep from '../CheckoutBundleBindStep/CheckoutBundleBindStep';
import CheckoutBundleHomeStep from '../CheckoutBundleHomeStep/CheckoutBundleHomeStep';
import CheckoutBundleSummary from '../CheckoutBundleSummary/CheckoutBundleSummary';
import CheckoutHomeFormLoading from '../CheckoutHomeFormLoading/CheckoutHomeFormLoading';
import { containerCSS, dividerCSS, stepIconCSS, stepTitleCSS } from './CheckoutBundleForm.style';
import {
  CheckoutBundleStepsKeys,
  type CheckoutBundleFormProps,
  type CheckoutBundleStep,
} from './CheckoutBundleForm.type';
import { AUTO_BUNDLE_STEP, BIND_BUNDLE_STEP, HOME_BUNDLE_STEP } from './CheckoutBundleForm.util';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { SessionResponse } from '../../../../../types/session.type';

const CheckoutBundleForm: FC<CheckoutBundleFormProps> = ({
  maxStep,
  currentStep,
  homePaymentCreditCard,
  autoPaymentCreditCard,
  updateCurrentStep,
  updateMaxStep,
  setAutoPaymentCreditCard,
  setHomePaymentCreditCard,
}) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const queryClient = useQueryClient();

  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage(gid, currentPageKey);
  const { data: configData } = usePublicConfig();
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest(gid);
  const { mutateAsync: mutateBindPolicy } = useMutatePolicyBind(gid);
  const { mutateAsync: mutateSession } = useMutateSession(gid);

  const navigate = useNavigate();
  const { track } = useSegment();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    if (currentStep.stepNumber > maxStep) {
      updateMaxStep(currentStep.stepNumber);
    }
  }, [currentStep, maxStep, updateMaxStep]);

  const onStepSubmit = async (currentStepNumber: number): Promise<void> => {
    await createQuotesRequest({ pageKey: currentPageKey });

    if (!configData?.config.websocket_url) {
      throw new Error('Websocket URL is not defined');
    }
    await waitForQuoteRequestCompleted(gid, configData.config.websocket_url);
    await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

    const nextStep = maxStep > currentStepNumber ? maxStep : currentStepNumber + 1;

    track(SEGMENT.events.paymentMethodStepCompleted, {
      type: currentStep.key,
    });

    updateCurrentStep(
      [AUTO_BUNDLE_STEP, HOME_BUNDLE_STEP, BIND_BUNDLE_STEP].find(
        (step) => step.stepNumber === nextStep
      ) as CheckoutBundleStep
    );
  };

  const onFinishBindStep = async (): Promise<void> => {
    track(SEGMENT.events.paymentMethodStepCompleted, {
      type: currentStep.key,
    });

    track(SEGMENT.events.bindPolicyClicked);

    await mutateBindPolicy({});

    await navigateToCongratulations();
  };

  const navigateToCongratulations = async (): Promise<void> => {
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);
    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  if (!sessionFlowConfig?.flow_config?.payment_methods || !pageData) {
    return <CheckoutHomeFormLoading />;
  }

  const getStepTitle = (title: string): ReactNode => (
    <div css={stepTitleCSS}>
      {CheckoutBundleStepsKeys.HomeBundle.includes(title.toLowerCase()) ? (
        <HouseIconSmall css={stepIconCSS} />
      ) : (
        <VehicleIconSmall css={stepIconCSS} />
      )}
      {title}
    </div>
  );

  return (
    <div css={containerCSS}>
      <CheckoutBundleHomeStep
        stepNumber={HOME_BUNDLE_STEP.stepNumber}
        stepTitle={getStepTitle('Home')}
        currentStepNumber={currentStep.stepNumber}
        maxStep={maxStep}
        creditCardNumber={homePaymentCreditCard}
        setHomePaymentCreditCard={setHomePaymentCreditCard}
        onSubmit={async () => await onStepSubmit(HOME_BUNDLE_STEP.stepNumber)}
        onEdit={() => updateCurrentStep(HOME_BUNDLE_STEP)}
      />

      <div css={dividerCSS} />
      <CheckoutBundleAutoStep
        stepNumber={AUTO_BUNDLE_STEP.stepNumber}
        currentStepNumber={currentStep.stepNumber}
        stepTitle={getStepTitle('Auto')}
        maxStep={maxStep}
        creditCardNumber={autoPaymentCreditCard}
        setAutoPaymentCreditCard={setAutoPaymentCreditCard}
        onSubmit={async () => await onStepSubmit(AUTO_BUNDLE_STEP.stepNumber)}
        onEdit={() => updateCurrentStep(AUTO_BUNDLE_STEP)}
      />
      <div css={dividerCSS} />

      {!isDesktop && currentStep.key === CheckoutBundleStepsKeys.BindBundle && (
        <CheckoutBundleSummary
          currentStep={currentStep}
          homePaymentCreditCard={homePaymentCreditCard}
          autoPaymentCreditCard={autoPaymentCreditCard}
        />
      )}

      <CheckoutBundleBindStep
        stepNumber={BIND_BUNDLE_STEP.stepNumber}
        currentStepNumber={currentStep.stepNumber}
        onSubmit={onFinishBindStep}
        disclosures={getDisclosuresSchema(pageData.page.sections[0])}
      />
    </div>
  );
};

export default CheckoutBundleForm;
