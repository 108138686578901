import { useEffect, useState } from 'react';
import { useSession } from '../session';
import { useCreateQuotesRequest, useLastQuoteRequest, useQuoteRequestsSubscription } from './index';
import type { QuoteRequest, QuoteType } from '../../types/quote.type';

interface InitQuoteRequestSubscriptionProps {
  gid: string;
  webSocketUrl?: string;
  shouldRefetchManually?: boolean;
}

const useInitQuotesRequestSubscription = <T = QuoteType>({
  gid,
  webSocketUrl,
  shouldRefetchManually,
}: InitQuoteRequestSubscriptionProps): {
  quoteRequest: QuoteRequest<T> | null;
  isPending: boolean;
} => {
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;

  const [isPending, setIsPending] = useState<boolean>(true);

  const {
    data,
    isSuccess,
    refetch: refetchLastQuoteRequest,
    isPending: isLastQuoteRequestPending,
  } = useLastQuoteRequest<T>(gid);

  useEffect(() => {
    if (isFinished) {
      refetchLastQuoteRequest();
      setIsPending(false);
    }
  }, [isFinished, refetchLastQuoteRequest]);

  useEffect(() => {
    if (shouldRefetchManually) {
      refetchLastQuoteRequest();
    }
  }, [shouldRefetchManually, refetchLastQuoteRequest]);

  useEffect(() => {
    setIsPending(true);
    const call = async (): Promise<void> => {
      startSubscription();
      await createQuotesRequest({ pageKey: currentPageKey });
      setIsPending(false);
    };

    if (isSuccess && data && !data.quotes_request) {
      call();
    } else if (isSuccess && !data?.quotes_request?.finished_at) {
      startSubscription();
    } else {
      setIsPending(false);
    }
  }, [data, isSuccess, gid, createQuotesRequest, startSubscription, currentPageKey]);

  return {
    quoteRequest: data?.quotes_request ?? null,
    isPending: isPending || isLastQuoteRequestPending,
  };
};

export default useInitQuotesRequestSubscription;
