import Question from '../../../../../questionsForm/components/Question/Question';
import { FormElementTag } from '../../../../../types/form-element.type';
import { flexContainerCSS, flexInputCSS } from './CheckoutContactInfoQuestions.style';
import {
  PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA,
  PERSONAL_INFO_EMAIL_QUESTION_SCHEMA,
  PERSONAL_INFO_PHONE_QUESTION_SCHEMA,
} from './CheckoutContactInfoQuestions.util';
import type { CheckoutContactInfoQuestionsProps } from './CheckoutContactInfoQuestions.type';
import type { FC } from 'react';

const CheckoutContactInfoQuestions: FC<CheckoutContactInfoQuestionsProps> = ({ isDisabled = false }) => {
  return (
    <>
      <div css={flexContainerCSS}>
        <div css={flexInputCSS}>
          <Question
            {...PERSONAL_INFO_PHONE_QUESTION_SCHEMA}
            key={PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey}
            isDisabled={isDisabled}
            tags={[FormElementTag.TrackForbidden]}
          />
        </div>
        <div css={flexInputCSS}>
          <Question
            {...PERSONAL_INFO_EMAIL_QUESTION_SCHEMA}
            key={PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey}
            isDisabled={isDisabled}
            tags={[FormElementTag.TrackForbidden]}
          />
        </div>
      </div>
      <Question
        {...PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA}
        key={PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey}
        isDisabled={isDisabled}
        tags={[FormElementTag.TrackForbidden]}
      />
    </>
  );
};

export default CheckoutContactInfoQuestions;
