import * as Sentry from '@sentry/react';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import { noopAsync } from '../../../../../shared/utils/noop';
import StripeInputElement from '../StripeInputElement/StripeInputElement';
import { StripeInputType } from '../StripeInputElement/StripeInputElement.type';
import { buttonCSS, formCSS, inputCSS } from './StripeCardPaymentForm.style';
import { removeEmptyAnswers, STRIPE_CREDIT_CARD_ANSWER_KEY } from './StripeCardPaymentForm.util';
import type { StripeCardPaymentFormProps } from './StripeCardPaymentForm.type';
import type { Answers } from '../../../../../types/answer.type';
import type { FC } from 'react';

const StripeCardPaymentForm: FC<StripeCardPaymentFormProps> = ({
  customCSS,
  actionLabel = 'Bind Policy',
  children,
  defaultAnswers,
  isBindDisabled,
  additionalQuestions,
  isSubmitting = false,
  onAfterValidSubmit = noopAsync,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<Answers>({
    defaultValues: removeEmptyAnswers(defaultAnswers),
  });

  useEffect(() => {
    if (defaultAnswers && !isSubmitting) {
      methods.reset(removeEmptyAnswers(defaultAnswers));
    }
  }, [defaultAnswers, isSubmitting, methods]);

  const onSubmit = async (data: Answers): Promise<void> => {
    if (!stripe || !elements || isLoading) {
      return;
    }

    setIsLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardNumberToken = cardNumberElement ? await stripe.createToken(cardNumberElement) : null;

    if (!cardNumberToken?.token?.id) {
      setIsLoading(false);
      Sentry.captureException(new Error(`Stripe card number token is not valid: ${cardNumberToken?.error?.type}`));
      return;
    }

    await onAfterValidSubmit(
      { ...data, [STRIPE_CREDIT_CARD_ANSWER_KEY]: cardNumberToken?.token?.id },
      cardNumberToken.token.card?.last4
    );
    setIsLoading(false);
  };

  return (
    <div css={customCSS}>
      <FormProvider {...methods}>
        <form id="card-stripe-form" css={formCSS} onSubmit={methods.handleSubmit(onSubmit, scrollToError)}>
          {additionalQuestions}
          <StripeInputElement type={StripeInputType.CardNumber} customCSS={inputCSS} disabled={isSubmitting} />
          <StripeInputElement type={StripeInputType.CardExpiry} customCSS={inputCSS} disabled={isSubmitting} />
          <StripeInputElement type={StripeInputType.CardCvc} customCSS={inputCSS} disabled={isSubmitting} />
          {children}
        </form>
      </FormProvider>

      <Button
        type="submit"
        form="card-stripe-form"
        css={buttonCSS}
        loadingText="Submitting"
        fullWidth
        size={ButtonSize.Large}
        isLoading={isLoading}
        disabled={isBindDisabled}
      >
        {actionLabel}
      </Button>
    </div>
  );
};

export default StripeCardPaymentForm;
