import { useId, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSession } from '../../../../../api/session';
import { SEGMENT } from '../../../../../constants/analytics';
import { useSegment } from '../../../../../hooks/use-segment';
import { Card } from '../../../../../shared/components/Card/Card';
import { accordionArrowCSS, containerCSS, contentCSS, toggleCSS, toggleWrapperCSS } from './QuoteCardWrapper.style';
import type { QuoteCardWrapperProps } from './QuoteCardWrapper.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const QuoteCardWrapper: FC<QuoteCardWrapperProps> = ({ customCSS, children, toggleContent, clearPadding = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const id = useId();
  const { track } = useSegment();
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;

  const onClick = (): void => {
    setIsExpanded((currentState) => {
      if (!currentState) {
        track(SEGMENT.events.viewCoverageDetailsClicked, { page: currentPageKey });
      }
      return !currentState;
    });
  };

  return (
    <Card customCSS={[containerCSS(clearPadding), customCSS]}>
      {children}
      {isExpanded && (
        <div css={contentCSS} id={id}>
          {toggleContent}
        </div>
      )}
      <div css={toggleWrapperCSS}>
        <button css={toggleCSS} aria-controls={id} aria-expanded={isExpanded} onClick={onClick}>
          View Coverage Details
          <div css={accordionArrowCSS(isExpanded)} />
        </button>
      </div>
    </Card>
  );
};

export default QuoteCardWrapper;
