import { Elements } from '@stripe/react-stripe-js';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { useMutatePrimaryInsured, usePrimaryInsured } from '../../../../../api/primary-insured';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { MAIN_FONT_URL } from '../../../../../styles/font';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import { AdditionalCreditCardQuestions } from '../AdditionalCreditCardQuestions/AdditionalCreditCardQuestions';
import CheckoutContactInfoQuestions from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions';
import {
  PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA,
  PERSONAL_INFO_EMAIL_QUESTION_SCHEMA,
  PERSONAL_INFO_PHONE_QUESTION_SCHEMA,
} from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions.util';
import CheckoutCreditCardPreview from '../CheckoutCreditCardPreviw/CheckoutCreditCardPreview';
import { getStripeKey } from '../CheckoutCreditCardStep/CheckoutCreditCardStep.util';
import { PAYMENT_METHOD_CREDIT_CARD_OPTION } from '../CheckoutHomeOpportunityPage.util';
import StripeCardPaymentForm from '../StripeCardPaymentForm/StripeCardPaymentForm';
import { getStripePromise, STRIPE_CREDIT_CARD_ANSWER_KEY } from '../StripeCardPaymentForm/StripeCardPaymentForm.util';
import {
  getAdditionalQuestionDefaultAnswers,
  getBillingDayOfMonthAnswer,
  getPaymentTypeAnswer,
} from './CheckoutBundleCreditCardStep.util';
import type { CheckoutBundleCreditCardStepProps } from './CheckoutBundleCreditCardStep.type';
import type { Address } from '../../../../../types/address.type';

import type { AddressAnswer } from '../../../../../types/answer.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { StripePaymentStepData } from '../StripeCardPaymentForm/StripeCardPaymentForm.type';
import type { FC } from 'react';

const CheckoutBundleCreditCardStep: FC<CheckoutBundleCreditCardStepProps> = ({
  customCSS,
  isEdit,
  actionLabel = 'Move to Next Step',
  questions = [],
  contactInformation = true,
  creditCardNumber,
  isSubmitting = false,
  onStepSubmit,
  onBeforeStepSubmit,
}) => {
  const { gid } = useParams() as DefaultLocation;

  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;

  const { data: pageData } = usePage(gid, currentPageKey);
  const { data: configData } = usePublicConfig();
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const primaryInsuredQuery = usePrimaryInsured(gid);
  const { mutateAsync: mutatePrimaryInsured } = useMutatePrimaryInsured(gid);

  const stripeConfig = configData?.config.integrations.stripe;
  const carrierKey = sessionFlowConfig?.flow_config?.carrier_key;
  const primaryInsuredData = primaryInsuredQuery.data;

  const stripePromise = useMemo(
    () => getStripePromise(getStripeKey(stripeConfig, carrierKey)),
    [stripeConfig, carrierKey]
  );

  const onFormSubmit = async (data: StripePaymentStepData, lastCardNumber?: string): Promise<void> => {
    onBeforeStepSubmit();
    if (contactInformation) {
      const primaryInsuredPayload = {
        email: data[PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey] as string,
        phone: data[PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey] as string,
        mailing_address: data[PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey] as AddressAnswer,
      };

      await mutatePrimaryInsured(primaryInsuredPayload);
    }

    const questionsAnswers = questions.reduce((acc, question) => {
      return {
        ...acc,
        [question.key]: isVisibleByAnswers(data, question.visibility_conditions) ? data[question.key] : null,
      };
    }, {});

    await onStepSubmit(
      {
        [STRIPE_CREDIT_CARD_ANSWER_KEY]: data[STRIPE_CREDIT_CARD_ANSWER_KEY],
        ...questionsAnswers,
      },
      lastCardNumber
    );
  };

  const getDefaultAnswers = (): Record<string, Address | string | undefined | Record<string, never>> => {
    const contactInfoAnswers = contactInformation
      ? {
          [PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person.email,
          [PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person.phone,
          [PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person?.mailing_address ?? {},
        }
      : {};
    return {
      ...contactInfoAnswers,
      ...getAdditionalQuestionDefaultAnswers(questions, pageData?.answers ?? {}),
    };
  };

  return (
    <div css={customCSS}>
      {(isEdit || !creditCardNumber) && (
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: MAIN_FONT_URL }] }}>
          <StripeCardPaymentForm
            isBindDisabled={false}
            actionLabel={actionLabel}
            onAfterValidSubmit={onFormSubmit}
            additionalQuestions={
              <AdditionalCreditCardQuestions questions={questions ?? []} isDisabled={isSubmitting} />
            }
            defaultAnswers={getDefaultAnswers()}
            isSubmitting={isSubmitting}
          >
            {contactInformation && <CheckoutContactInfoQuestions isDisabled={isSubmitting} />}
          </StripeCardPaymentForm>
        </Elements>
      )}

      {!isEdit && primaryInsuredData?.person && creditCardNumber && (
        <CheckoutCreditCardPreview
          cardNumber={creditCardNumber}
          paymentMethod={PAYMENT_METHOD_CREDIT_CARD_OPTION.label}
          paymentType={getPaymentTypeAnswer(questions, pageData?.answers ?? {})}
          billingDayOfMonth={getBillingDayOfMonthAnswer(questions, pageData?.answers ?? {})}
          actionLabel={actionLabel}
          showButton={false}
          onButtonClick={onStepSubmit}
        />
      )}
    </div>
  );
};

export default CheckoutBundleCreditCardStep;
