import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text20CSS, text32CSS, text14CSS, textCSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 20px 160px;
  ${mq[0]} {
    padding: 72px 0 200px;
  }
`;

export const imageCSS = css`
  width: 144px;
  height: 144px;

  ${mq[0]} {
    width: 226px;
    height: 226px;
  }
`;

export const cardsWrapperCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  ${mq[0]} {
    flex-wrap: nowrap;
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 16px;

  ${mq[0]} {
    ${text32CSS};
    line-height: 40px;
    max-width: 430px;
  }
`;

export const subtitleCSS = css`
  ${text14CSS};
  line-height: 20px;
  margin-bottom: 32px;

  ${mq[0]} {
    ${textCSS};
    max-width: 520px;
  }
`;

export const moreButtonCSS = css`
  border-width: 4px;
  margin-top: 48px;
  ${mq[0]} {
    ${text20CSS};
  }
`;
