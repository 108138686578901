import { Modal as MuiModal } from '@mui/material';
import { type FC } from 'react';
import CloseIcon from '../../../assets/close.svg?react';
import noop from '../../utils/noop';
import Button from '../Button/Button';
import {
  buttonCSS,
  buttonsWrapperCSS,
  closeIconCSS,
  containerCSS,
  contentCSS,
  contentNotActionableCSS,
  overlayCSS,
  titleCSS,
} from './Modal.style';
import { ButtonDirection } from './Modal.type';
import type { ModalProps } from './Modal.type';

const Modal: FC<ModalProps> = ({
  customCSS,
  children,
  isOpen,
  isFullScreen = false,
  title,
  isNotActionable = false,
  isLoading = false,
  isExtendedWidth = false,
  actionButtons = [],
  buttonDirection = ButtonDirection.Row,
  onClose = noop,
}) => {
  const handleBackgroundClick = (): void => {
    if (isLoading || isNotActionable) {
      return;
    }

    onClose();
  };

  return (
    <MuiModal
      open={isOpen}
      onClose={handleBackgroundClick}
      slotProps={{
        backdrop: {
          sx: overlayCSS,
        },
      }}
    >
      <div css={[containerCSS(isFullScreen, isExtendedWidth), customCSS]}>
        {isNotActionable ? (
          <div css={contentNotActionableCSS}>{children}</div>
        ) : (
          <>
            <div css={titleCSS}>
              {title}
              <CloseIcon css={closeIconCSS} onClick={onClose} />
            </div>
            <div css={contentCSS(isFullScreen)}>{children}</div>
            {actionButtons.length > 0 && (
              <div css={buttonsWrapperCSS(isFullScreen, buttonDirection)}>
                {actionButtons.map(({ text, ...buttonProps }) => (
                  <Button key={text} customCSS={buttonCSS(buttonDirection)} isLoading={isLoading} {...buttonProps}>
                    {text}
                  </Button>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </MuiModal>
  );
};

export default Modal;
