import { useState, type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../../api/public/flow-config';
import { usePublicPrimaryInsured } from '../../api/public/primary-insured';
import { useSession } from '../../api/session';
import SIRV_IMAGES from '../../constants/sirv-images';
import Toggletip from '../../shared/components/Toggletip/Toggletip';
import { PageKeyHome } from '../../types/page.type';
import { StrategyType } from '../../types/public-flow-config.type';
import {
  iconCSS,
  reminderHeaderTextCSS,
  reminderSubHeaderTextCSS,
  reminderTextWrapperCSS,
  reminderTitleWrapperCSS,
  toggletipContentCSS,
} from './ContactReminderToggletip.style';
import useReminderStore from './contexts/ContactReminderToggletip.context';
import type { DefaultLocation } from '../../types/route-params.type';

const ContactReminderToggletip: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: flowConfigData } = usePublicFlowConfig(gid);
  const { isReminderPostponed } = useReminderStore();
  const currentPageKey = sessionData?.session.current_page_key;
  const isDiscountsPage = currentPageKey === PageKeyHome.Discounts;
  const isDigitalProfile = flowConfigData?.flow_config.strategy === StrategyType.DigitalProfile;

  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const userName = publicSessionPrimaryInsuredData?.primary_insured.first_name ?? '';

  const [displayContactReminder, setDisplayContactReminder] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!isReminderPostponed && isDiscountsPage && isDigitalProfile) {
      setDisplayContactReminder(true);
      timer = setTimeout(() => {
        setDisplayContactReminder(false);
      }, 6000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isDiscountsPage, isDigitalProfile, isReminderPostponed]);

  return (
    <>
      {displayContactReminder && (
        <Toggletip
          content={
            <div css={toggletipContentCSS}>
              <div css={reminderTextWrapperCSS}>
                <div css={reminderTitleWrapperCSS}>
                  <img src={SIRV_IMAGES.maleAgent} alt="Smiling man with headphones" css={iconCSS} />
                  <div css={reminderHeaderTextCSS}>Hi {userName},</div>
                </div>
                <div css={reminderSubHeaderTextCSS}>
                  You’ve got questions? We’ve got answers! Our experts are available to help you with any questions you
                  may have.
                </div>
              </div>
            </div>
          }
          showByDefault={displayContactReminder}
          displayButton={false}
        >
          <></>
        </Toggletip>
      )}
    </>
  );
};

export default ContactReminderToggletip;
