import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import type { Answers } from '../../../../../types/answer.type';
import type { QuestionSchema } from '../../../../../types/question.type';

export const getPaymentTypeAnswer = (questions: QuestionSchema[] | undefined, answers: Answers): string | null => {
  const question = questions?.find((q) =>
    [SCHEMA_KEYS.billingAutoPaymentType, SCHEMA_KEYS.billingHomePaymentType].includes(q.key)
  );

  return question && answers ? getSelectQuestionAnswer(question, answers) : null;
};

export const getBillingDayOfMonthAnswer = (
  questions: QuestionSchema[] | undefined,
  answers: Answers
): string | null => {
  const question = questions
    ?.filter((q) => isVisibleByAnswers(answers, q.visibility_conditions))
    .find((q) => [SCHEMA_KEYS.billingHomeDayOfMoth, SCHEMA_KEYS.billingAutoDayOfMoth].includes(q.key));
  return question && answers ? getSelectQuestionAnswer(question, answers) : null;
};

export const getSelectQuestionAnswer = (question: QuestionSchema, answers: Answers): string | null => {
  const questionKey = question.key;

  return question.variants?.find((v) => v.value === answers[questionKey])?.label ?? null;
};

export const getAdditionalQuestionDefaultAnswers = (
  questions: QuestionSchema[] | undefined,
  answers: Answers
): Record<string, string | undefined> => {
  return questions?.length
    ? questions?.reduce((acc, question) => {
        const hasRelevantVariant = question.variants?.some((v) => v.value === answers[question.key]);
        const answer = hasRelevantVariant ? { [question.key]: answers[question.key] } : {};
        return { ...acc, ...answer };
      }, {})
    : {};
};
