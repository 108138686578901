import type { AddressAnswer } from '../../../../types/answer.type';
import type { AddressSuggestion } from '../../../../types/smarty-streets.type';

export const getDefaultValueString = (value: AddressAnswer): string =>
  value?.line1 ? `${value.line1}, ${value.city}, ${value.state} ${value.zip}`.trim() : '';

export const suggestionToAnswer = (suggestion: AddressSuggestion): AddressAnswer => ({
  line1: `${suggestion.street_line} ${suggestion.secondary}`.trim(),
  city: suggestion.city,
  state: suggestion.state,
  zip: suggestion.zipcode,
});
