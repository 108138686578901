import { useCallback, useEffect, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuotesRequestSubscription } from '../../../../api/quote';
import useSession from '../../../../api/session/use-session';
import { SEGMENT } from '../../../../constants/analytics';
import { useSegment } from '../../../../hooks/use-segment';
import FullPageLoader from '../../../../shared/components/FullPageLoader/FullPageLoader';
import type { DigitalProfileQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';

const CustomerJourneyRedirect: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { track } = useSegment();
  const { data: sessionData, isLoading: isSessionDataLoading } = useSession(gid);
  const customerJourneyNextStepUrl = sessionData?.session?.customer_journey.next_step_url;

  const webSocketUrl = configData?.config.websocket_url;
  const { isPending: isQuotesRequestPending } = useInitQuotesRequestSubscription<DigitalProfileQuote>({
    gid,
    webSocketUrl,
  });

  const onRedirect = useCallback(() => {
    track(SEGMENT.events.fromDigitalProfileToCustomerPortalRedirected);
    !!customerJourneyNextStepUrl && window.open(customerJourneyNextStepUrl, '_self');
  }, [customerJourneyNextStepUrl, track]);

  useEffect(() => {
    if (!isQuotesRequestPending && !isSessionDataLoading && customerJourneyNextStepUrl) {
      onRedirect();
    }
  }, [customerJourneyNextStepUrl, isQuotesRequestPending, isSessionDataLoading, onRedirect]);

  return <FullPageLoader />;
};

export default CustomerJourneyRedirect;
