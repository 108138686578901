import { Fragment } from 'react';
import phoneIcon from '../../../../assets/phone.svg';
import Format from '../../../../utils/format.util';
import noop from '../../../utils/noop';
import Anchor from '../../Anchor/Anchor';
import {
  desktopContainerCSS,
  mobileContainerCSS,
  hoursCSS,
  callActionCSS,
  paragraphCSS,
  callActionMobileCSS,
  phoneImageCSS,
  phoneNumberCSS,
} from './HeaderContacts.style';
import type { HeaderContactsProps } from '../Header.type';
import type { FC } from 'react';

const HeaderContacts: FC<HeaderContactsProps> = ({ phoneNumber, customCSS, onPhoneClick = noop }) => {
  if (!phoneNumber) {
    return null;
  }

  return (
    <Fragment>
      <div css={[customCSS, desktopContainerCSS]}>
        <div css={hoursCSS}>
          <p css={paragraphCSS}>Mon - Fri:</p>
          <p css={paragraphCSS}>9AM - 8PM ET</p>
        </div>

        <div css={callActionCSS}>
          <p css={paragraphCSS}>Call a Matic agent</p>
          <p css={paragraphCSS}>
            at{' '}
            <Anchor
              anchorType="underline-active"
              href={`tel:${phoneNumber}`}
              onClick={onPhoneClick}
              customCSS={phoneNumberCSS}
            >
              {Format.phone(phoneNumber)}
            </Anchor>
          </p>
        </div>
      </div>
      <div css={[customCSS, mobileContainerCSS]}>
        <a css={callActionMobileCSS} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
          <img css={phoneImageCSS} src={phoneIcon} alt="Phone" /> <span>Call Us</span>
        </a>
      </div>
    </Fragment>
  );
};

export default HeaderContacts;
