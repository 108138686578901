import { useState, type FC } from 'react';
import LockIcon from '../../../../../assets/lock.svg?react';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { stickyContainerCSS } from '../../../../../styles/layout.style';

import CheckoutBundleForm from '../CheckoutBundleForm/CheckoutBundleForm';
import { HOME_BUNDLE_STEP } from '../CheckoutBundleForm/CheckoutBundleForm.util';
import CheckoutBundleSummary from '../CheckoutBundleSummary/CheckoutBundleSummary';
import {
  containerCSS,
  formContainerCSS,
  pageTitleCSS,
  layoutRightColumnCSS,
  pageSubtitleCSS,
} from './CheckoutBundle.style';
import type { CheckoutBundleStep } from '../CheckoutBundleForm/CheckoutBundleForm.type';

const CheckoutBundle: FC = () => {
  const [currentStep, setCurrentStep] = useState<CheckoutBundleStep>(HOME_BUNDLE_STEP);
  const [maxStep, setMaxStep] = useState<number>(1);
  const { isDesktop } = useResponsive();
  const [homePaymentCreditCard, setHomePaymentCreditCard] = useState<string>('');
  const [autoPaymentCreditCard, setAutoPaymentCreditCard] = useState<string>('');

  return (
    <div css={containerCSS}>
      <div css={formContainerCSS}>
        <div css={pageTitleCSS}>
          <span>Secure checkout</span>
          <LockIcon />
        </div>
        <div css={pageSubtitleCSS}>We never store your payment information and your payment is secure.</div>
        <CheckoutBundleForm
          currentStep={currentStep}
          updateCurrentStep={setCurrentStep}
          maxStep={maxStep}
          updateMaxStep={setMaxStep}
          homePaymentCreditCard={homePaymentCreditCard}
          autoPaymentCreditCard={autoPaymentCreditCard}
          setAutoPaymentCreditCard={setAutoPaymentCreditCard}
          setHomePaymentCreditCard={setHomePaymentCreditCard}
        />
      </div>
      {isDesktop && (
        <div css={layoutRightColumnCSS}>
          <div css={stickyContainerCSS}>
            <CheckoutBundleSummary
              currentStep={currentStep}
              homePaymentCreditCard={homePaymentCreditCard}
              autoPaymentCreditCard={autoPaymentCreditCard}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutBundle;
