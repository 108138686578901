import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const headerCSS = css`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  font-weight: bold;
  margin-bottom: 4px;
  border: unset;
  background-color: transparent;
  padding: 0 20px 24px 0;
  ${text20CSS};

  ${mq[0]} {
    ${text24CSS};
    line-height: 32px;
  }
`;

export const imageCSS = css`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
`;

export const contentCSS = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${mq[1]} {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 48px;
  }
`;

export const coverageCSS = css`
  ${mq[1]} {
    flex: 1 0 calc(50% - 48px);
  }
`;

export const iconBackgroundCSS = css`
  background-color: rgba(64, 141, 247, 0.2);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 8px;
  ${mq[1]} {
    width: 40px;
    height: 40px;
  }
`;

export const iconCSS = css`
  position: relative;
  top: 4px;
  width: 24px;
  height: 24px;
  ${mq[1]} {
    top: 8px;
  }
`;

const expandedCSS = css`
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

export const accordionArrowCSS = (expanded: boolean): SerializedStyles => css`
  flex: 0 0 24px;
  height: 10px;
  width: 18px;
  position: absolute;
  right: 0;
  &::after,
  &::before {
    background-color: ${theme.colors.azure62};
    content: '';
    display: block;
    height: 5px;
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease;
    width: 16px;
    border-radius: 2px;
  }
  &::before {
    left: 5px;
    transform: rotate(45deg);
  }
  &::after {
    right: 5px;
    transform: rotate(-45deg);
  }
  ${expanded && expandedCSS};
`;
