const yyyyMMDDPattern = /(\d{4})-(\d{2})-(\d{2})/;
const mmDDYYYYPattern = /(\d{2})-(\d{2})-(\d{4})/;

const yearInMs = 3.15576e10;
export const getAge = (date: string): number | string => {
  const result = Math.floor((Date.now() - +new Date(date)) / yearInMs);
  if (isNaN(result)) return date;

  return result;
};

export const parseDateValue = (value: string): string => {
  // YYYY-MM-DD to MM-DD-YYYY
  if (!value.match(yyyyMMDDPattern)) return value;

  return value.replace(yyyyMMDDPattern, '$2-$3-$1');
};

export const normalizeDateValue = (value: string): string => {
  // MM-DD-YYYY to YYYY-MM-DD
  if (!value.match(mmDDYYYYPattern)) return value;

  return value.replace(mmDDYYYYPattern, '$3-$1-$2');
};

export const formatDateValue = (value: string): string => {
  // YYYY-MM-DD to MM/DD/YYYY
  if (!value.match(yyyyMMDDPattern)) return value;

  return value.replace(yyyyMMDDPattern, '$2/$3/$1');
};

export const getDateFromTodayByDays = (days: string): Date =>
  new Date(new Date(new Date()).setDate(new Date().getDate() + parseInt(days)));
