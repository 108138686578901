import { Global } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuotesRequestSubscription } from '../../../../api/quote';
import { useSessionFlowConfig } from '../../../../api/session-flow-config';
import QuotePageLoader from '../../../../components/QuotePageLoader/QuotePageLoader';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { hideFooterCSS } from '../../../../shared/shared.style';
import { getPrimaryQuote } from '../../../../utils/quote.util';
import { loaderCSS } from './AutoQuoteOpportunityPage.style';
import AutoQuotePageContent from './AutoQuotePageContent/AutoQuotePageContent';
import type { AutoQuoteOpportunityPageProps } from './AutoQuoteOpportunityPage.type';
import type { AutoQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const AutoQuoteOpportunityPage: FC<AutoQuoteOpportunityPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: configData } = usePublicConfig();
  const webSocketUrl = configData?.config.websocket_url;
  const { quoteRequest } = useInitQuotesRequestSubscription<AutoQuote>({ gid, webSocketUrl });

  const quotes = quoteRequest?.groups[0]?.quotes;

  const quote = getPrimaryQuote<AutoQuote>(
    quotes,
    sessionFlowConfig?.flow_config.policy_type,
    sessionFlowConfig?.flow_config?.carrier_key
  );

  if (!quote) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} imgUrl={SIRV_IMAGES.carLoader} />
      </>
    );
  }

  return <AutoQuotePageContent customCSS={customCSS} quote={quote} />;
};

export default AutoQuoteOpportunityPage;
