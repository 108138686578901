import { css } from '@emotion/react';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import { formWithGridCSS } from '../../../../../styles/form.style';
import { mq } from '../../../../../styles/media-queries';

export const formCSS = css`
  ${activeElementResetCSS};
  ${formWithGridCSS};
`;

export const buttonCSS = css`
  margin-top: 12px;
`;

export const formInputCSS = css`
  flex-basis: 100%;
  ${mq[0]} {
    flex-basis: 50%;
  }
`;
