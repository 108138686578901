import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 588px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;

  > div:first-of-type {
    ::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      right: 50%;
      background-color: ${theme.colors.white};
      z-index: -1;
    }
  }

  > div:last-of-type {
    ::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      background-color: ${theme.colors.white};
      z-index: -1;
    }
  }
`;

export const animatedDashedLoaderCSS = css`
  position: absolute;
  width: 100%;
  height: 2px;
  top: 16px;
  bottom: 0;

  ${mq[1]} {
    top: 36px;
  }
`;
