import { POLICY_BRANCH_AUTO_COVERAGES_KEYS } from '../../../../../constants/schema-hardcoded-keys';
import { POLICY_AUTO_COVERAGES_GROUP } from '../../../../../utils/coverages.util';
import type { AutoQuoteCoverage } from '../../../../../types/quote.type';
import type { QuoteCoveragesGroup, QuoteCoverageView } from '../../QuoteCoverageGroup/QuoteCoverageGroup.type';

export const getBranchPolicyCoveragesGroup = (coverages: AutoQuoteCoverage[]): QuoteCoveragesGroup[] => {
  const policyCoverages = coverages.reduce<QuoteCoverageView[]>((acc, coverage) => {
    if (POLICY_BRANCH_AUTO_COVERAGES_KEYS.includes(coverage.key)) {
      return [
        ...acc,
        {
          title: coverage.title,
          value: coverage.value,
          description: coverage.description,
          iconUrl: coverage.icon_url,
          key: coverage.key,
        },
      ];
    }
    return acc;
  }, []);

  return policyCoverages.length > 0
    ? [
        {
          assetGid: POLICY_AUTO_COVERAGES_GROUP.gid,
          title: POLICY_AUTO_COVERAGES_GROUP.name,
          coverages: policyCoverages,
        },
      ]
    : [];
};

export const getBranchNotPolicyCoverages = (coverages: AutoQuoteCoverage[]): AutoQuoteCoverage[] => {
  return coverages.filter((coverage) => !POLICY_BRANCH_AUTO_COVERAGES_KEYS.includes(coverage.key));
};
