import { useEffect, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../api/carrier';
import { useInitialQuote } from '../../../../api/quote';
import MaticHeartIcon from '../../../../assets/matic-heart.svg?react';
import useReminderStore from '../../../../components/ContactReminderToggletip/contexts/ContactReminderToggletip.context';
import PreviewPageHeader from '../../../../components/PreviewPageHeader/PreviewPageHeader';
import { SEGMENT } from '../../../../constants/analytics';
import useResponsive from '../../../../hooks/use-responsive';
import { useSegment } from '../../../../hooks/use-segment';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { Card } from '../../../../shared/components/Card/Card';
import Divider from '../../../../shared/components/Divider/Divider';
import Logo from '../../../../shared/components/Logo/Logo';
import Modal from '../../../../shared/components/Modal/Modal';
import { previewPageCSS } from '../../../../styles/layout.style';
import { PageKeyHome } from '../../../../types/page.type';
import format from '../../../../utils/format.util';
import {
  buttonCustomCSS,
  cardHeadingCSS,
  customCardCSS,
  customModalCSS,
  descriptionCSS,
  headingCSS,
  innerCardContentCSS,
  priceCSS,
  priceDescriptionCSS,
} from './DiscountsPagePreview.style';
import FinalizeSteps from './FinalizeSteps/FinalizeSteps';
import type { DiscountsPagePreviewProps } from './DiscountsPagePreview.type';

import type { HomeInitialQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';

export const DiscountsPagePreview: FC<DiscountsPagePreviewProps> = ({ setPreviewAnswer }) => {
  const { isMobile } = useResponsive();
  const { gid } = useParams() as DefaultLocation;
  const { data: initialQuote } = useInitialQuote<HomeInitialQuote>(gid);
  const { track } = useSegment();
  const { postponeReminder, isReminderPostponed } = useReminderStore();
  const { data: carrierData } = useSelectedCarrier(gid);

  useEffect(() => {
    if (!isReminderPostponed) {
      postponeReminder();
    }
  }, [postponeReminder, isReminderPostponed]);

  useEffect(() => {
    track(SEGMENT.events.pagePreviewViewed, {
      page: PageKeyHome.Discounts,
    });
  }, [track]);

  const onCTAClicked = (): void => {
    track(SEGMENT.events.pagePreviewCTAClicked, {
      page: PageKeyHome.Discounts,
    });

    setPreviewAnswer('no');
  };

  const quote = initialQuote?.quote;

  return (
    <Modal isOpen isFullScreen isNotActionable customCSS={customModalCSS}>
      <PreviewPageHeader carrierLogoUrl={carrierData?.carrier?.logo_url} carrierName={quote?.carrier_name} />

      <div css={previewPageCSS}>
        <h2 css={headingCSS}>
          <MaticHeartIcon height={42} /> Let’s finalize your policy!
        </h2>
        <p css={descriptionCSS}>
          In order to finalize your {quote?.carrier_name ?? ''} policy we need some additional details on you and your
          home so that we can <span>customize the coverage specifically for you.</span>
        </p>

        {!!quote?.premium && !!+quote.premium && (
          <Card customCSS={customCardCSS}>
            <div css={cardHeadingCSS}>Your selected policy:</div>
            <div css={innerCardContentCSS}>
              <Logo
                height={isMobile ? 47 : 72}
                width={isMobile ? 120 : 182}
                carrierLogo={carrierData?.carrier?.logo_url}
                carrierName={carrierData?.carrier?.name}
              />
              <Divider height={isMobile ? 47 : 55} />
              <div>
                <p css={priceCSS}>
                  {format.money(quote.premium, 0, '$')}
                  <span>/yr</span>
                </p>
                <p css={priceDescriptionCSS}>Annual Premium</p>
              </div>
            </div>
          </Card>
        )}

        <FinalizeSteps />
        <Button fullWidth size={ButtonSize.Large} customCSS={buttonCustomCSS} onClick={onCTAClicked}>
          Get Started
        </Button>
      </div>
    </Modal>
  );
};
