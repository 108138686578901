import type { QuestionVariant } from '../../../../../types/question.type';
import type { Servicer } from '../../../../../types/servicer.type';

export const getServicersOptions = (servicers?: Servicer[]): QuestionVariant[] =>
  !servicers
    ? []
    : servicers.map((s) => ({
        value: s.gid,
        label: s.name,
      }));
