import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../api/page';
import { useSession } from '../../../../api/session';
import { prepareWorkflowDefaultAnswers } from '../../../../questionsForm/utils/answers/workflow-schema-answers.util';
import FullPageLoader from '../../../../shared/components/FullPageLoader/FullPageLoader';
import { innerLayoutContainerCSS } from '../../../../styles/layout.style';
import isVisibleByAnswers from '../../../../utils/visibility-conditions.util';
import PageFormWrapper from '../../../questions/components/PageFormWrapper/PageFormWrapper';
import DiscountsForm from '../DiscountsForm/DiscountsForm';
import { DiscountsPagePreview } from '../DiscountsPagePreview/DiscountsPagePreview';
import { questionFormCSS } from './DiscountsPage.style';
import type { DiscountsPageProps, SimpleAnswer } from './DiscountsPage.type';
import type { Answers } from '../../../../types/answer.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const DiscountsPage: FC<DiscountsPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData, isLoading } = usePage(gid, currentPageKey);
  const [defaultAnswers, setDefaultAnswers] = useState<Answers>({});
  const defaultAnswersPageKey = useRef<string>();
  const [previewAnswer, setPreviewAnswer] = useState<SimpleAnswer | null>(null);

  useEffect(() => {
    if (pageData?.answers && defaultAnswersPageKey.current !== currentPageKey) {
      const defaultAnswers = prepareWorkflowDefaultAnswers(pageData);
      setDefaultAnswers(defaultAnswers);
      defaultAnswersPageKey.current = currentPageKey;
    }
  }, [pageData, currentPageKey]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  const isPagePreviewVisible =
    pageData?.page.page_preview &&
    isVisibleByAnswers(
      (previewAnswer || pageData?.answers) as Answers,
      pageData?.page.page_preview?.visibility_conditions
    );

  return (
    <div css={customCSS}>
      <PageFormWrapper defaultValues={defaultAnswers}>
        {isPagePreviewVisible ? (
          <DiscountsPagePreview setPreviewAnswer={setPreviewAnswer} />
        ) : (
          <DiscountsForm customCSS={[innerLayoutContainerCSS(false), questionFormCSS]} />
        )}
      </PageFormWrapper>
    </div>
  );
};

export default DiscountsPage;
