import debounce from 'debounce-promise';
import { useCallback, useRef, useState } from 'react';
import Async from 'react-select/async';
import api from '../../../api/api';
import noop from '../../utils/noop';
import { selectCSS } from './Select.style';
import { DEFAULT_SELECT_OPTIONS } from './Select.util';
import { Control, DropdownIndicator, MenuList, Option } from './SelectParts.utils';
import type { AsyncSelectProps, VehicleOption } from './Select.type';
import type { FC } from 'react';
import type Select from 'react-select/dist/declarations/src/Select';

const loadOptions =
  (url: string) =>
  async (inputValue: string): Promise<VehicleOption[]> => {
    const response = await api.get<{ data: VehicleOption[] }>(`${url}?term=${inputValue}`);
    return await Promise.resolve(response.data);
  };

const VehicleSelect: FC<AsyncSelectProps> = ({
  inputId,
  url,
  componentRef,
  value,
  placeholder,
  isDisabled,
  hasError,
  dense,
  menuShouldBlockScroll,
  onValidEntry,
  onBlur,
  onValidatePreviousInputs = noop,
}) => {
  if (!url) {
    throw new Error('`url` must be provided in props of an AsyncSelect.');
  }

  const [selected, setSelected] = useState<VehicleOption | null>(null);
  const selectRef = useRef<Select<VehicleOption>>(null);
  const wrapperRef = useRef(null);

  if (componentRef && selectRef.current) {
    componentRef(selectRef.current.inputRef);
  }

  const hasPropsValue = useCallback(() => value && Object.keys(value as VehicleOption).length > 0, [value]);

  const inputValue = (selected ?? hasPropsValue()) ? value : null;

  const onChange = (value: VehicleOption | null): void => {
    if (!value) {
      return;
    }
    setSelected({ ...value, gid: undefined });
    !!onValidEntry && onValidEntry({ ...value, gid: undefined });
    onValidatePreviousInputs();
  };

  const getLabelString = (option: VehicleOption): string => `${option.year} ${option.make} ${option.model}`;
  const getOptionValue = (option: VehicleOption): string => option.gid ?? '';

  return (
    <div ref={wrapperRef}>
      <Async
        {...DEFAULT_SELECT_OPTIONS}
        cacheOptions
        ref={selectRef}
        inputId={inputId}
        isDisabled={isDisabled}
        css={selectCSS(hasError, isDisabled, dense)}
        noOptionsMessage={() => 'Start typing to find a vehicle'}
        placeholder={placeholder}
        getOptionLabel={getLabelString}
        getOptionValue={getOptionValue}
        loadOptions={debounce(loadOptions(url), 300)}
        value={inputValue as VehicleOption}
        menuShouldBlockScroll={menuShouldBlockScroll}
        components={{
          MenuList,
          Option,
          DropdownIndicator,
          Control,
        }}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default VehicleSelect;
