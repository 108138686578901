import { ComponentTypes } from '../../../../types/form-component.type';
import { PolicyType } from '../../../../types/policy.type';
import { isAutoPolicyDetailsComponent, isPolicyDetailsSchema } from '../../../../utils/schema.util';
import CongratulationsInstructionStep from '../CongratulationsInstructionStep/CongratulationsInstructionStep';
import CongratulationsTextBlock from '../CongratulationsTextBlock/CongratulationsTextBlock';
import type {
  FormComponentSchema,
  TextBlockSchema,
  InstructionStepSchema,
} from '../../../../types/form-component.type';
import type { CustomSectionSchema } from '../../../../types/section.type';
import type { PolicySummary } from '../PoliciesSummaryDetails/PoliciesSummaryDetails.types';
import type { ReactElement } from 'react';

export const getComponentByType = (
  { key, type, ...c }: FormComponentSchema,
  index: number,
  visibleComponents: FormComponentSchema[]
): ReactElement | null => {
  switch (type) {
    case ComponentTypes.TextBlock: {
      const textBlockSchema = c as Omit<TextBlockSchema, 'key' | 'type'>;
      return <CongratulationsTextBlock key={key} heading={textBlockSchema.heading} />;
    }
    case ComponentTypes.InstructionStep: {
      const instructionStepSchema = c as Omit<InstructionStepSchema, 'key' | 'type'>;
      return (
        <CongratulationsInstructionStep
          key={key}
          showLine={visibleComponents[index + 1]?.type === ComponentTypes.InstructionStep}
          stepNumber={index}
          heading={instructionStepSchema.heading}
          description={instructionStepSchema.description}
          iconUrl={instructionStepSchema.icon_url}
          email={instructionStepSchema.email ?? ''}
          phone={instructionStepSchema.phone ?? ''}
          url={instructionStepSchema.url}
        />
      );
    }
    default:
      return null;
  }
};

export const getPolicies = (policiesSchema: CustomSectionSchema): PolicySummary[] => {
  return policiesSchema.components.reduce<PolicySummary[]>((acc, curr) => {
    if (isPolicyDetailsSchema(curr)) {
      const { policy_number: policyNumber, policy_term: policyTerm, effective_date: effectiveDate } = curr.policy;
      const policyType = isAutoPolicyDetailsComponent(curr) ? PolicyType.Auto : PolicyType.Home;
      return [...acc, { policyNumber, policyTerm, policyType, policyEffectiveDate: effectiveDate }];
    }
    return acc;
  }, []);
};
