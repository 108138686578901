import QuestionIcon from '../../../../assets/grey-question.svg?react';
import HomeIcon from '../../../../assets/home-simple.svg?react';
import VehicleIcon from '../../../../assets/vehicle.svg?react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import useResponsive from '../../../../hooks/use-responsive';
import { Card } from '../../../../shared/components/Card/Card';
import DashedRow from '../../../../shared/components/DashedRow/DashedRow';
import SirvImage from '../../../../shared/components/SirvImage/SirvImage';
import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
import { PolicyType, UNKNOWN_POLICY_NUMBER } from '../../../../types/policy.type';
import format from '../../../../utils/format.util';
import { formatPlurality } from '../../../../utils/string-helpers';
import {
  carrierNameCSS,
  containerCSS,
  contentCSS,
  contentItemCSS,
  dashedRowCSS,
  headerCSS,
  headerDividerCSS,
  iconCSS,
  logoCSS,
  policiesContentCSS,
  unknownPolicyNumberCSS,
} from './PoliciesSummaryDetails.style';
import type { PoliciesSummaryDetailsProps } from './PoliciesSummaryDetails.types';
import type { FC, ReactNode } from 'react';

const PoliciesSummaryDetails: FC<PoliciesSummaryDetailsProps> = ({
  customCSS,
  policies,
  carrier,
  renderSummaryList,
  isBundle = false,
}) => {
  const { isMobile } = useResponsive();

  const { showDate, showNumber, showTerm } = renderSummaryList;
  const unknownPolicyNumberContent = (
    <>
      Not Yet Issued
      <Tooltip
        customCSS={{ buttonCSS: unknownPolicyNumberCSS }}
        content={
          <>
            You will receive an email from <span css={carrierNameCSS}>{carrier.name}</span> in 24 hours with your policy
            number.
          </>
        }
      >
        <QuestionIcon />
      </Tooltip>
    </>
  );

  const getPolicyNumberContent = (policyNumber: string): ReactNode =>
    policyNumber === UNKNOWN_POLICY_NUMBER ? unknownPolicyNumberContent : policyNumber;

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div css={headerCSS}>
        <SirvImage
          customCSS={logoCSS}
          url={carrier.logo_url}
          width={200}
          height={isMobile ? 30 : 50}
          alt={`${carrier.name} logo`}
        />
        <div css={headerDividerCSS} />
        <span>Policy Details:</span>
      </div>
      <div css={policiesContentCSS}>
        {policies.map((policy, index) => (
          <div css={contentCSS(isBundle)} key={policy.policyNumber + '_' + index}>
            {showNumber && (
              <div css={contentItemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
                <DashedRow
                  customCSS={dashedRowCSS}
                  label={
                    <>
                      {isBundle ? (
                        policy.policyType === PolicyType.Auto ? (
                          <VehicleIcon css={iconCSS} />
                        ) : (
                          <HomeIcon css={iconCSS} />
                        )
                      ) : null}
                      Policy Number:
                    </>
                  }
                  content={getPolicyNumberContent(policy.policyNumber)}
                />
              </div>
            )}
            {showDate && (
              <div css={contentItemCSS}>
                <DashedRow
                  customCSS={dashedRowCSS}
                  label={'Policy Start Date:'}
                  content={format.date(policy.policyEffectiveDate)}
                />
              </div>
            )}
            {showTerm && (
              <div css={contentItemCSS}>
                <DashedRow
                  customCSS={dashedRowCSS}
                  label={'Policy Term:'}
                  content={
                    policy.policyTerm < 12
                      ? formatPlurality('Month', policy.policyTerm)
                      : formatPlurality('Year', policy.policyTerm / 12)
                  }
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default PoliciesSummaryDetails;
