import { css } from '@emotion/react';
import { mq } from '../../../../../../styles/media-queries';
import theme from '../../../../../../styles/theme';
import { text14CSS, text20CSS, textCSS } from '../../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;

  width: calc(100% / 4);

  ${mq[1]} {
    margin-bottom: 40px;
    width: unset;
  }
`;

export const imgWrapperCSS = css`
  width: 72px;
  height: 72px;
  justify-content: center;
  border: 1px solid ${theme.colors.gray10};
  border-radius: 50%;
  display: none;
  position: relative;

  ${mq[1]} {
    display: flex;
  }

  :before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${theme.colors.white};
    z-index: -1;
  }
`;

export const descriptionCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  color: ${theme.colors.gray60};
  font-weight: 700;
  ${text14CSS};

  ${mq[1]} {
    flex-direction: row;
    ${textCSS};
  }
`;

export const countCSS = (isFirstElement: boolean): SerializedStyles => css`
  ${text20CSS};
  width: 32px;
  height: 32px;
  border: 2px solid ${isFirstElement ? theme.colors.azure62 : theme.colors.gray30};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mq[1]} {
    width: 24px;
    height: 24px;
  }

  :before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${theme.colors.white};
    z-index: -1;
  }
`;
