import { useFormContext } from 'react-hook-form';
import HouseIconSmall from '../../../../../assets/home-simple.svg?react';
import CarIcon from '../../../../../assets/quote-hero-car.svg?react';
import HouseIcon from '../../../../../assets/quote-hero-house.svg?react';
import UpdateSmallIcon from '../../../../../assets/update-small.svg?react';
import CarIconSmall from '../../../../../assets/vehicle.svg?react';
import { AUTOMATION_TEST_ID } from '../../../../../constants/automation-test-id';
import Question from '../../../../../questionsForm/components/Question/Question';
import DashedRow from '../../../../../shared/components/DashedRow/DashedRow';
import Logo from '../../../../../shared/components/Logo/Logo';
import TooltipQuestionButton from '../../../../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import { PolicyType } from '../../../../../types/policy.type';
import { HOME_POLICY_TYPES } from '../../../../../types/quote.type';
import format from '../../../../../utils/format.util';
import { getQuoteSavings } from '../../../../../utils/quote.util';
import {
  carIconCSS,
  carrierLogoCSS,
  containerCSS,
  contentColumnCSS,
  contentCSS,
  dashedRowCSS,
  houseIconCSS,
  logoContainerCSS,
  numbersCSS,
  numbersHeaderCSS,
  policyIconContainerCSS,
  savingsTagButtonCSS,
  savingsTagCSS,
  smallIconCSS,
  dateWrapperCSS,
} from '../../DigitalProfileQuotePage/DigitalProfileBundleQuoteDetails/DigitalProfileBundleQuoteDetails.style';
import { getAnnualPremium } from '../../DigitalProfileQuotePage/DigitalProfileBundleQuoteDetails/DigitalProfileBundleQuoteDetails.util';
import QuoteDetailsHero from '../../DigitalProfileQuotePage/QuoteDetailsHero/QuoteDetailsHero';
import { titleContainerCSS, updateIconCSS } from './BundleQuoteDetails.style';
import { getEffectiveDateQuestionToRender } from './BundleQuoteDetails.util';
import type { BundleQuoteDetailsProps } from './BundleQuoteDetails.type';

import type { FC } from 'react';

const BundleQuoteDetails: FC<BundleQuoteDetailsProps> = ({
  customCSS,
  homeEffectivePolicy,
  quotes,
  quotesGroup,
  sections,
  onFinalizeCheckoutClicked,
}) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const homeQuote = quotes.find((quote) => HOME_POLICY_TYPES.includes(quote.policy_type));
  const autoQuote = quotes.find((quote) => quote.policy_type === PolicyType.Auto);
  const homeOnly = !autoQuote;

  const totalDiscountPremiumPerMonth =
    Math.round((Number(quotesGroup.total_premium_with_multi_policy_discount) / 12) * 100) / 100;

  const mpdApplied = quotesGroup.multi_policy_discount;
  const homePremium = getAnnualPremium(homeQuote, mpdApplied);
  const autoPremium = getAnnualPremium(autoQuote, mpdApplied);

  const savings = getQuoteSavings(homeQuote, mpdApplied, homeEffectivePolicy);

  const homeEffectiveDateComponent = getEffectiveDateQuestionToRender(
    homeQuote?.policy_effective_date ?? '',
    PolicyType.Home,
    sections ?? [],
    homeEffectivePolicy?.expiration_date
  );
  const autoEffectiveDateComponent = getEffectiveDateQuestionToRender(
    autoQuote?.policy_effective_date ?? '',
    PolicyType.Auto,
    sections ?? []
  );

  const premiumString = `$${format.money(Number(homePremium))}`;
  const currentPremiumString = `$${format.money(Number(homePremium) + savings)}`;

  const savingsTooltipContent =
    '<p>This is how much you may save if you switch your policies. We calculated ' +
    'these savings based on the difference between your current premium and this quoted premium.</p>' +
    `<p><strong>${currentPremiumString} - ${premiumString} = $${format.money(savings)}</strong></p>`;

  const updateQuoteTitle = (
    <div css={titleContainerCSS}>
      <UpdateSmallIcon css={updateIconCSS} />
      Update Quote
    </div>
  );
  const actionLabel = isDirty ? updateQuoteTitle : 'Finalize Checkout';

  return (
    <div css={[containerCSS, customCSS]}>
      <QuoteDetailsHero
        premium={totalDiscountPremiumPerMonth}
        homeOnly={homeOnly}
        actionButtonLabel={actionLabel}
        onActionClick={onFinalizeCheckoutClicked}
      />
      <div css={contentCSS}>
        <div css={contentColumnCSS}>
          <div css={numbersHeaderCSS}>
            <div css={logoContainerCSS}>
              <HouseIconSmall css={smallIconCSS} />
              <Logo
                carrierLogo={homeQuote?.carrier_logo_url}
                carrierName={homeQuote?.carrier_name}
                customCSS={carrierLogoCSS}
                height={80}
              />
              <div>
                {savings > 0 && (
                  <div data-testid={AUTOMATION_TEST_ID.bundleQuotePage.homeSavings} css={savingsTagCSS}>
                    Savings ${format.money(savings)}
                    <TooltipQuestionButton customCSS={savingsTagButtonCSS} size={16} content={savingsTooltipContent} />
                  </div>
                )}
              </div>
            </div>
            <div css={policyIconContainerCSS}>
              <HouseIcon css={houseIconCSS} />
            </div>
          </div>
          <div css={numbersCSS}>
            <DashedRow
              testId={AUTOMATION_TEST_ID.bundleQuotePage.homeAnnualPremium}
              customCSS={dashedRowCSS}
              label="Annual Premium"
              content={`$${format.money(homePremium)}`}
            />
          </div>

          <div css={dateWrapperCSS}>
            {!!homeEffectiveDateComponent?.questionKey && (
              <Question key={homeEffectiveDateComponent.questionKey} {...homeEffectiveDateComponent} />
            )}
          </div>
        </div>

        <div css={contentColumnCSS}>
          <div css={numbersHeaderCSS}>
            <div css={logoContainerCSS}>
              <CarIconSmall css={smallIconCSS} />
              <Logo
                carrierLogo={autoQuote?.carrier_logo_url}
                carrierName={autoQuote?.carrier_name}
                customCSS={carrierLogoCSS}
                height={80}
              />
            </div>
            <div css={policyIconContainerCSS}>
              <CarIcon css={carIconCSS} />
            </div>
          </div>
          <div css={numbersCSS}>
            <DashedRow
              testId={AUTOMATION_TEST_ID.bundleQuotePage.autoAnnualPremium}
              customCSS={dashedRowCSS}
              label="6 Month Premium"
              content={`$${format.money(autoPremium / 2)}`}
            />
          </div>

          <div css={dateWrapperCSS}>
            {!!autoEffectiveDateComponent?.questionKey && (
              <Question key={autoEffectiveDateComponent.questionKey} {...autoEffectiveDateComponent} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundleQuoteDetails;
