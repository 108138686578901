import SCHEMA_KEYS from '../constants/schema-hardcoded-keys';
import { ComponentTypes } from '../types/form-component.type';
import { FormElementType } from '../types/form-element.type';
import { SectionPresentationType } from '../types/section.type';
import type {
  BillingInformationSchema,
  WorkflowSchema,
  PaymentMethodInformationSchema,
  FormComponentSchema,
  DisclosureSchema,
  DeductibleSchema,
  OptionalCoverageSchema,
  EffectiveDateSchema,
  PolicySummaryDetailsSchema,
} from '../types/form-component.type';
import type { FormElementSchema, QuestionElementContent, DisclosureContent } from '../types/form-element.type';
import type { PageResponse } from '../types/page.type';
import type {
  CustomSectionSchema,
  SectionSchema,
  AuthSectionSchema,
  WorkflowSectionSchema,
} from '../types/section.type';

export const isFormComponentSchemaWithQuestions = (
  component: FormComponentSchema
): component is WorkflowSchema | BillingInformationSchema | PaymentMethodInformationSchema => {
  return (
    component.type === ComponentTypes.Workflow ||
    component.type === ComponentTypes.BillingInformation ||
    component.type === ComponentTypes.CCPaymentMethodInformation ||
    component.type === ComponentTypes.EFTPaymentMethodInformation
  );
};

export const isDisclosureComponent = (component: FormComponentSchema): component is DisclosureSchema => {
  return component.type === ComponentTypes.Disclosure;
};

export const isCustomSection = (section: SectionSchema): section is CustomSectionSchema => {
  return section.presentation_type === SectionPresentationType.Custom;
};

export const isWorkflowSection = (section: SectionSchema): section is WorkflowSectionSchema => {
  return section.presentation_type === SectionPresentationType.Workflow;
};

export const isAuthSection = (section: SectionSchema): section is AuthSectionSchema => {
  return section.presentation_type === SectionPresentationType.Authorization;
};

export const hasAuthSection = (sections: SectionSchema[]): boolean => {
  return sections.some((section) => section.presentation_type === SectionPresentationType.Authorization);
};

export const getDeductibleComponent = (sections: SectionSchema[] = []): DeductibleSchema | undefined => {
  return sections.reduce<DeductibleSchema | undefined>((acc, section) => {
    if (acc ?? !isCustomSection(section)) return acc;

    return section.components.find(
      (component: FormComponentSchema) => component.type === ComponentTypes.Deductible
    ) as DeductibleSchema;
  }, undefined);
};

export const getEffectiveDateComponent = (sections: SectionSchema[] = []): EffectiveDateSchema | undefined => {
  return sections.reduce<EffectiveDateSchema | undefined>((acc, section) => {
    if (acc ?? !isCustomSection(section)) return acc;

    return section.components.find(
      (component: FormComponentSchema) => component.type === ComponentTypes.EffectiveDate
    ) as EffectiveDateSchema;
  }, undefined);
};

export const getOptionalCoverageComponents = (sections: SectionSchema[] = []): OptionalCoverageSchema[] =>
  sections.reduce<OptionalCoverageSchema[]>((acc, section) => {
    if (!isCustomSection(section)) return acc;

    const optionCoverageComponents = section.components.reduce<OptionalCoverageSchema[]>((acc, component) => {
      if (isOptionalCoverageComponent(component)) {
        acc.push(component);
      }

      return acc;
    }, []);

    return [...acc, ...optionCoverageComponents];
  }, []);

export const getOptionnalCoveragesKeys = (components: OptionalCoverageSchema[] = []): string[] => {
  return components.map((component) => component.key.substring(component.key.lastIndexOf('/') + 1));
};

export const isDeductibleComponent = (component?: FormComponentSchema): component is WorkflowSchema => {
  return component?.type === ComponentTypes.Deductible;
};

export const isOptionalCoverageComponent = (component?: FormComponentSchema): component is OptionalCoverageSchema => {
  return component?.type === ComponentTypes.OptionalCoverage;
};

export const isQuestionElement = (element: FormElementSchema): element is FormElementSchema<QuestionElementContent> => {
  return element.kind === FormElementType.Question;
};

export const isDisclosureElement = (element: FormElementSchema): element is FormElementSchema<DisclosureContent> => {
  return element.kind === FormElementType.Disclosure;
};

export const isHomePolicyDetailsComponent = (component?: FormComponentSchema): boolean => {
  return component?.type === ComponentTypes.HomePolicyDetails;
};

export const isAutoPolicyDetailsComponent = (component?: FormComponentSchema): boolean => {
  return component?.type === ComponentTypes.AutoPolicyDetails;
};

export const isPolicyDetailsSchema = (component?: FormComponentSchema): component is PolicySummaryDetailsSchema => {
  return component?.type === ComponentTypes.HomePolicyDetails || component?.type === ComponentTypes.AutoPolicyDetails;
};

export const getCurrentPageSection = (
  currentSectionKey: string | null = '',
  pageData?: PageResponse
): SectionSchema | undefined => {
  if (currentSectionKey && pageData) {
    return pageData.page.sections.find((section) => section.key === currentSectionKey);
  }
};

export const getDisclosuresSchema = (currentSection?: SectionSchema): DisclosureSchema[] => {
  if (currentSection && isCustomSection(currentSection) && currentSection.components?.length) {
    return currentSection.components.reduce<DisclosureSchema[]>((acc, component) => {
      if (isDisclosureComponent(component)) {
        acc.push(component);
      }

      return acc;
    }, []);
  }

  return [];
};

export const getHomePolicyEffectiveDateComponent = (
  sections: SectionSchema[] = []
): EffectiveDateSchema | undefined => {
  return sections.reduce<EffectiveDateSchema | undefined>((acc, section) => {
    if (acc ?? !isCustomSection(section)) return acc;

    return section.components.find(
      (component: FormComponentSchema) =>
        component.type === ComponentTypes.EffectiveDate && component.key === SCHEMA_KEYS.homePolicyEffectiveDay
    ) as EffectiveDateSchema;
  }, undefined);
};

export const getAutoPolicyEffectiveDateComponent = (
  sections: SectionSchema[] = []
): EffectiveDateSchema | undefined => {
  return sections.reduce<EffectiveDateSchema | undefined>((acc, section) => {
    if (acc ?? !isCustomSection(section)) return acc;

    return section.components.find(
      (component: FormComponentSchema) =>
        component.type === ComponentTypes.EffectiveDate && component.key === SCHEMA_KEYS.autoPolicyEffectiveDay
    ) as EffectiveDateSchema;
  }, undefined);
};
