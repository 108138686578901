import Highlighter from 'react-highlight-words';
import { SWITCH_VALUE } from '../../../../../questionsForm/components/Question/SwitchQuestion/SwitchInput/SwitchInput.util';
import { questionHasAnswer } from '../../../../../questionsForm/utils/answers/answers.util';
import { DATA_POINT_INTERPOLATION_REGEX } from '../../../../../questionsForm/utils/interpolation/interpolation.util.const';
import { coverageDescriptionCSS } from './QuoteOptionalCoverage.style';
import type { Answers } from '../../../../../types/answer.type';
import type { OptionalCoverageSchema } from '../../../../../types/form-component.type';
import type { PageResponse } from '../../../../../types/page.type';
import type { HomeQuoteCoverage } from '../../../../../types/quote.type';
import type { ReactElement, ReactNode } from 'react';

export const ADDED_COVERAGE = 'Added';
export const NOT_ADDED_COVERAGE = 'Not Added';

export const prepareCoverageAnswersForSubmit = (answers: Answers, components: OptionalCoverageSchema[]): Answers => {
  const questionsKeys = components.map((c) => c.key);
  return questionsKeys.reduce(
    (acc, key) => (questionHasAnswer(answers[key]) ? { ...acc, [key]: answers[key] } : acc),
    {}
  );
};

export const isPercentageCoverage = (value: unknown): boolean => typeof value === 'string';
export const isBooleanCoverage = (value: unknown): boolean =>
  typeof value === 'string' && [SWITCH_VALUE.on, SWITCH_VALUE.off].includes(value);

const HighlightCoverageDescriptionValue: React.ComponentType<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => <strong css={coverageDescriptionCSS}>{children}</strong>;

export const interpolateCoverageDescription = (
  description: string,
  coverages: HomeQuoteCoverage[]
): string | ReactElement => {
  if (coverages.length <= 0) return description;

  const replaceKey = description.match(DATA_POINT_INTERPOLATION_REGEX);
  if (!replaceKey) {
    return description;
  }

  const value = coverages.find((c) => `%data_point\x7B\x7B${c.key}\x7D\x7D` === replaceKey[0])?.value ?? '';

  return (
    <Highlighter
      searchWords={[value]}
      autoEscape={true}
      textToHighlight={description.replace(replaceKey[0], value || '')}
      highlightTag={HighlightCoverageDescriptionValue}
    />
  );
};

export const getCoverageValue = (pageData: PageResponse, question: OptionalCoverageSchema): string | undefined => {
  const value = (pageData.answers[question.key] ?? question.default_answer_value) as string | undefined;
  const calculatePercentage = (value: string, key: string): string => {
    if (key === 'quote_customization/extended_replacement_cost') {
      return `${(parseFloat(value) + 1) * 100}%`;
    }
    return `${parseFloat(value) * 100}%`;
  };
  if (value) {
    if (isBooleanCoverage(value)) {
      return SWITCH_VALUE.on === value ? ADDED_COVERAGE : NOT_ADDED_COVERAGE;
    }
    if (parseFloat(value) === 0) {
      return NOT_ADDED_COVERAGE;
    }
    return isPercentageCoverage(value) ? calculatePercentage(value, question.key) : `$${value}`;
  } else return NOT_ADDED_COVERAGE;
};
