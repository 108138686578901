import { useQueryClient } from '@tanstack/react-query';
import { useState, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMutateAnswers, usePage } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import SCHEMA_KEYS, { PAYMENT_TYPE_ESCROW_OPTION } from '../../../../../constants/schema-hardcoded-keys';
import { PaymentMethod } from '../../../../../types/public-flow-config.type';
import CheckoutBundleCreditCardStep from '../CheckoutBundleCreditCardStep/CheckoutBundleCreditCardStep';
import CheckoutBundleMortgageStep from '../CheckoutBundleMortgageStep/CheckoutBundleMortgageStep';
import { HOME_PAYMENT_METHOD_QUESTION } from '../CheckoutHomeOpportunityPage.util';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { StepTitleButtonType } from '../CheckoutStepTitle/CheckoutStepTitle.type';
import PaymentMethodSelect from '../PaymentMethodSelect/PaymentMethodSelect';
import { HOME_BILLING_DAY_QUESTION_SCHEMA, HOME_PAYMENT_TYPE_QUESTION_SCHEMA } from './CheckoutBundleHomeStep.util';
import type { CheckoutBundleHomeStepProps } from './CheckoutBundleHomeStep.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CheckoutSecondStepData } from '../CheckoutPaymentStep/CheckoutPaymentStep.type';

const CheckoutBundleHomeStep: FC<CheckoutBundleHomeStepProps> = ({
  stepNumber,
  currentStepNumber,
  stepTitle,
  maxStep,
  creditCardNumber,
  onEdit,
  onSubmit,
  setHomePaymentCreditCard,
}) => {
  const queryClient = useQueryClient();
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);
  const { data: sessionData } = useSession(gid);
  const { data: pageData } = usePage(gid, sessionData?.session.current_page_key);

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod>(
    (pageData?.answers[HOME_PAYMENT_METHOD_QUESTION.key] as PaymentMethod) ?? PaymentMethod.escrow
  );
  const [isLoading, setIsLoading] = useState(false);

  const paymentMethods = sessionFlowConfig?.flow_config?.payment_methods;
  const paymentsOptions = HOME_PAYMENT_METHOD_QUESTION.options.filter((o) => paymentMethods?.includes(o.value));

  const isFinished = maxStep > stepNumber;
  const isCurrent = currentStepNumber === stepNumber;

  const onFormSubmit = async (data?: CheckoutSecondStepData, cardNumber?: string): Promise<void> => {
    const dataToUpdate = data ?? { [SCHEMA_KEYS.billingHomePaymentType]: PAYMENT_TYPE_ESCROW_OPTION };
    const { answers } = await mutateAnswers({
      ...dataToUpdate,
      [SCHEMA_KEYS.billingHomePaymentMethod]: currentPaymentMethod,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.page, gid, sessionData?.session.current_page_key], {
      ...pageData,
      answers,
    });

    await onSubmit();

    if (cardNumber) {
      setHomePaymentCreditCard(cardNumber);
    }

    setIsLoading(false);
  };

  const onBeforeStepSubmit = (): void => {
    setIsLoading(true);
  };

  return (
    <div>
      <CheckoutStepTitle
        stepNumber={stepNumber}
        title={stepTitle}
        hideStepNumber={true}
        isFinished={isFinished && !isCurrent}
        onButtonClick={onEdit}
        buttonType={isFinished && !isCurrent ? StepTitleButtonType.Edit : null}
      />
      {paymentsOptions.length > 0 && isCurrent && (
        <PaymentMethodSelect
          title="How would you like to pay for your home insurance?"
          questionKey={HOME_PAYMENT_METHOD_QUESTION.key}
          placeholder={HOME_PAYMENT_METHOD_QUESTION.placeholder}
          options={paymentsOptions}
          onChange={(value) => setCurrentPaymentMethod(value)}
          value={currentPaymentMethod}
          isDisabled={isLoading}
        />
      )}

      {currentPaymentMethod && (
        <>
          {currentPaymentMethod === PaymentMethod.escrow ? (
            <CheckoutBundleMortgageStep
              isCurrent={isCurrent}
              isEdit={isCurrent}
              onStepSubmit={onFormSubmit}
              isSubmitting={isLoading}
              onBeforeStepSubmit={onBeforeStepSubmit}
            />
          ) : (
            <CheckoutBundleCreditCardStep
              creditCardNumber={creditCardNumber}
              isCurrent={isCurrent}
              isEdit={isCurrent}
              isSubmitting={isLoading}
              onStepSubmit={onFormSubmit}
              questions={[HOME_PAYMENT_TYPE_QUESTION_SCHEMA, HOME_BILLING_DAY_QUESTION_SCHEMA]}
              onBeforeStepSubmit={onBeforeStepSubmit}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CheckoutBundleHomeStep;
