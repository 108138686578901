import { useWatch } from 'react-hook-form';
import Question from '../../../../../questionsForm/components/Question/Question';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import { flexContainerCSS, flexInputCSS } from './AdditionalCreditCardQuestions.style';
import type { AdditionalCreditCardQuestionsProps } from './AdditionalCreditCardQuestions.type';
import type { FC } from 'react';

export const AdditionalCreditCardQuestions: FC<AdditionalCreditCardQuestionsProps> = ({ questions, isDisabled }) => {
  const answers = useWatch();

  return (
    <div css={flexContainerCSS}>
      {questions
        .filter((q) => isVisibleByAnswers(answers, q.visibility_conditions))
        .map((question) => {
          return (
            <div key={question.key} css={flexInputCSS}>
              <Question
                {...question}
                key={question.key}
                gridWidth={question.grid_width}
                questionKey={question.key}
                isDisabled={isDisabled}
              />
            </div>
          );
        })}
    </div>
  );
};
