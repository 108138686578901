import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../api/carrier';
import { usePublicContactPhoneNumber } from '../../api/public/public-contact-phone-number';
import { SEGMENT } from '../../constants/analytics';

import { useSegment } from '../../hooks/use-segment';
import Container from '../../shared/components/Container/Container';
import Footer from '../../shared/components/Footer/Footer';
import Header from '../../shared/components/Header/Header';
import isGid from '../../shared/utils/is-gid.util';
import { fullHeightPageCSS } from '../../styles/layout.style';
import { Flows } from '../../types/route-params.type';
import ScheduleCallPage from './components/ScheduleCallPage/ScheduleCallPage';
import type { Carrier } from '../../types/carrier.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const ScheduleCallLayout: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const carrierQuery = useSelectedCarrier<Carrier | null>(gid, isGid(gid));
  const { data: phoneData } = usePublicContactPhoneNumber(gid);

  const { page, track } = useSegment();
  useEffect(() => {
    page(SEGMENT.pages.scheduleCall.name);
  }, [page]);

  const onPhoneClick = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: SEGMENT.pages.scheduleCall.name,
      location,
    });
  };

  return (
    <div css={fullHeightPageCSS}>
      <Header
        phoneNumber={phoneData?.contact_phone_number?.number}
        carrierName={carrierQuery.data?.carrier?.name}
        carrierLogo={carrierQuery.data?.carrier?.logo_url}
        onPhoneClick={() => onPhoneClick(SEGMENT.locations.header)}
        reverseLogo={flow === Flows.Auto}
        showContacts
        denseContainer
      />
      <main>
        <Container dense>
          <ScheduleCallPage />
        </Container>
      </main>
      <Footer
        phone={phoneData?.contact_phone_number?.number}
        denseContainer
        onSocialLinkClick={() => onPhoneClick(SEGMENT.locations.footer)}
      />
    </div>
  );
};

export default ScheduleCallLayout;
