import QuestionLabel from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import Select from '../../../../../shared/components/Select/Select';
import { labelCSS, paymentMethodCSS } from './PaymentMethodSelect.style';
import type { PaymentMethodSelectProps } from './PaymentMethodSelect.type';
import type { PaymentMethod } from '../../../../../types/public-flow-config.type';
import type { FC } from 'react';

const PaymentMethodSelect: FC<PaymentMethodSelectProps> = ({
  value,
  questionKey,
  placeholder,
  title,
  options,
  infoBlockText,
  isDisabled,
  onChange,
}) => (
  <div css={paymentMethodCSS(isDisabled)}>
    <QuestionLabel customCSS={labelCSS} keyName={questionKey}>
      {title}
    </QuestionLabel>
    <Select
      inputId={questionKey}
      options={options}
      placeholder={placeholder}
      value={value}
      onValidEntry={(val) => onChange(val as PaymentMethod)}
      infoBlockText={infoBlockText}
      isDisabled={isDisabled}
    />
  </div>
);

export default PaymentMethodSelect;
