import type { Loan } from '../../../../../types/loan.type';
import type { Person } from '../../../../../types/person.type';

export const hasFullLoanAndPersonData = (loan?: Loan, primaryInsured?: Person): boolean => {
  return Boolean(
    loan?.loan_number &&
      loan.servicer?.gid &&
      primaryInsured?.email &&
      primaryInsured.phone &&
      primaryInsured.mailing_address?.gid
  );
};
