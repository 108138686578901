import { Fragment } from 'react';
import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import { getVisibleComponents } from '../../../../../questionsForm/utils/questions-form.util';
import type { Answers, AnswerValue } from '../../../../../types/answer.type';
import type { FormComponentSchema } from '../../../../../types/form-component.type';
import type { AutoQuoteCoverage } from '../../../../../types/quote.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { ReactNode } from 'react';

export const prepareAnswersToSubmit = (
  answers: Answers,
  defaultAnswers?: Answers,
  sections?: CustomSectionSchema[]
): Record<string, AnswerValue> => {
  const visibleComponents = sections?.reduce<FormComponentSchema[]>((acc, section) => {
    return [...acc, ...getVisibleComponents(section.components, { ...defaultAnswers })];
  }, []);

  const autoDeductibleCustomizationKeys = Object.keys(answers)
    .filter((key) =>
      [SCHEMA_KEYS.autoPolicyComprehensive, SCHEMA_KEYS.autoPolicyCollision].some((str) => key.includes(str))
    )
    .map((key) => ({
      key,
    }));

  const resultObject: Answers = {};

  visibleComponents?.forEach((component) => {
    resultObject[component.key] = answers[component.key];
  });

  autoDeductibleCustomizationKeys?.forEach((autoDeductible) => {
    resultObject[autoDeductible.key] = answers[autoDeductible.key];
  });

  return resultObject;
};

export const getAutoCoveragesDefaultValues = (coverages: AutoQuoteCoverage[]): [] | Answers[] => {
  const coveragesToUpdate: Answers[] = [];

  const coveragesToDefault = coverages?.filter((coverage) => {
    return [SCHEMA_KEYS.collision, SCHEMA_KEYS.comprehensive].includes(coverage?.key as string);
  });

  coveragesToDefault?.forEach((coverage) => {
    coveragesToUpdate?.push({
      key: `${SCHEMA_KEYS.autoPolicyPrefix}${coverage?.key}` + '_' + `${coverage?.asset_gid}`,
      value: coverage?.value ?? '',
    });
  });

  return coveragesToUpdate;
};

export const bundleDisclaimerText: ReactNode = (
  <Fragment>
    State specific rules apply. <b>Documentation may be required</b> after purchase to maintain discount.
  </Fragment>
);
