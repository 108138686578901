import { useState } from 'react';
import noop from '../../../utils/noop';
import CheckboxIcon from '../../Checkbox/CheckboxIcon/CheckboxIcon';
import { containerCSS } from '../CounterInput.style';
import { CounterInputVariant } from '../CounterInput.type';
import { checkboxIconCSS, checkboxInputWrapperCSS, labelTextCSS } from './CounterInputWithCheckbox.style';
import type { CounterInputWithCheckboxProps } from './CounterInputWithCheckbox.types';
import type { FC } from 'react';

const CounterInputWithCheckbox: FC<CounterInputWithCheckboxProps> = ({
  inputId,
  iconUrl,
  label,
  disabled,
  hasError,
  isFocused,
  inputElement,
  onToggleCheckbox = noop,
  onFocus,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheckboxCheck = (prevValue: boolean): void => {
    if (disabled) return;
    setIsChecked((prevState) => !prevState);
    onFocus(true);

    onToggleCheckbox(!prevValue);
  };

  return (
    <label htmlFor={inputId}>
      <div
        role="presentation"
        css={containerCSS(CounterInputVariant.WithCheckbox, hasError, isFocused, isChecked)}
        onClick={() => onCheckboxCheck(isChecked)}
      >
        <div>
          <CheckboxIcon checked={isChecked} customCSS={checkboxIconCSS} disabled={disabled} hasError={hasError} />
          <img data-testid="checkbox-image" src={iconUrl} aria-hidden alt="" />
          <p css={labelTextCSS}>{label}</p>
        </div>
        {isChecked && (
          <div
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
            }}
            css={checkboxInputWrapperCSS}
          >
            {inputElement}
          </div>
        )}
      </div>
    </label>
  );
};

export default CounterInputWithCheckbox;
