import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import type { SerializedStyles } from '@emotion/react';

export const paymentMethodCSS = (isDisabled = false): SerializedStyles => css`
  margin-bottom: 16px;

  ${mq[0]} {
    margin-bottom: 12px;
  }
  ${isDisabled &&
  css`
    opacity: 0.5;
  `}
`;

export const labelCSS = css`
  margin-top: 10px;
  margin-bottom: 4px;
`;
