const QUERY_CACHE_KEYS = {
  session: 'session',
  sessionConfig: 'sessionConfig',
  carrier: 'carrier',
  page: 'page',
  quoteRequest: 'quoteRequest',
  quoteRequestNoQuotes: 'quoteRequestNoQuotes',
  quotesRequestsList: 'quotesRequestList',
  config: 'config',
  coInsureds: 'coInsureds',
  loan: 'loan',
  servicers: 'servicers',
  primaryInsured: 'primaryInsured',
  initialQuote: 'initialQuote',
  featureFlags: 'featureFlags',
  allFunnelUrl: 'allFunnelUrl',
  vehiclesAndDriversData: 'vehicleAndDriversData',
  vehicles: 'vehicles',
  publicSession: 'publicSession',
  publicPrimaryInsured: 'publicPrimaryInsured',
  publicFlowConfig: 'publicFlowConfig',
  publicErrors: 'publicErrors',
  drivers: 'drivers',
  effectivePolicy: 'effectivePolicy',
  contactFlows: 'contactFlows',
  acceptedDisclosures: 'acceptedDisclosures',
  engagement: 'engagement',
  publicCustomerJourney: 'publicCustomerJourney',
  contactPhoneNumber: 'contactPhoneNumber',
};

export default QUERY_CACHE_KEYS;
