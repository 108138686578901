import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';

export const headerCSS = css`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${theme.colors.gray5};

  ${mq[1]} {
    padding: 20px 0;
  }
`;

export const dividerCSS = css`
  margin: 0 13px;
`;
