import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Question from '../../../../../questionsForm/components/Question/Question';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import noop from '../../../../../shared/utils/noop';
import { FormElementTag } from '../../../../../types/form-element.type';
import CheckoutContactInfoQuestions from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions';
import {
  PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA,
  PERSONAL_INFO_EMAIL_QUESTION_SCHEMA,
  PERSONAL_INFO_PHONE_QUESTION_SCHEMA,
} from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions.util';
import { buttonCSS, formCSS, formInputCSS } from './CheckoutMortgageForm.style';
import { LOAN_QUESTION_SCHEMA, SERVICER_QUESTION_SCHEMA } from './CheckoutMortgageForm.util';
import type { CheckoutMortgageFormProps } from './CheckoutMortgageForm.type';
import type { CheckoutMortgageContactFormState } from '../CheckoutMortgageStep/CheckoutMortgageStep.type';
import type { FC } from 'react';

const CheckoutMortgageForm: FC<CheckoutMortgageFormProps> = ({
  customCSS,
  loan,
  primaryInsured,
  servicersOptions = [],
  actionLabel,
  isBindDisabled,
  isSubmitting = false,
  onValidSubmit = noop,
}) => {
  const { gid, ...addressAnswer } = primaryInsured?.mailing_address ?? {};
  const methods = useForm<CheckoutMortgageContactFormState>({
    defaultValues: {
      [SERVICER_QUESTION_SCHEMA.questionKey]: loan?.servicer?.gid,
      [LOAN_QUESTION_SCHEMA.questionKey]: loan?.loan_number,
      [PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey]: primaryInsured?.email,
      [PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey]: primaryInsured?.phone,
      [PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey]: addressAnswer,
    },
  });

  useEffect(() => {
    if (loan ?? primaryInsured) {
      const { gid, ...addressAnswer } = primaryInsured?.mailing_address ?? {};
      methods.reset({
        [SERVICER_QUESTION_SCHEMA.questionKey]: loan?.servicer?.gid,
        [LOAN_QUESTION_SCHEMA.questionKey]: loan?.loan_number,
        [PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey]: primaryInsured?.email,
        [PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey]: primaryInsured?.phone,
        [PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey]: addressAnswer,
      });
    }
  }, [methods, loan, primaryInsured]);

  const onSubmit = async (data: CheckoutMortgageContactFormState): Promise<void> => {
    await onValidSubmit(data);
  };

  return (
    <div css={customCSS}>
      <FormProvider {...methods}>
        <form id="loan-form" css={formCSS} onSubmit={methods.handleSubmit(onSubmit, scrollToError)}>
          <div css={formInputCSS}>
            <Question
              {...SERVICER_QUESTION_SCHEMA}
              key={SERVICER_QUESTION_SCHEMA.questionKey}
              isDisabled={isSubmitting}
              variants={servicersOptions}
            />
          </div>
          <div css={formInputCSS}>
            <Question
              {...LOAN_QUESTION_SCHEMA}
              key={LOAN_QUESTION_SCHEMA.questionKey}
              isDisabled={isSubmitting}
              tags={[FormElementTag.TrackForbidden]}
            />
          </div>
          <CheckoutContactInfoQuestions isDisabled={isSubmitting} />
        </form>
      </FormProvider>

      <Button
        customCSS={buttonCSS}
        type="submit"
        form="loan-form"
        fullWidth
        size={ButtonSize.Large}
        isLoading={isSubmitting}
        loadingText="Submitting"
        disabled={isBindDisabled}
      >
        {actionLabel}
      </Button>
      {import.meta.env.DEV && <DevTool control={methods.control} />}
    </div>
  );
};

export default CheckoutMortgageForm;
