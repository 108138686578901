export interface IObject {
  [key: string]: string | number | boolean | IObject | undefined | null | string[] | [];
}

export const compactObject = (val: IObject): IObject => {
  return Object.keys(val).reduce((acc: IObject, key) => {
    const value = val[key];
    const isEmpty =
      value === null ||
      value === undefined ||
      value === false ||
      value === '' ||
      (Array.isArray(value) && value.length === 0);

    if (isEmpty) {
      return acc;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      const compacted = compactObject(value as IObject);
      acc[key] = compacted;
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const isObjectEmpty = (value: Record<string, unknown>): boolean => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};
