import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const flexContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const flexInputCSS = css`
  flex-basis: 100%;

  ${mq[0]} {
    flex-basis: 50%;
  }
`;
