import formatUtil from '../../../../utils/format.util';
import type { Address } from '../../../../types/address.type';
import type { HomeInitialQuote, HomeQuote } from '../../../../types/quote.type';
import type { ReactElement } from 'react';

export const QUOTE_PAGE_DISCLAIMER =
  'The coverage descriptions provided above are for informational purposes only. Coverage limits, deductibles, and ' +
  'certain exclusions may apply. Refer to your policy for the details.';

export const BUNDLE_BIND_QUOTE_PAGE_DISCLAIMER =
  'The coverage descriptions provided above are for informational purposes only. Coverage limits, deductibles, and ' +
  'certain exclusions may apply. Every homeowner is responsible for evaluating their own coverage needs to ensure ' +
  'adequate coverage in the event of a claim. Refer to your policy for the details.';

export const getPageHeadingText = (
  isDynamicFlow: boolean,
  quote?: HomeQuote,
  initialQuote?: HomeInitialQuote,
  originalRC2Quote?: HomeQuote | null,
  address?: Address
): { heading: string; subheading?: string | ReactElement } => {
  if (isDynamicFlow) {
    return !initialQuote || quote?.carrier_key === initialQuote?.carrier_key
      ? {
          heading: 'Your quote is ready!',
          subheading: address ? formatUtil.address(address) : '',
        }
      : {
          heading: originalRC2Quote ? 'We’ve found another option!' : 'Your quote is ready!',
          subheading: originalRC2Quote
            ? `This carrier’s final quote is a lower price than ${initialQuote?.carrier_name}` +
              ` final quote of $${formatUtil.money(initialQuote?.premium)}.`
            : `Based on the details of your home, the carrier you selected is unable to provide coverage. ` +
              `We found another option for you with ${quote?.carrier_name}.`,
        };
  }

  if (quote?.carrier_key === initialQuote?.carrier_key) {
    return {
      heading: 'Your quote is ready!',
      subheading: address ? formatUtil.address(address) : '',
    };
  }

  return {
    heading: 'We’ve found another option!',
    subheading:
      `This carrier's final quote is a lower price than ${initialQuote?.carrier_name}` +
      ` final quote of $${formatUtil.money(initialQuote?.premium || 0)}.`,
  };
};
