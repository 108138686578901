import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { globalCSS } from './App.style';
import { usePublicConfig } from './api/publicConfig';
import GoogleReCaptchaWrapper from './components/GoogleReCaptchaWrapper/GoogleReCaptchaWrapper';
import PageTypeCheckGuard from './components/PageTypeCheckGuard/PageTypeCheckGuard';
import SessionInit from './components/SessionInit/SessionInit';
import SessionLockGuard from './components/SessionLockGuard/SessionLockGuard';
import SessionPath from './components/SessionPath/SessionPath';
import ROUTES from './constants/routes';
import useAnalytics from './hooks/use-analytics';
import AutoInformationLayout from './pages/auto-information';
import CheckoutLayout from './pages/checkout';
import CongratulationsLayout from './pages/congratulations';
import DiscountsLayout from './pages/discounts';
import ErrorLayout from './pages/error';
import ErrorFallback from './pages/error/components/ErrorFallback/ErrorFallback';
import ErrorFallbackContent from './pages/error/components/ErrorFallbackContent/ErrorFallbackContent';
import ErrorAgentLayout from './pages/error-agent';
import ErrorDisqualifiedLayout from './pages/error-disqualified/index';
import PolicySoldLayout from './pages/policy-sold';
import QuestionsLayout from './pages/questions';
import QuoteLayout from './pages/quote';
import ScheduleCallLayout from './pages/schedule-call';
import SessionLockedLayout from './pages/session-locked';
import ScrollToTop from './shared/components/ScrollToTop/ScrollToTop';
import { PageTypes } from './types/page.type';
import type { FallbackRender } from '@sentry/react';
import type { FC } from 'react';

const errorFallback: FallbackRender = ({ error, resetError }) => (
  <ErrorFallback error={error} resetError={resetError} />
);

const App: FC = () => {
  const configQuery = usePublicConfig(false);
  const { reset } = useQueryErrorResetBoundary();
  useAnalytics(configQuery.data?.config.integrations);

  const reloadPage = (): void => {
    window.location.reload();
  };

  if (!configQuery.data) {
    return null;
  }

  if (configQuery.isError) {
    return (
      <Fragment>
        <Global styles={globalCSS} />
        <ErrorFallbackContent resetError={reloadPage} />
      </Fragment>
    );
  }
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Global styles={globalCSS} />
      <Sentry.ErrorBoundary onReset={reset} fallback={errorFallback}>
        <Routes>
          {/* TODO: Split routes to chunks */}
          <Route path={ROUTES.init} element={<SessionInit />} />
          <Route path={ROUTES.flowAndGid} element={<SessionPath />} />
          <Route
            path={ROUTES.questions}
            element={
              <GoogleReCaptchaWrapper>
                <SessionLockGuard>
                  <PageTypeCheckGuard pageType={PageTypes.QuestionForm}>
                    <QuestionsLayout />
                  </PageTypeCheckGuard>
                </SessionLockGuard>
              </GoogleReCaptchaWrapper>
            }
          />
          <Route
            path={ROUTES.autoInformation}
            element={
              <SessionLockGuard>
                <PageTypeCheckGuard pageType={PageTypes.AutoInformationForm}>
                  <AutoInformationLayout />
                </PageTypeCheckGuard>
              </SessionLockGuard>
            }
          />
          <Route
            path={ROUTES.discounts}
            element={
              <SessionLockGuard>
                <PageTypeCheckGuard pageType={PageTypes.Discounts}>
                  <DiscountsLayout />
                </PageTypeCheckGuard>
              </SessionLockGuard>
            }
          />
          <Route
            path={ROUTES.quote}
            element={
              <SessionLockGuard>
                <PageTypeCheckGuard pageType={PageTypes.QuoteCustomization}>
                  <QuoteLayout />
                </PageTypeCheckGuard>
              </SessionLockGuard>
            }
          />
          <Route
            path={ROUTES.checkout}
            element={
              <SessionLockGuard>
                <PageTypeCheckGuard pageType={PageTypes.Billing}>
                  <CheckoutLayout />
                </PageTypeCheckGuard>
              </SessionLockGuard>
            }
          />
          <Route
            path={ROUTES.congratulations}
            element={
              <SessionLockGuard>
                <PageTypeCheckGuard pageType={PageTypes.Congratulations}>
                  <CongratulationsLayout />
                </PageTypeCheckGuard>
              </SessionLockGuard>
            }
          />
          <Route path={ROUTES.sessionScheduleCall} element={<ScheduleCallLayout />} />
          <Route path={ROUTES.scheduleCall} element={<ScheduleCallLayout />} />
          <Route path={ROUTES.sessionLocked} element={<SessionLockedLayout />} />
          <Route path={ROUTES.policySold} element={<PolicySoldLayout />} />
          <Route path={ROUTES.agent} element={<ErrorAgentLayout />} />
          <Route path={ROUTES.errorDisqualified} element={<ErrorDisqualifiedLayout />} />

          <Route path={ROUTES.problem} element={<ErrorLayout />} />
          <Route path={ROUTES.invalid} element={<ErrorLayout />} />
        </Routes>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
