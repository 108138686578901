import { useState, type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DisclosureComponent from '../../../../../questionsForm/components/DisclosureComponent/DisclosureComponent';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { buttonCSS, disclosureCSS } from './CheckoutBundleBindStep.style';
import type { CheckoutBundleBindStepProp } from './CheckoutBundleBindStep.type';

const CheckoutBundleBindStep: FC<CheckoutBundleBindStepProp> = ({
  stepNumber,
  currentStepNumber,
  disclosures,
  onSubmit,
}) => {
  const isCurrent = currentStepNumber === stepNumber;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();

  const onFormSubmit = async (data: Record<string, unknown>): Promise<void> => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  return (
    <div>
      <CheckoutStepTitle
        hideStepNumber
        title={'Checkout'}
        isDisabled={currentStepNumber !== stepNumber}
        stepNumber={stepNumber}
      />
      {isCurrent && (
        <FormProvider {...methods}>
          <form id="bind-checkbox-form" onSubmit={methods.handleSubmit(onFormSubmit)}>
            {disclosures.map((d) => (
              <DisclosureComponent
                customCSS={disclosureCSS}
                key={d.key}
                componentKey={d.key}
                content={d.content}
                disclosureGid={d.disclosure_gid}
                validations={d.validations}
                isLineHidden
              />
            ))}
            <Button
              type="submit"
              form="bind-checkbox-form"
              css={buttonCSS}
              fullWidth
              size={ButtonSize.Large}
              isLoading={isLoading}
            >
              Complete Payment
            </Button>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default CheckoutBundleBindStep;
