import { format } from 'date-fns';
import { useState } from 'react';
import useResponsive from '../../../../../hooks/use-responsive';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import { Card } from '../../../../../shared/components/Card/Card';
import { noopAsync } from '../../../../../shared/utils/noop';
import AutoQuoteCoverages from '../AutoQuoteCoverages/AutoQuoteCoverages';
import {
  buttonContainerCSS,
  buttonCSS,
  cardCSS,
  coveragesCSS,
  effectiveDateCSS,
  priceCSS,
  quoteDurationCSS,
} from './AutoPolicyDetails.style';
import type { AutoPolicyDetailsProps } from './AutoPolicyDetails.type';
import type { FC } from 'react';

const AutoPolicyDetails: FC<AutoPolicyDetailsProps> = ({
  customCSS,
  premium,
  policyTermInMonths,
  effectiveDate,
  coverages,
  assets,
  onSubmit = noopAsync,
}) => {
  const { isMobile } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);

  const period = policyTermInMonths === 12 ? '1 Year' : `${policyTermInMonths} Months`;

  const onClick = async (): Promise<void> => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  return (
    <div css={customCSS}>
      <Card customCSS={cardCSS}>
        <div css={buttonContainerCSS}>
          <div css={priceCSS}>{premium}</div>
          <div css={quoteDurationCSS}>For {period}</div>
          <Button
            customCSS={buttonCSS}
            size={isMobile ? ButtonSize.Normal : ButtonSize.Large}
            isLoading={isLoading}
            onClick={onClick}
          >
            Check Out
          </Button>
          <div css={effectiveDateCSS}>
            Policy Effective Date: <strong>{format(new Date(effectiveDate), 'M/d/yyyy')}</strong>
          </div>
        </div>

        {!isMobile && <AutoQuoteCoverages customCSS={coveragesCSS} coverages={coverages} assets={assets} />}
      </Card>

      {isMobile && <AutoQuoteCoverages customCSS={coveragesCSS} coverages={coverages} assets={assets} />}
    </div>
  );
};

export default AutoPolicyDetails;
