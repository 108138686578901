import { Global } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState, type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { usePage } from '../../../../../api/page';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { usePublicConfig } from '../../../../../api/publicConfig';
import {
  useCreateQuotesRequest,
  useInitialQuote,
  useLastQuoteRequest,
  useMutateQuote,
  useQuoteRequestsSubscription,
} from '../../../../../api/quote';
import { useMutateSession } from '../../../../../api/session';
import useSession from '../../../../../api/session/use-session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import QuotePageLoader from '../../../../../components/QuotePageLoader/QuotePageLoader';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import { useSegment } from '../../../../../hooks/use-segment';
import { prepareCustomAnswers } from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import { Card } from '../../../../../shared/components/Card/Card';
import { hideFooterCSS } from '../../../../../shared/shared.style';
import { innerLayoutContainerCSS } from '../../../../../styles/layout.style';
import { PolicyType } from '../../../../../types/policy.type';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import {
  HOME_POLICY_TYPES,
  type AutoQuote,
  type DigitalProfileQuote,
  type HomeInitialQuote,
  type HomeQuote,
} from '../../../../../types/quote.type';
import {
  getCurrentNavigationPage,
  getCurrentSectionKey,
  getNextNavigationKeys,
} from '../../../../../utils/current-form-keys.util';
import { getPrimaryQuote } from '../../../../../utils/quote.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import {
  getAutoPolicyEffectiveDateComponent,
  getDeductibleComponent,
  getHomePolicyEffectiveDateComponent,
} from '../../../../../utils/schema.util';
import BundleQuoteCoverages from '../BundleQuoteCoverages/BundleQuoteCoverages';
import BundleQuoteDetails from '../BundleQuoteDetails/BundleQuoteDetails';
import ConfirmationQuoteModal from '../ConfirmationQuoteModal/ConfirmationQuoteModal';
import { getPageHeadingText, BUNDLE_BIND_QUOTE_PAGE_DISCLAIMER } from '../HomeQuoteOpportunityPage.util';
import HomeQuotePageHeading from '../HomeQuotePageHeading/HomeQuotePageHeading';
import RecalculateQuoteButton from '../RecalculateQuoteButton/RecalculateQuoteButton';
import WaitingLoadingModal from '../WaitingLoadingModal/WaitingLoadingModal';
import {
  containerCSS,
  cardCSS,
  disclaimerCSS,
  wrapperCSS,
  loaderCSS,
  loaderImageCSS,
} from './BundleQuotePageContent.style';
import {
  getAutoCoveragesDefaultValues,
  prepareAnswersToSubmit,
  bundleDisclaimerText,
} from './BundleQuotePageContent.util';
import type { BundleQuotePageContentProps } from './BundleQuotePageContent.type';
import type { Address } from '../../../../../types/address.type';
import type { Answers } from '../../../../../types/answer.type';
import type { Carrier } from '../../../../../types/carrier.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { SessionResponse } from '../../../../../types/session.type';

const BundleQuotePageContent: FC<BundleQuotePageContentProps> = ({ quotesGroup, customCSS }) => {
  const queryClient = useQueryClient();

  const { gid, flow } = useParams() as DefaultLocation;
  const { data: initialQuoteData } = useInitialQuote<HomeInitialQuote>(gid);
  const { data: lastQuoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key ?? '';
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPageKey);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { data: carrierData } = useSelectedCarrier<Carrier>(gid);
  const { data: initialQuote } = useInitialQuote<DigitalProfileQuote>(gid);
  const { data: homeEffectivePolicy } = useEffectivePolicy(gid);
  const { data: configData } = usePublicConfig();
  const { mutateAsync: mutateSession } = useMutateSession(gid);

  const finalQuoteViewedRef = useRef(false);

  const webSocketUrl = configData?.config.websocket_url;
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest(gid);
  const { mutateAsync: updateQuote } = useMutateQuote(gid);

  const [isQuoteUpdating, setIsQuoteUpdating] = useState(false);
  const [showUpdateQuote, setShowUpdateQuote] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { track } = useSegment();
  const navigate = useNavigate();

  useEffect(() => {
    const call = async (): Promise<void> => {
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

      // Refetching the session to update session lock timer
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.page, gid] });
      setIsQuoteUpdating(false);
    };

    if (isQuoteUpdating && isFinished) {
      call();
    }
  }, [isQuoteUpdating, isFinished, queryClient, gid]);

  const methods = useForm();

  const quotes = quotesGroup?.quotes;
  const homeQuote = quotes?.find((q) => HOME_POLICY_TYPES.includes(q.policy_type)) as HomeQuote;
  const autoQuote = quotes?.find((q) => q.policy_type === PolicyType.Auto) as AutoQuote;
  const originalHomeRC2Quote = getPrimaryQuote<HomeQuote>(
    lastQuoteRequestData?.quotes_request?.groups[0]?.quotes ?? [],
    sessionFlowConfig?.flow_config.policy_type,
    initialQuoteData?.quote?.carrier_key
  );

  useEffect(() => {
    if (!finalQuoteViewedRef.current) {
      finalQuoteViewedRef.current = true;
      track(SEGMENT.events.finalQuoteViewed, {
        quotes: [homeQuote?.gid, autoQuote?.gid],
        carrier_key: sessionFlowConfig?.flow_config?.carrier_key,
        config_type: sessionFlowConfig?.flow_config?.config_type,
      });
    }
  }, [track, homeQuote?.gid, autoQuote?.gid, sessionFlowConfig]);

  const address = (pageData?.answers?.property_address as Address) || primaryInsuredData?.person.mailing_address;

  const pageHeading = getPageHeadingText(
    sessionFlowConfig?.flow_config.config_type === ConfigType.Dynamic,
    homeQuote,
    initialQuoteData?.quote,
    originalHomeRC2Quote,
    address
  );

  useEffect(() => {
    if (isQuoteUpdating) {
      return;
    }
    if (methods.formState.isDirty && !showUpdateQuote) {
      track(SEGMENT.events.updateQuoteNotificationShown);
      setShowUpdateQuote(true);
    }
    if (!methods.formState.isDirty && showUpdateQuote) {
      setShowUpdateQuote(false);
    }
  }, [methods.formState.isDirty, showUpdateQuote, isQuoteUpdating, track]);

  useEffect(() => {
    if (pageData?.answers) {
      const deductibleComponent = getDeductibleComponent(pageData.page.sections);
      const homeEffectiveDateComponent = getHomePolicyEffectiveDateComponent(pageData.page.sections);
      const autoEffectiveDateComponent = getAutoPolicyEffectiveDateComponent(pageData.page.sections);
      const defaultAnswers = prepareCustomAnswers(pageData);
      const autoCoveragesDefaultValues = getAutoCoveragesDefaultValues(autoQuote?.coverages);

      if (deductibleComponent && homeEffectiveDateComponent && autoEffectiveDateComponent) {
        defaultAnswers[deductibleComponent.key] = homeQuote.deductible;
        defaultAnswers[homeEffectiveDateComponent.key] = homeQuote.policy_effective_date ?? '';
        defaultAnswers[autoEffectiveDateComponent?.key] = autoQuote.policy_effective_date ?? '';
      }

      if (autoCoveragesDefaultValues.length) {
        autoCoveragesDefaultValues.forEach((coverage: Answers) => {
          // If there is no default answer, set default value from auto quote coverage
          if (!defaultAnswers[coverage.key as string]) {
            defaultAnswers[coverage.key as string] = coverage.value;
          }
        });
      }

      methods.reset(defaultAnswers, { keepDirty: false, keepTouched: true });
    }
  }, [methods, pageData, homeQuote, autoQuote]);

  const triggerConfirmationModal = (value: boolean): void => {
    value && track(SEGMENT.events.additionalDiscountsModalShown);
    setShowConfirmationModal(value);
  };

  const handleQuoteUpdate = async (answers: Answers): Promise<void> => {
    track(SEGMENT.events.updateQuoteClicked);
    const answersToSubmit = prepareAnswersToSubmit(answers, pageData?.answers, pageData?.page.sections);

    setIsQuoteUpdating(true);
    setShowUpdateQuote(false);

    await updateQuote({ ...answersToSubmit });
    startSubscription();
    await createQuotesRequest({ pageKey: currentPageKey });
  };

  const onFinalizeCheckoutClicked = (): void => {
    if (showUpdateQuote) {
      methods.handleSubmit(handleQuoteUpdate)();
    } else {
      triggerConfirmationModal(true);
      track(SEGMENT.events.finalizedCheckoutClicked);
    }
  };

  const onConfirmationSubmit = async (): Promise<void> => {
    if (carrierData) {
      track(SEGMENT.events.quoteSelected, {
        ordinal: 1,
        carrier_key: carrierData.carrier.key,
      });
    }

    await createQuotesRequest({ pageKey: currentPageKey });
    await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });

    navigateToCheckout();
  };

  const navigateToCheckout = async (): Promise<void> => {
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
      completed_section_key: getCurrentSectionKey(sessionData as SessionResponse),
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  if (!quotes || !initialQuote || !homeEffectivePolicy || !pageData) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} customImageCSS={loaderImageCSS} imgUrl={SIRV_IMAGES.homeLoader} />
      </>
    );
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={[innerLayoutContainerCSS(false), wrapperCSS]}>
        <HomeQuotePageHeading heading={pageHeading.heading} subheading={pageHeading.subheading} />
        {showUpdateQuote && <RecalculateQuoteButton onUpdate={methods.handleSubmit(handleQuoteUpdate)} />}
        <div>
          <Card customCSS={cardCSS}>
            <FormProvider {...methods}>
              <form id="quote-details-form">
                <BundleQuoteDetails
                  sections={pageData?.page?.sections}
                  homeEffectivePolicy={homeEffectivePolicy.effective_policy}
                  quotes={quotes}
                  quotesGroup={quotesGroup}
                  onFinalizeCheckoutClicked={onFinalizeCheckoutClicked}
                />
                <BundleQuoteCoverages autoQuote={autoQuote} homeQuote={homeQuote} address={address} />
              </form>
            </FormProvider>

            <ConfirmationQuoteModal
              isExtendedDimensions
              isOpen={showConfirmationModal}
              onSubmit={onConfirmationSubmit}
              toggleModal={triggerConfirmationModal}
              disclaimer={bundleDisclaimerText}
            />
          </Card>
        </div>
        <div css={disclaimerCSS}>{BUNDLE_BIND_QUOTE_PAGE_DISCLAIMER}</div>
        <WaitingLoadingModal
          title="Updating information..."
          description="This might take up to 30 seconds"
          open={isQuoteUpdating}
        />
      </div>
    </div>
  );
};

export default BundleQuotePageContent;
