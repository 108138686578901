import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const coveragesCSS = css`
  padding: 0 24px;

  ${mq[0]} {
    padding: 0 40px;
  }
`;

export const coveragesContainerCSS = css`
  padding-top: 20px;
  padding-bottom: 32px;

  ${mq[0]} {
    padding-top: 16px;
    padding-bottom: 32px;
  }
`;

export const buttonCSS = (isActive: boolean): SerializedStyles => css`
  background-color: transparent;
  border: none;
  color: ${theme.colors.black};
  border-radius: 0;
  padding: 0;

  &:first-of-type {
    margin-right: 31px;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: inherit;
    color: inherit;
  }
  ${isActive &&
  css`
    box-shadow: inset 0 -4px 0 0 ${theme.colors.azure50};
  `}
`;

export const policyCoveragesTypeCSS = css`
  display: flex;
  justify-content: start;
  height: 60px;
  background-color: ${theme.colors.gray5};
  padding: 0 24px;

  ${mq[0]} {
    padding: 0 40px;
  }
`;

export const coveragesInsideTabsCSS = css`
  margin-top: 12px;
  ${mq[0]} {
    margin-top: 8px;
  }
`;

export const tabsContainerCSS = css`
  margin-top: 12px;
  ${mq[0]} {
    margin-top: 16px;
  }
`;

export const autoCoveragesCSS = css`
  ${coveragesCSS};
  padding-top: 20px;

  ${mq[0]} {
    padding-top: 20px;
  }
`;
