import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text20CSS, text24CSS } from '../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  margin-bottom: 45px;

  ${mq[0]} {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  ${mq[1]} {
    width: 90%;
  }
`;

export const headerCSS = css`
  padding: 16px 24px;
  background-color: ${theme.colors.azure95};
  ${text20CSS};
  font-weight: 700;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: left;
  ${mq[0]} {
    ${text24CSS};
    justify-content: center;
  }
`;

export const logoCSS = css`
  img {
    max-height: 40px;
  }
`;

export const headerDividerCSS = css`
  content: '';
  height: 20px;
  border-left: 2px solid ${theme.colors.gray60};
  margin-right: 16px;
  margin-left: 16px;
`;

export const contentCSS = (isBundle: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.gray60};

  ${mq[0]} {
    ${text20CSS};
    flex-direction: row;
    justify-content: space-around;
  }
  ${isBundle &&
  css`
    :first-of-type {
      margin-bottom: 24px;
    }
  `}
`;

export const contentItemCSS = css`
  margin-bottom: 24px;

  ${mq[0]} {
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const dashedRowCSS = css`
  color: ${theme.colors.gray80};
  & span {
    padding-right: 6px;
    display: flex;
    align-items: end;
  }
  & span:first-of-type {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: ${theme.colors.gray80};
  }
`;

export const carrierNameCSS = css`
  text-transform: capitalize;
`;

export const unknownPolicyNumberCSS = css`
  margin-left: 5px;
  padding: 0;
  background-color: transparent;
`;

export const policiesContentCSS = css`
  padding: 12px 24px 0;

  ${mq[0]} {
    padding: 20px 40px 40px;
  }
`;

export const iconCSS = css`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;
