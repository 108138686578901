import { Global } from '@emotion/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuotesRequestSubscription } from '../../../../api/quote';
import { useSession } from '../../../../api/session';
import { useSessionFlowConfig } from '../../../../api/session-flow-config';
import QuotePageLoader from '../../../../components/QuotePageLoader/QuotePageLoader';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { hideFooterCSS } from '../../../../shared/shared.style';
import { isCurrentKnockOutSection } from '../../../../utils/navigation.util';
import { isBundleQuotesGroupBindable } from '../../../../utils/quote.util';
import { resetScroll } from '../../../../utils/scroll.util';
import CheckoutBundle from './CheckoutBundle/CheckoutBundle';
import CheckoutHome from './CheckoutHome/CheckoutHome';
import { containerCSS, loaderCSS, loaderImageCSS } from './CheckoutHomeOpportunityPage.style';
import KnockoutQuestionsForm from './KnockoutQuestionsForm/KnockoutQuestionsForm';
import type { CheckoutHomeOpportunityPageProps } from './CheckoutHomeOpportunityPage.type';
import type { HomeQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const CheckoutHomeOpportunityPage: FC<CheckoutHomeOpportunityPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const { data: configData } = usePublicConfig();
  const { isPending: isFlowConfigLoading } = useSessionFlowConfig(gid);
  const currentSectionKey = sessionQuery.data?.session.current_section_key;

  const webSocketUrl = configData?.config.websocket_url;
  const { isPending: isQuoting, quoteRequest } = useInitQuotesRequestSubscription<HomeQuote>({ gid, webSocketUrl });

  const isBundleGroupBindable = isBundleQuotesGroupBindable(quoteRequest?.groups[0]);

  useEffect(() => {
    resetScroll();
  }, [currentSectionKey]);

  if (isQuoting || !quoteRequest) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} customImageCSS={loaderImageCSS} imgUrl={SIRV_IMAGES.homeLoader} />
      </>
    );
  }

  if (!sessionQuery.data || isFlowConfigLoading) {
    return null;
  }

  const renderContent = (): ReactNode => {
    if (isBundleGroupBindable) {
      return <CheckoutBundle />;
    }

    return isCurrentKnockOutSection(sessionQuery.data.session.current_section_key) ? (
      <KnockoutQuestionsForm />
    ) : (
      <CheckoutHome />
    );
  };

  return <div css={[containerCSS(isBundleGroupBindable), customCSS]}>{renderContent()}</div>;
};

export default CheckoutHomeOpportunityPage;
