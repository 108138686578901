import { type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicPrimaryInsured } from '../../api/public/primary-insured';
import { usePublicContactPhoneNumber } from '../../api/public/public-contact-phone-number';
import { useSession } from '../../api/session';
import CalendarIcon from '../../assets/calendar-icon.svg?react';
import PhoneIcon from '../../assets/phone.svg?react';
import UserWithChat from '../../assets/user-with-chat.svg?react';
import { SEGMENT } from '../../constants/analytics';
import ROUTES from '../../constants/routes';
import useResponsive from '../../hooks/use-responsive';
import { useSegment } from '../../hooks/use-segment';
import Anchor from '../../shared/components/Anchor/Anchor';
import Button from '../../shared/components/Button/Button';
import { buttonCSS } from '../../shared/components/Button/Button.style';
import Toggletip from '../../shared/components/Toggletip/Toggletip';
import Format from '../../utils/format.util';
import {
  toggletipButtonCSS,
  userWithChatIconCSS,
  actionButtonCSS,
  buttonIconCSS,
  headerTextCSS,
  scheduleCallCSS,
  subHeaderTextCSS,
  textWrapperCSS,
  toggletipContentCSS,
} from './LiveHelpButton.style';
import type { DefaultLocation } from '../../types/route-params.type';

const LiveHelpButton: FC = () => {
  const { isMobile } = useResponsive();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const { data: phoneData } = usePublicContactPhoneNumber(gid);

  const userName = publicSessionPrimaryInsuredData?.primary_insured.first_name ?? '';
  const { track } = useSegment();
  const navigate = useNavigate();

  const onLiveHelpClick = (): void => {
    track(SEGMENT.events.liveHelpClicked, { page: currentPageKey });
  };

  const onPhoneClicked = (): void =>
    track(SEGMENT.events.phoneConversationInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.header,
    });

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.header,
    });
    navigate(ROUTES.sessionScheduleCall.replace(':gid', gid).replace(':flow', flow));
  };

  return (
    <Toggletip
      content={
        <div css={toggletipContentCSS}>
          <div css={textWrapperCSS}>
            <div css={headerTextCSS}>Hi {userName},</div>
            <div css={subHeaderTextCSS}>Here’s how we can help:</div>
          </div>
          <div>
            <Anchor
              css={[buttonCSS(false), actionButtonCSS]}
              href={`tel:${phoneData?.contact_phone_number?.number}`}
              onClick={onPhoneClicked}
            >
              <PhoneIcon css={buttonIconCSS} />
              Call: {Format.phone(phoneData?.contact_phone_number?.number)}
            </Anchor>
            <Button css={scheduleCallCSS} variant={'secondary'} onClick={onScheduleCall}>
              <CalendarIcon css={buttonIconCSS} />
              Schedule A Consultation
            </Button>
          </div>
        </div>
      }
      customCSS={{ buttonCSS: toggletipButtonCSS }}
      onClick={onLiveHelpClick}
    >
      {!isMobile && <UserWithChat data-testid="chat-icon" css={userWithChatIconCSS} />}
      Live Help
    </Toggletip>
  );
};

export default LiveHelpButton;
