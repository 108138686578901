import QuestionIcon from '../../../assets/grey-question.svg?react';
import Tooltip from '../Tooltip/Tooltip';
import { dashedRowCSS, tooltipCSS } from './DashedRow.style';
import type { DashedRowProps } from './DashedRow.type';
import type { FC } from 'react';

const DashedRow: FC<DashedRowProps> = ({ label, content, customCSS, testId, tooltipContent }) => {
  return (
    <div data-testid={testId} css={[dashedRowCSS, customCSS]}>
      <span>
        {label}
        {tooltipContent && (
          <Tooltip customCSS={{ buttonCSS: tooltipCSS }} content={<>{tooltipContent}</>}>
            <QuestionIcon />
          </Tooltip>
        )}
      </span>
      <span>{content}</span>
    </div>
  );
};

export default DashedRow;
