import SCHEMA_KEYS, {
  PAYMENT_TYPE_MONTHLY_OPTION_KEY,
  PAYMENT_TYPE_ONE_TIME_OPTION_KEY,
} from '../../../../../constants/schema-hardcoded-keys';
import { REQUIRED_VALIDATION } from '../../../../../questionsForm/utils/question-validations/question-validations.util';
import { QuestionType } from '../../../../../types/question.type';
import { VisibilityOperation } from '../../../../../types/visibility-condition.type';
import { generateBillingDayVariants } from '../CheckoutHomeOpportunityPage.util';
import type { QuestionSchema } from '../../../../../types/question.type';

export const AUTO_PAYMENT_TYPE_QUESTION_SCHEMA: QuestionSchema = {
  key: SCHEMA_KEYS.billingAutoPaymentType,
  type: QuestionType.SingleSelect,
  label: 'Payment Plan',
  placeholder: '- select -',
  validations: [REQUIRED_VALIDATION],
  grid_width: 6,
  variants: [
    // {
    //   label: 'Monthly - 6 installments',
    //   value: PAYMENT_TYPE_MONTHLY_OPTION_KEY,
    // },
    {
      label: 'One Time',
      value: PAYMENT_TYPE_ONE_TIME_OPTION_KEY,
    },
  ],
  visibility_conditions: [],
};

export const AUTO_BILLING_DAY_QUESTION_SCHEMA: QuestionSchema = {
  key: SCHEMA_KEYS.billingAutoDayOfMoth,
  type: QuestionType.SingleSelect,
  label: 'Billing day',
  placeholder: '- select -',
  validations: [REQUIRED_VALIDATION],
  grid_width: 6,
  variants: generateBillingDayVariants(28),
  visibility_conditions: [
    [
      {
        operation: VisibilityOperation.Equal,
        value: PAYMENT_TYPE_MONTHLY_OPTION_KEY,
        source: SCHEMA_KEYS.billingAutoPaymentType,
      },
    ],
  ],
};
