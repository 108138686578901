import useResponsive from '../../hooks/use-responsive';
import Divider from '../../shared/components/Divider/Divider';
import { carrierLogoCSS } from '../../shared/components/Header/Header.style';
import Logo from '../../shared/components/Logo/Logo';
import { dividerCSS, headerCSS } from './PreviewPageHeader.style';
import type { PreviewPageHeaderProps } from './PreviewPageHeader.type';
import type { FC } from 'react';

const PreviewPageHeader: FC<PreviewPageHeaderProps> = ({ carrierLogoUrl, carrierName }) => {
  const { isMobile } = useResponsive();

  const headerLogoSizes = isMobile ? 24 : 32;

  return (
    <header css={headerCSS}>
      {carrierLogoUrl && (
        <>
          <Logo
            customCSS={carrierLogoCSS}
            carrierLogo={carrierLogoUrl}
            carrierName={carrierName}
            height={headerLogoSizes}
          />
          <Divider height={headerLogoSizes} customCSS={dividerCSS} />
        </>
      )}
      <Logo height={headerLogoSizes} />
    </header>
  );
};

export default PreviewPageHeader;
