import { css } from '@emotion/react';
import { focusHelperCSS } from '../../../../../shared/components/Input/Input.style';
import { loaderCSS } from '../../../../../shared/components/SkeletonLoader/SkeletonLoader.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';
import type { StripeElementStyle } from '@stripe/stripe-js';

export const stripeElementCSS: StripeElementStyle = {
  base: {
    color: theme.colors.black,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    backgroundColor: 'transparent',
    fontVariant: 'lining-nums',
    '::placeholder': {
      color: theme.colors.gray60,
      fontStyle: 'italic',
    },
    ':disabled': {
      backgroundColor: theme.colors.gray10,
    },
  },
};

export const containerCSS = css`
  margin-bottom: 16px;

  ${mq[0]} {
    margin-bottom: 12px;
  }
`;

export const labelCSS = css`
  margin-bottom: 4px;
`;

const inputErrorCSS = (isFocused?: boolean): SerializedStyles => css`
  border-color: ${isFocused ? theme.colors.black : theme.colors.errorRed};
  box-shadow: inset 0 0 0 1px ${isFocused ? 'transparent' : theme.colors.errorRed};

  &:hover {
    border-color: ${isFocused ? theme.colors.black : theme.colors.errorRed};
  }
`;

const disabledInputCSS = css`
  background-color: ${theme.colors.gray10};
  opacity: 0.5;
`;

export const inputWrapperCSS = (
  isLoading?: boolean,
  hasError?: boolean,
  isFocused?: boolean,
  isDisabled?: boolean
): SerializedStyles => css`
  border: 1px solid ${isLoading ? theme.colors.white : theme.colors.black};
  border-radius: 4px;
  height: ${theme.sizes.controlHeight}px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;

  &:hover {
    border-color: ${isFocused ? theme.colors.black : theme.colors.azure62};
  }

  ${hasError && inputErrorCSS(isFocused)};

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    ${isLoading ? loaderCSS(60) : null};
  }
  ${isDisabled && disabledInputCSS}
`;

const getBorderColor = (focused?: boolean, hasError?: boolean): string => {
  if (focused) {
    return hasError ? theme.colors.errorRed : theme.colors.azure50;
  }
  return 'transparent';
};

export const activeOutlineCSS = (focused?: boolean, hasError?: boolean): SerializedStyles => css`
  ${focusHelperCSS};
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-color: ${getBorderColor(focused, hasError)};
`;

export const errorCSS = css`
  margin-top: 4px;
`;
