import { css } from '@emotion/react';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import { formWithGridCSS } from '../../../../../styles/form.style';
import { mq } from '../../../../../styles/media-queries';

export const formCSS = css`
  ${formWithGridCSS};
  ${activeElementResetCSS};
  display: block;
`;

export const buttonCSS = css`
  margin-top: 12px;
`;

export const inputCSS = css`
  ${mq[0]} {
    margin-right: 16px;
  }
`;
