import type { SerializedStyles } from '@emotion/react';

export enum StripeInputType {
  CardNumber = 'cardNumber',
  CardExpiry = 'cardExpiry',
  CardCvc = 'cardCvc',
}

export interface StripeInputElementProps {
  customCSS?: SerializedStyles;
  type: StripeInputType;
  disabled?: boolean;
}
