import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (isBundleCheckout?: boolean): SerializedStyles => css`
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;

  ${isBundleCheckout &&
  css`
    ${mq[1]} {
      max-width: 100%;
    }

    ${mq[2]} {
      max-width: 1440px;
    }
  `}
`;

export const loaderImageCSS = css`
  width: 280px;
  height: 195px;

  ${mq[0]} {
    width: 335px;
    height: 250px;
  }
`;

export const loaderCSS = css`
  padding-top: 48px;
  width: 100%;
  ${mq[0]} {
    padding-top: 72px;
  }
`;
