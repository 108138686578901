import { useQueryClient } from '@tanstack/react-query';
import { type FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { useMutatePolicyBind } from '../../../../../api/policy';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useCreateQuotesRequest } from '../../../../../api/quote';
import { waitForQuoteRequestCompleted } from '../../../../../api/quote/use-quote-requests-subscription';
import { useMutateSession, useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import { useSegment } from '../../../../../hooks/use-segment';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import { getCurrentPageSection } from '../../../../../utils/schema.util';
import CheckoutHomeFormLoading from '../CheckoutHomeFormLoading/CheckoutHomeFormLoading';
import CheckoutHomePageLoader from '../CheckoutHomePageLoader/CheckoutHomePageLoader';
import CheckoutPaymentStep from '../CheckoutPaymentStep/CheckoutPaymentStep';
import { containerCSS } from './CheckoutDynamicHomeForm.style';
import type { CheckoutDynamicHomeQuoteFormProps } from './CheckoutDynamicHomeForm.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { SessionResponse } from '../../../../../types/session.type';

const CheckoutDynamicHomeForm: FC<CheckoutDynamicHomeQuoteFormProps> = ({ customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;

  const queryClient = useQueryClient();
  const { data: configData } = usePublicConfig();
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: sessionData } = useSession(gid);

  const { mutateAsync: mutateSession } = useMutateSession(gid);
  const { mutateAsync: mutateBindPolicy } = useMutatePolicyBind(gid);
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest(gid);

  const currentPageKey = sessionData?.session.current_page_key;
  const currentSectionKey = sessionData?.session.current_section_key;
  const { data: pageData } = usePage(gid, currentPageKey);

  const { track } = useSegment();
  const navigate = useNavigate();

  const [bindInProgress, setBindInProgress] = useState(false);

  const currentSection = getCurrentPageSection(currentSectionKey, pageData);

  const onBindPolicy = async (): Promise<void> => {
    setBindInProgress(true);
    track(SEGMENT.events.bindPolicyClicked);

    await createQuotesRequest({ pageKey: currentPageKey });

    if (!configData?.config.websocket_url) {
      throw new Error('Websocket URL is not defined');
    }
    await waitForQuoteRequestCompleted(gid, configData.config.websocket_url);

    await mutateBindPolicy({});
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);
    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  if (!sessionFlowConfig?.flow_config?.payment_methods) {
    return <CheckoutHomeFormLoading />;
  }

  if (bindInProgress) {
    return <CheckoutHomePageLoader />;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <CheckoutPaymentStep
        paymentMethods={sessionFlowConfig?.flow_config?.payment_methods}
        stepNumber={1}
        hideStepNumber
        currentStepNumber={1}
        actionLabel={currentSection?.action_label}
        onGoToNextStep={onBindPolicy}
      />
    </div>
  );
};

export default CheckoutDynamicHomeForm;
