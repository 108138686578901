import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../../api/public/flow-config';
import { usePublicPrimaryInsured } from '../../api/public/primary-insured';
import { useSession } from '../../api/session';
import PageContainer from '../../components/PageContainer/PageContainer';
import PolicyHolderDigitalProfileQuotePage from '../../components/PolicyHolderDigitalProfileQuotePage/PolicyHolderDigitalProfileQuotePage';
import { useSegment } from '../../hooks/use-segment';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader';
import { PolicyType } from '../../types/policy.type';
import { StrategyType } from '../../types/public-flow-config.type';
import AutoQuoteOpportunityPage from './components/AutoQuoteOpportunityPage/AutoQuoteOpportunityPage';
import CustomerJourneyRedirect from './components/CustomerJourneyRedirect/CustomerJourneyRedirect';
import DigitalProfileQuotePage from './components/DigitalProfileQuotePage/DigitalProfileQuotePage';
import HomeQuoteOpportunityPage from './components/HomeQuoteOpportunityPage/HomeQuoteOpportunityPage';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const QuoteLayout: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData, isLoading: isSessionDataLoading } = useSession(gid);
  const { data: flowConfigData } = usePublicFlowConfig(gid);
  const { data: publicPrimaryInsured } = usePublicPrimaryInsured(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const customerJourneyNextStep = sessionData?.session.customer_journey.next_step_url;

  const pageViewedTracked = useRef(false);
  const { page } = useSegment();

  useEffect(() => {
    if (currentPageKey && !pageViewedTracked.current && !customerJourneyNextStep) {
      page(currentPageKey);
      pageViewedTracked.current = true;
    }
  }, [currentPageKey, page, customerJourneyNextStep]);

  const getQuotePageToRender = (
    policyType: PolicyType,
    strategy: StrategyType,
    isPolicyholder?: boolean
  ): ReactNode => {
    if (strategy === StrategyType.DigitalProfile) {
      return isPolicyholder ? <PolicyHolderDigitalProfileQuotePage /> : <DigitalProfileQuotePage />;
    }

    switch (policyType) {
      case PolicyType.Auto:
        return <AutoQuoteOpportunityPage />;
      case PolicyType.Condo:
      case PolicyType.Home:
        return <HomeQuoteOpportunityPage />;
    }
  };

  if (
    !sessionData?.session ||
    !flowConfigData?.flow_config ||
    !publicPrimaryInsured?.primary_insured ||
    isSessionDataLoading
  ) {
    return <FullPageLoader />;
  }

  if (customerJourneyNextStep && flowConfigData.flow_config.strategy === StrategyType.DigitalProfile) {
    return <CustomerJourneyRedirect />;
  }

  return (
    <PageContainer showFooter allowFullStoryTracking allowTabTitleUpdate>
      {getQuotePageToRender(
        flowConfigData?.flow_config.policy_type,
        flowConfigData.flow_config.strategy,
        publicPrimaryInsured.primary_insured.is_policyholder
      )}
    </PageContainer>
  );
};

export default QuoteLayout;
