import type { Answers } from './answer.type';
import type { DynamicDefaults } from './dynamic-defaults.type';
import type { SectionSchema } from './section.type';
import type { VisibilityCondition } from './visibility-condition.type';

export enum PageTypes {
  QuestionForm = 'question_form',
  AutoInformationForm = 'auto_information_form',
  Discounts = 'discounts',
  QuoteCustomization = 'quote_customization',
  Billing = 'billing',
  Congratulations = 'congratulations',
}

export interface PageResponse<T = SectionSchema> {
  page: PageSchema<T>;
  answers: Answers;
  default_answers: Answers;
  dynamic_defaults: DynamicDefaults;
}

export interface PageSchema<T = SectionSchema> {
  key: PageKey;
  content_heading?: string | null;
  content_description?: string | null;
  content_icon_url?: string | null;
  page_type: PageTypes;
  sections: T[];
  page_preview?: {
    visibility_conditions: VisibilityCondition[][];
  };
}

export type PageKey = PageKeyAuto | PageKeyHome;

export enum PageKeyAuto {
  DetailsConfirmation = 'details_confirmation',
  VehiclesDetails = 'vehicles_details',
  DriversDetails = 'drivers_details',
  PolicyInformation = 'policy_information',
  FinalQuote = 'final_quote',
  CheckOut = 'check_out',
  PersonInsured = 'person_insured',
}

export enum PageKeyHome {
  Discounts = 'discounts',
  PropertyDetails = 'property_details',
  PersonalInformation = 'personal_information',
  FinalQuote = 'final_quote',
  CheckOut = 'check_out',
  PersonInsured = 'person_insured',
  PropertyInformation = 'property_information',
}
