import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  z-index: 3;
  position: fixed;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-shadow: ${theme.boxShadow.container};
  top: ${theme.header.headerHeightMobile}px;
  border-top: 2px solid ${theme.colors.azure50};
  border-bottom: 2px solid ${theme.colors.azure50};

  ${mq[0]} {
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    max-width: 650px;
    border: 2px solid ${theme.colors.azure50};
    border-radius: ${theme.sizes.borderRadius}px;
  }

  ${mq[1]} {
    top: ${theme.header.headerHeightDesktop + 16}px;
  }
`;

export const updateButtonCSS = css`
  ${text14CSS};
  padding: 0;
  height: 28px;
  width: 100%;
  margin-top: 16px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${mq[0]} {
    ${textCSS};
    height: auto;
    width: auto;
    margin-top: 0;
    padding: 10px 12px;
  }
`;

export const unsavedChangesCSS = css`
  font-weight: 700;
`;

export const textBlockCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 20px;
  ${text14CSS};

  ${mq[0]} {
    text-align: left;
    width: 70%;
  }
`;

export const updateIconCSS = (isButton = false): SerializedStyles => css`
  color: ${theme.colors.azure50};

  ${isButton &&
  css`
    margin-right: 8px;
  `}
`;

export const iconContainerCSS = css`
  display: none;
  ${mq[0]} {
    background-color: ${theme.colors.azure95};
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;
